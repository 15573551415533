export const skillOption = [
  'Python',
  'Data Analysis',
  'Maths',
  'Machine Learning',
  'Business Insights and Use-case',
  'MatPlotLib',
  'NumPy',
  'Pandas',
  'Pytorch',
  'VSCode',
  'Jupyter',
  'Tenserflow',
  'Flask',
  'Streamlit',
  'PySpark',
  'Spreadsheet',
  'SQL',
  'Probability and Statistics',
  'Supervised Learning',
  'Unsupervised Learning',
  'EDA',
  'Guesstimate',
  'NLP',
  'Tableau',
  'Google Data Studio',
  'Computer Vision',
  'Deep Learning',
  'Scikit Learn',
  'Business Acumen',
  'Google Collab',
  'AWS',
  'Azure',
];
