import React, { useState } from 'react';

import { InputAdornment, TextField } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import { PATH } from '../../../constants';
import { LoadingCircle } from '../../utils/Icons';
import { setLoggedInUser } from '../../../redux/authSlice';
import { updateMyProfile } from '../../../services/userApiService';
import { CheckboxDark } from '../../../utils';
import upiLogo from '../../../static/img/upi-logo.png';

const Payment = ({ editMode, paymentDetails }) => {
  const [formData, setFormData] = useState(paymentDetails || {});
  const [isLoading, setIsLoading] = useState({});

  const dispatch = useDispatch();
  const history = useHistory();

  const handlPaymentDetailsInput = ({ target }) =>
    setFormData({
      ...formData,
      accountDetails: {
        ...formData.accountDetails,
        [target.name]: target.value,
      },
    });

  const handlPaymentDetailsSubmit = async (e) => {
    e.preventDefault();

    setIsLoading({ pmt: true });

    try {
      dispatch(setLoggedInUser(await updateMyProfile({ paymentDetails: formData })));
      toast.info('Profile Updated!', toastProps);
    } catch (err) {
      console.error(err);
      toast.error('Failed To Update Profile! \n Please Try Again Later!!', toastProps);
    }

    setIsLoading({ pmt: false });

    if (!editMode) history.push(PATH.HOME);
  };

  return (
    <div className='w-full max-w-4xl mt-16'>
      <h1 className='text-2xl font-semibold'>Bank Details</h1>
      <form
        onSubmit={handlPaymentDetailsSubmit}
        className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-8'
      >
        <TextField
          label='A/c Holder Name'
          variant='outlined'
          type='text'
          name='holderName'
          value={(formData.accountDetails && formData.accountDetails.holderName) || ''}
          onChange={handlPaymentDetailsInput}
          fullWidth
        />
        <TextField
          label='Bank Name'
          variant='outlined'
          type='text'
          name='bankName'
          value={(formData.accountDetails && formData.accountDetails.bankName) || ''}
          onChange={handlPaymentDetailsInput}
          fullWidth
        />
        <TextField
          label='A/c Number'
          variant='outlined'
          type='number'
          name='number'
          value={(formData.accountDetails && formData.accountDetails.number) || ''}
          onChange={handlPaymentDetailsInput}
          fullWidth
        />
        <TextField
          label='IFSC Code'
          variant='outlined'
          type='text'
          name='ifsc'
          value={(formData.accountDetails && formData.accountDetails.ifsc) || ''}
          onChange={handlPaymentDetailsInput}
          fullWidth
        />

        <div className='text-xl font-semibold mt-4'>UPI Detail (optional)</div>
        <span />
        <TextField
          label='UPI ID'
          placeholder='johndoe@sbi'
          variant='outlined'
          type='email'
          value={formData.upi || ''}
          onChange={({ target }) => setFormData({ ...formData, upi: target.value })}
          fullWidth
          InputProps={{
            startAdornment: (
              <>
                <InputAdornment position='start'>
                  <img src={upiLogo} alt='UPI Logo' className='w-12' />
                </InputAdornment>
              </>
            ),
          }}
        />
        <span />

        {!editMode && (
          <>
            <div className='flex items-center mt-32'>
              <CheckboxDark color='primary' required />
              <span className='text-blue-500'>I accept the terms and conditions.</span>
            </div>
            <span />
          </>
        )}

        <button
          type='submit'
          disabled={isLoading.pmt}
          className='bg-green-c1 dark:bg-ab-secondary text-white font-medium rounded focus:outline-none py-4'
        >
          {isLoading.pmt && <LoadingCircle iconStyle='w-6 mr-2' />}
          {editMode ? 'UPDATE DETAILS' : 'SUBMIT FOR VERIFICATION'}
        </button>

        {!editMode && (
          <Link to={PATH.HOME} className='text-gray-400 m-auto'>
            Skip for now
          </Link>
        )}
      </form>

      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

const toastProps = {
  position: 'bottom-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
};

export default Payment;
