import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import CompletionPage from './CompletionPage';
import CandidataRatingForm from './CandidateRatingForm';
import InterviewDetailsHeader from '../InterviewDetailsHeader';

const SessionFeedback = () => {
  const [submitted, setSubmitted] = useState(false);

  const history = useHistory();
  const booking = history.location.state?.booking;

  return (
    <div className='fixed h-screen w-screen inset-0 bg-gray-c1 dark:bg-ab-primary p-4 z-50'>
      <div className='max-w-4xl rounded-lg mx-auto font-medium overflow-scroll hidden-scrollbar max-h-screen pb-8'>
        <InterviewDetailsHeader booking={booking} />
        {submitted ? (
          <CompletionPage />
        ) : (
          <CandidataRatingForm booking={booking} setSubmitted={setSubmitted} />
        )}
      </div>
    </div>
  );
};

export default SessionFeedback;
