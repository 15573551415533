import React, { useEffect } from 'react';
import { useHMSActions, useHMSStore, selectLocalPeer } from '@100mslive/hms-video-react';
import { CircularProgress } from '@material-ui/core';

import Peer from './Peer';
import Controls from './Controls';

const CustomPreview = ({ config, joinOnClick }) => {
  const hmsActions = useHMSActions();
  const localPeer = useHMSStore(selectLocalPeer);

  useEffect(() => hmsActions.preview(config), [config, hmsActions]);

  return localPeer ? (
    <div className='mx-auto w-full max-w-md bg-ab-tertiary dark:bg-opacity-10 border border-solid border-ab-primary-shade-2 border-opacity-20 p-4 rounded-lg flex shadow-lg'>
      <div className='w-full m-auto'>
        <Peer peer={localPeer} />
        <Controls isPreviewMode={true} />
        <button
          onClick={joinOnClick}
          className='mx-auto block font-medium bg-blue-c1 dark:bg-ab-secondary text-ab-tertiary rounded-lg px-8 py-2'
        >
          JOIN
        </button>
      </div>
    </div>
  ) : (
    <span className='m-auto text-blue-c1 dark:text-ab-tertiary'>
      <CircularProgress color='inherit' />
    </span>
  );
};

export default CustomPreview;
