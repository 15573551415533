import { useEffect, useState } from 'react';
import {
  CalendarTodayOutlined,
  Event,
  Info,
  LinkedIn,
  Notifications,
  Person,
  Restore,
  Star,
} from '@material-ui/icons';

import RenderThumbnail from '../../../components/utils/RenderThumbnail';
import {
  BOOKING_STAGE,
  BOOKING_STATUS,
  DATE_FORMAT_1,
  DATE_ONLY_FORMAT_1,
  TIME_FORMAT_1,
} from '../../../constants';
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

const STATUS_COLOR = {
  [BOOKING_STATUS.ACTIVE]: 'border-yellow-400 text-yellow-400',
  [BOOKING_STATUS.COMPLETED]: 'border-green-c3 text-green-c3',
  [BOOKING_STATUS.CANCELLED]: 'border-ab-secondary text-ab-secondary',
};

// / Day.js /
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const BookingDetailsCardView = ({
  booking,
  selectedTimeSlot,
  setSelectedTimeSlot,
  reschedule,
  setReschedule,
}) => {
  const { loggedInUser } = useSelector(({ authReducer }) => authReducer);
  const { Student: studentData } = booking;
  const [preferences, setPreferences] = useState([]);

  const coachTimeZone = dayjs.tz.guess() || Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    if ([BOOKING_STAGE.TIME_SLOTS_ADDED, BOOKING_STAGE.COACH_ASSIGNED].includes(booking.stage)) {
      const checkedPref = [];
      for (const pref of booking.preferences) {
        if (pref.date >= dayjs().format('YYYY-MM-DD')) checkedPref.push(pref);
      }
      setPreferences(checkedPref);
    }
  }, []);

  // const isRescheduleAllowed = useMemo(() => {
  //   const editingLimit = dayjs().add(2, 'hour');
  //   const endDate = dayjs(booking.startTime);
  //   return (
  //     booking.stage === BOOKING_STAGE.COACH_ASSIGNED &&
  //     editingLimit.isBefore(endDate)
  //   );
  // }, [booking]);

  const getTimeZonedData = (date, startTime, timeZone) => {
    return dayjs(`${date} ${startTime}`).tz(coachTimeZone).format(TIME_FORMAT_1);
  };

  return (
    <div key={booking.id} className='py-4 px-6 flex flex-col'>
      <div className='flex items-center justify-between'>
        <span className='text-ab-primary dark:text-ab-tertiary text-xl font-semibold opacity-80'>
          {booking.themeName + ' - ' + booking.subTopicName}
        </span>
        <span className='flex gap-4'>
          {booking.exclusiveForCoaches && booking.exclusiveForCoaches.includes(loggedInUser.id) && (
            <Tooltip title={<span className='text-base'>Only Visible to You</span>} arrow>
              <span className='border border-solid border-purple-c1 text-purple-c1 text-sm rounded py-1 px-2 inline-flex items-center gap-2 cursor-default'>
                <Star fontSize='small' />
                <span>Exclusive</span>
              </span>
            </Tooltip>
          )}

          {/* booking by */}
          <span
            className={`border border-solid text-sm rounded py-1 px-2 inline-flex items-center gap-2 ${
              booking.createdBy === booking.studentId
                ? 'border-green-c1 text-green-c1'
                : 'border-blue-c1 text-blue-c1'
            }`}
          >
            <Person fontSize='small' />
            <span>
              {booking.createdBy === booking.studentId ? 'Student Booking' : 'Admin Booking'}
            </span>
          </span>

          {/* status */}
          {booking.stage !== BOOKING_STAGE.TIME_SLOTS_ADDED && (
            <span
              className={
                'border border-solid text-sm capitalize rounded-md py-1 px-4 flex items-center gap-2 ' +
                STATUS_COLOR[booking.status]
              }
            >
              <span className='w-3 h-3 rounded-full bg-current' />
              <span>{booking.status}</span>
            </span>
          )}
        </span>
      </div>

      {/* candidate details */}
      <div className='flex items-center gap-12 font-medium text-ab-primary dark:text-ab-tertiary mt-2 mb-4'>
        <div className='flex items-center gap-3'>
          <span className='opacity-60'>By</span>
          <RenderThumbnail
            thumbnail={studentData?.thumbnail}
            name={studentData?.firstName}
            size='2.5'
          />
          <span className='opacity-60'>
            {studentData?.firstName} {studentData?.lastName}
          </span>
          {studentData.linkedInUrl && (
            <a href={studentData.linkedInUrl} target='_blank' rel='noreferrer'>
              <LinkedIn htmlColor='#0275B4' />
            </a>
          )}
        </div>
        <span className='opacity-60'>{studentData?.phone}</span>
        <span className='opacity-60'>{studentData?.email}</span>
        {booking.stage === BOOKING_STAGE.TIME_SLOTS_ADDED && (
          <span className='flex-grow text-right'>
            <span className='opacity-60 mr-4'>requested on</span>
            <span className='bg-yellow-c1 bg-opacity-30 opacity-80 rounded px-4 py-2'>
              {dayjs(booking.createdAt).format(DATE_FORMAT_1)}
            </span>
            {showNotification(_.sortBy(booking.preferences, ['date'])?.[0]?.date) && (
              <span className='text-red-500 animate-pulse ml-2'>
                <Notifications />
              </span>
            )}
          </span>
        )}
      </div>

      <hr className='border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100' />

      {/* booking details */}
      {booking.stage === BOOKING_STAGE.TIME_SLOTS_ADDED || reschedule ? (
        <>
          <div className='font-medium text-ab-primary dark:text-ab-tertiary opacity-60 mt-4 mb-2'>
            {preferences.length
              ? 'Select from below time slots'
              : 'All time slots expired, please request new time slots!'}
          </div>

          {preferences.map((prf, i) => (
            <div
              className='flex items-center gap-2 text-ab-primary dark:text-ab-tertiary my-1.5'
              key={i}
            >
              <span className='font-semibold opacity-60 w-6 ml-2'>{i + 1}.</span>
              <span className='opacity-60'>
                <CalendarTodayOutlined />
              </span>
              <span className='w-32 opacity-60 whitespace-nowrap'>
                {dayjs(prf.date).format(DATE_ONLY_FORMAT_1)}
              </span>

              {prf.timeSlots.map((ts, j) => (
                <button
                  onClick={() =>
                    setSelectedTimeSlot({
                      bookingId: booking.id,
                      date: prf.date,
                      timeZone: prf.timeZone,
                      ...ts,
                    })
                  }
                  key={j}
                  className={
                    'font-medium w-36 ' +
                    (_.isEqual(selectedTimeSlot, {
                      bookingId: booking.id,
                      timeZone: prf.timeZone,
                      date: prf.date,
                      ...ts,
                    })
                      ? 'border border-solid border-ab-secondary rounded-full text-ab-secondary'
                      : 'opacity-50')
                  }
                >
                  <span>{getTimeZonedData(prf.date, ts.startTime, prf.timeZone)}</span>
                </button>
              ))}
            </div>
          ))}
        </>
      ) : (
        <div className='flex items-center gap-8 mt-2'>
          {booking.stage === BOOKING_STAGE.COACH_ASSIGNED ? (
            <span>
              <div className='opacity-40 mb-1'>Session scheduled at</div>
              <div className='flex items-center gap-8'>
                <div className='text-blue-c1 text-lg font-semibold bg-blue-c1 bg-opacity-20 rounded-lg p-3 max-w-min whitespace-nowrap flex items-center gap-2'>
                  <Event fontSize='large' color='inherit' />
                  <span className='pr-1'>{dayjs(booking.startTime).format(DATE_FORMAT_1)}</span>
                </div>
                <div className='flex flex-col justify-between gap-1'>
                  <Tooltip
                    arrow
                    title={
                      <span className='text-base'>
                        Choose from other time slots shared or request for new time slots here
                      </span>
                    }
                  >
                    <button
                      onClick={() => setReschedule(true)}
                      className='flex items-center gap-2 opacity-60'
                    >
                      <Restore />
                      <span>Reschedule</span>
                    </button>
                  </Tooltip>

                  {booking.isNewSlotsRequested && (
                    <div className='font-medium text-yellow-c1 flex items-center gap-2 opacity-80'>
                      <Info />
                      <span>Requested for new time slots</span>
                    </div>
                  )}
                </div>
              </div>
            </span>
          ) : (
            <span>
              <div className='opacity-40 mb-1'>Session conducted at</div>
              <div className='flex items-center gap-8'>
                <div className='text-blue-c1 text-lg font-semibold bg-blue-c1 bg-opacity-20 rounded-lg p-3 max-w-min whitespace-nowrap flex items-center gap-2'>
                  <Event fontSize='large' color='inherit' />
                  <span className='pr-1'>{dayjs(booking.conductedAt).format(DATE_FORMAT_1)}</span>
                </div>
              </div>
            </span>
          )}
        </div>
      )}

      {/* {booking.stage !== BOOKING_STAGE.TIME_SLOTS_ADDED && !reschedule && (
        <div className='flex items-center gap-4 text-ab-primary dark:text-ab-tertiary font-normal'>
          <CurrencyIcon iconStyle='w-8 opacity-60 -mr-2' />
          <span className='font-medium opacity-60'>{`Total Earning:  ₹ ${booking.totalEarning}`}</span>
          <span className='opacity-40'>
            {`(Fixed Earning:  ₹${
              booking.fixedEarning || 0
            }  +  Variable Earning:  ₹${booking.variableEarning || 0})`}
          </span>
        </div>
      )} */}
    </div>
  );
};

const showNotification = (bookingEarliestTimeSlot) => {
  // notification icon will blink 48 hours ahead of earliest slot listed if booking is open
  return dayjs(bookingEarliestTimeSlot).isBefore(dayjs().add(48, 'hour'));
};

export default BookingDetailsCardView;
