import { Formik, Field, Form } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import List from '@material-ui/core/List';

import Typography from '@material-ui/core/Typography';
import { ToastContainer, toast } from 'react-toastify';
import { LoadingCircle } from '../../utils/Icons';
import { AIcon, InfoIcon } from '../../utils/Icons';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { updateCoach } from '../../../redux/authSlice';
import './styles.css';

const theme = createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '12px',
        color: '#00000099',
        backgroundColor: '#F5F5F5',
        opacity: '80%',
        width: '150px',
        border: '1px solid #0000004d',
        padding: '4px',
      },
    },
  },
});

const descMap = {
  'Full Stack Data Science':
    'Train some of the best minds for the most challenging Data Science roles',
  'Full Stack Web Development':
    'Train full stack software developers on the latest Tech-stacks to powerful careers',
};

function Expertise({ setActiveOption, curriculum, me, domainKnowledge }) {
  const matches = useMediaQuery('(min-width:1024px)');
  const toastProps = {
    position: matches ? 'bottom-right' : '',
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
  };

  const courseNameMapping = {
    web3: 'Full Stack Web Development',
    'data-science': 'Full Stack Data Science',
    'Full Stack Data Science': 'data-science',
    'Full Stack Web Development': 'web3',
  };

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedCurriculum, setSelectedCurriculum] = useState(Object.values(domainKnowledge)[0]);
  const [display, setDisplay] = useState('');
  const [open, setOpen] = useState(false);

  const handleOpen = (courseName) => {
    if (display === courseName) {
      setOpen(false);
    }

    if (display === courseName && open === false) {
      setOpen(true);
    }

    if (display === '') {
      setDisplay(courseName);
      setOpen(true);
    }

    if (display !== courseName) {
      setDisplay(courseName);
      setOpen(true);
    }
  };

  useEffect(() => {
    const key = Object.keys(domainKnowledge)[0];
    const value = Object.values(domainKnowledge)[0];

    setSelectedCourse(courseNameMapping[key]);
    setSelectedCurriculum(value);
  }, [domainKnowledge]);

  const handleSubmit = async (values) => {
    // setLoading(true);
    let domainKnowledge = [];
    const selectedCourseName = courseNameMapping[values.CourseChecked];
    const data = {
      [selectedCourseName]: values.curriculumChecked,
    };
    domainKnowledge = data;
    const obj = {
      domainKnowledge,
    };

    try {
      await dispatch(updateCoach(obj));
      toast.info('Profile Updated!', toastProps);
    } catch (err) {
      toast.error('Failed To Update Profile! \n Please Try Again Later!!', toastProps);
    }
    setLoading(false);
    setActiveOption('G');
  };

  return (
    <div className='w-full md:mt-6 flex flex-col mx-2 text-black '>
      {/* header & image input */}
      <div className=' items-center md:gap-4 mb-8 md:mb-12 lg:mr-20'>
        <div className=' text-black text-base mb-4 px-2'>Choose Your Domain(s)</div>
        <Formik
          enableReinitialize
          initialValues={{
            CourseChecked: selectedCourse || '',
            curriculumChecked: selectedCurriculum || [],
          }}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <div className='flex px-2 justify-start '>
                {curriculum?.map((course, index) => {
                  return (
                    <div
                      key={course.id}
                      className={`flex items-start lg:p-4 pl-1.5 pr-1 py-3 lg:w-80 w-40 md:w-56  mx-1 flex-shrink-0  text-green-c1 border-solid lg:rounded rounded-lg ${
                        values.CourseChecked === course.attributes.courseName
                          ? 'border-2 border-blue-c1 '
                          : 'border-gray-c7 border'
                      }`}
                    >
                      <div
                        className='flex md:space-x-4  space-x-2'
                        role='group'
                        aria-labelledby='my-radio-group'
                      >
                        <Field
                          type='radio'
                          className='h-5 w-5 flex-shrink-0'
                          name='CourseChecked'
                          onClick={(e) => {
                            values.curriculumChecked = [];
                            if (course.attributes.courseName !== values.CourseChecked)
                              setSelectedCurriculum([]);
                            setSelectedCourse(course.attributes.courseName);
                          }}
                          value={course.attributes.courseName}
                        />
                        <div className=' text-green-c1 font-semibold md:text-base text-xs md:-mt-1'>
                          {course.attributes.courseName === 'Full Stack Data Science' &&
                            'Data Science'}
                          {course.attributes.courseName === 'Full Stack Web Development' &&
                            'Software Development'}

                          <p className='text-sm text-gray-c6 font-normal mt-2 hidden md:flex'>
                            {descMap[course.attributes.courseName]}
                          </p>
                        </div>
                      </div>
                      <div className='md:hidden -mt-1.5'>
                        <MuiThemeProvider theme={theme}>
                          <Tooltip
                            open={course.attributes.courseName === display && open}
                            onClick={() => handleOpen(course.attributes.courseName)}
                            title={descMap[course.attributes.courseName]}
                            className='lg:hidden'
                            PopperProps={{
                              popperOptions: {
                                modifiers: {
                                  offset: {
                                    enabled: true,
                                    offset: '-54px 8px',
                                  },
                                },
                              },
                            }}
                          >
                            <Button
                              style={{
                                minWidth: '40px',
                                padding: '0px',
                                right: '0px',
                              }}
                            >
                              <InfoIcon
                                fill={
                                  values.CourseChecked === course.attributes.courseName
                                    ? '#148EFF'
                                    : 'black'
                                }
                              />
                            </Button>
                          </Tooltip>
                        </MuiThemeProvider>
                      </div>
                    </div>
                  );
                })}
              </div>

              {values.CourseChecked?.length > 0 && (
                <p className='text-black md:text-base text-sm md:my-8 my-6 px-3 '>
                  Select topics which your are interested in:
                </p>
              )}
              <div
                className='overflow-y-auto srcollTopics relative'
                style={{ height: 'calc(100vh - 420px)' }}
              >
                <div className='  '>
                  {values.CourseChecked?.length > 0 && (
                    <div className=''>
                      {Object.values(
                        curriculum
                          ?.filter((item) => values.CourseChecked === item.attributes.courseName)
                          .map((course, index) => {
                            return (
                              <React.Fragment key={index}>
                                {course.attributes?.learningJourney[0]?.modules?.data?.map(
                                  (module, index) => {
                                    return (
                                      <div className={`classes.root text-gray-c6`} key={module.id}>
                                        <List
                                          id='additional-actions1-header'
                                          name='moduleChecked'
                                          className='flex items-center'
                                        >
                                          <Field
                                            type='checkbox'
                                            className='h-5 w-5 flex-shrink-0 mr-4'
                                            name='curriculumChecked'
                                            value={module.attributes?.description}
                                            onClick={(event) => event.stopPropagation()}
                                          />

                                          <Typography className={` text-base text-gray-c6`}>
                                            {module.attributes?.description}
                                          </Typography>
                                        </List>
                                      </div>
                                    );
                                  }
                                )}
                              </React.Fragment>
                            );
                          })
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className=''>
                <button
                  type='submit'
                  disabled={values.curriculumChecked?.length === 0}
                  className={` disabled:opacity-40 md:mb-8 mb-4  md:w-72 w-11/12 bg-green-c1 text-white focus:outline-none font-medium  text-center rounded bottom-0 fixed py-4 md:ml-0 ml-2`}
                >
                  {loading && <LoadingCircle iconStyle='w-6 mr-2' />}
                  <span>NEXT</span>
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Expertise;
