import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { LoadingCircle } from '../../utils/Icons';
import { useDispatch } from 'react-redux';
import { skillOption } from './SkillOption';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { updateCoach } from '../../../redux/authSlice';
import { getCoachInfo } from '../../../services/userApiService';
import './styles.css';

const skills = ({ skills, setActiveOption, me }) => {
  const matches = useMediaQuery('(min-width:1024px)');
  const toastProps = {
    position: matches ? 'bottom-right' : '',
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
  };

  const [loading, setLoading] = useState(false);
  const [skillMenu, setSkillMenu] = useState(skills ? false : true);
  const [selectedSkills, setSelectedSkills] = useState(skills);
  const [updatedSkill, setUpdatedSkills] = useState(false);
  const dispatch = useDispatch();

  useEffect(async () => {
    const skills = await getCoachInfo();
    setSelectedSkills(skills?.unverified?.skills);
  }, [updatedSkill]);

  const handleSubmit = async (values) => {
    setLoading(true);
    const skills = values.checked;
    if (me.id) {
      const obj = {
        skills,
      };
      try {
        await dispatch(updateCoach(obj));
        toast.info('Profile Updated!', toastProps);
        setLoading(false);
        setSkillMenu(false);
        setUpdatedSkills(true);
      } catch (err) {
        toast.error('Failed To Update Profile! \n Please Try Again Later!!', toastProps);
      }
      setUpdatedSkills(false);
    }
  };

  return (
    <div className='w-full md:mt-6 mt-3 flex flex-col mx-4 md:mx-0 md:pr-40'>
      {/* header & image input */}
      <div className=' items-center md:gap-4  md:mb-12 '>
        <p className=' text-black text-base md:mb-8 mb-4 '>
          {!selectedSkills || selectedSkills?.length === 0 || skillMenu
            ? 'Add your skills'
            : 'Your skills'}
        </p>
        {!selectedSkills || selectedSkills?.length === 0 || skillMenu ? (
          <Formik
            enableReinitialize
            initialValues={{
              checked: selectedSkills,
            }}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <div className='flex flex-wrap gap-4 '>
                  {skillOption.map((skill, index) => {
                    return (
                      <label>
                        <div
                          key={index}
                          className={`flex  p-4 px-8 w-auto border-solid rounded-full border border-blue-c1 ${
                            values.checked &&
                            Object.values(values?.checked)?.filter((i) => i === skill)?.length > 0
                              ? 'bg-blue-c1 text-white'
                              : 'bg-color-gray-c9 text-black'
                          }
                        `}
                        >
                          <div className='flex space-x-4'>
                            <div className=' text-base -mt-1'>
                              <Field
                                type='checkbox'
                                className='h-6 w-6 flex-shrink-0'
                                name='checked'
                                value={skill}
                                hidden
                              />
                              {skill}
                            </div>
                          </div>
                        </div>
                      </label>
                    );
                  })}
                </div>
                <div className=' flex mt-8 gap-10'>
                  <button
                    type='button'
                    className='text-gray-c5 rounded py-3 justify-self-start font-medium cursor-pointer'
                    onClick={() => {
                      setSkillMenu(false);
                      setUpdatedSkills(true);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='rounded py-3 justify-self-start font-medium cursor-pointer text-blue-c1 disabled:opacity-40 disbled:cursor-not-allowed'
                    disabled={values.checked?.length === 0}
                  >
                    {loading && <LoadingCircle iconStyle='w-6 mr-2' />}
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <div className='flex flex-wrap gap-4 justify-startb w-full'>
              {selectedSkills.map((skill, index) => {
                return (
                  <label>
                    <div
                      key={index}
                      className={`flex  p-4 px-8 w-auto text-green-c1 border-solid rounded-full border border-blue-c1 bg-blue-c1 text-white'
                       `}
                    >
                      <div className='flex space-x-4'>
                        <div className=' text-white text-base -mt-1'>
                          <input
                            type='checkbox'
                            className='h-6 w-6 flex-shrink-0'
                            name='checked'
                            value={skill}
                            hidden
                          />
                          {skill}
                        </div>
                      </div>
                    </div>
                  </label>
                );
              })}
            </div>
            <div className=' flex mt-8 gap-10'>
              <button
                type='button'
                className='rounded py-3 justify-self-start font-medium cursor-pointer text-blue-c1 disabled:opacity-40 disbled:cursor-not-allowed'
                //disabled={values.checked.length === 0}
                onClick={() => {
                  setSkillMenu(true);
                }}
              >
                {loading && <LoadingCircle iconStyle='w-6 mr-2' />}+ Add Skills
              </button>
            </div>
          </>
        )}
        <button
          type='button'
          disabled={!selectedSkills || selectedSkills?.length === 0}
          onClick={() => {
            setActiveOption('F');
          }}
          className={`disabled:opacity-40 disabled:cursor-not-allowed  md:mb-8 mb-4 md:w-72 w-11/12 bg-green-c1 text-white focus:outline-none font-medium  text-center rounded bottom-0 fixed py-4 -ml-1 md:-ml-0`}
        >
          <span>Next</span>
        </button>
      </div>
      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

export default skills;
