import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { CircularProgress, Tooltip } from '@material-ui/core';
import _ from 'lodash';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjs from 'dayjs';

import { AlertIcon } from '../../components/utils/Icons';
import BookingDetailsCardView from './components/BookingDetailsCardView';
import { PATH } from '../../constants';
import SideBar from './SideBar';
import desertSvg from '../../static/svg/desert.svg';
import { acceptBooking, getOpenBookings } from '../../services/bookingApiService';
import { coachSubTopicsSelector } from '../../redux/authSlice';
import RequestNewSlotsConfirmation from './components/RequestNewSlotsConfirmation';
import { Info } from '@material-ui/icons';

// / Day.js /
dayjs.extend(utc);
dayjs.extend(timezone);

const OpenBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [sortedBookings, setSortedBookings] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const [fetchingBookings, setFetchingBookings] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sortOptions, setSortOptions] = useState({ sortBy: 'far-dates' });
  const [filterOptions, setFilterOptions] = useState({
    theme: [],
    fromDate: null,
    toDate: null,
  });
  const [showRequestNewSlotsModal, setShowRequestNewSlotsModal] = useState(false);

  const { loggedInUser } = useSelector(({ authReducer }) => authReducer);
  const themes = useSelector(({ themeReducer }) => themeReducer.themes);
  const coachSubTopics = useSelector(coachSubTopicsSelector);
  const history = useHistory();

  useEffect(() => {
    const fetchOpenBookings = async () => {
      setFetchingBookings(true);
      if (!coachSubTopics) return;
      const subTopicIds = coachSubTopics
        .filter(({ status }) => status === 'APPROVED')
        .map(({ subTopicId }) => subTopicId);
      try {
        const bookingsData = await getOpenBookings(
          filterOptions.theme.length ? filterOptions.theme : undefined,
          filterOptions.fromDate || undefined,
          filterOptions.toDate || undefined,
          subTopicIds
        );
        setBookings(bookingsData);
        setFetchingBookings(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetchOpenBookings();
  }, [filterOptions, coachSubTopics]);

  useEffect(() => {
    let allBookings = bookings;
    switch (sortOptions.sortBy) {
      case 'high-to-low':
        allBookings.sort((a, b) => b.totalEarning - a.totalEarning);
        break;
      case 'low-to-high':
        allBookings.sort((a, b) => a.totalEarning - b.totalEarning);
        break;
      case 'far-dates':
        allBookings.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        break;
      case 'near-dates':
        allBookings.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      default:
        break;
    }

    // show exclusive bookings on top
    const exclusiveBookings = _.remove(
      allBookings,
      (booking) =>
        booking.exclusiveForCoaches && booking.exclusiveForCoaches.includes(loggedInUser.id)
    );
    allBookings = [...exclusiveBookings, ...allBookings];

    setSortedBookings(allBookings);
  }, [sortOptions, bookings]);

  const handleStudentTimeZone = (date, timeZone = 'Asia/Calcutta', startTime, endTime) => {
    const time = {};
    time.startTime = dayjs.tz(`${date}T${startTime}`, timeZone).toISOString();
    time.endTime = dayjs.tz(`${date}T${endTime}`, timeZone).toISOString();
    return time;
  };

  const handleAcceptBooking = async (booking) => {
    setIsLoading(booking.id);
    try {
      const { bookingId, date, startTime, endTime, timeZone } = selectedTimeSlot;

      const payload = {
        id: bookingId,
        ...handleStudentTimeZone(date, timeZone, startTime, endTime),
      };
      await acceptBooking(payload);
      history.push(PATH.MENTORSHIP.MANAGE_BOOKINGS);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  return (
    <div className='flex gap-4 p-4 bg-gray-c1 dark:bg-ab-primary text-ab-primary dark:text-ab-tertiary font-medium min-h-screen'>
      <div className='flex-grow flex flex-col gap-6'>
        {!loggedInUser && (
          <div className='bg-ab-primary-shade-2 border-2 border-yellow-300 rounded-lg px-6 py-3 flex items-center gap-4'>
            <AlertIcon iconStyle='w-10 text-yellow-500 inline' />
            <span className='text-ab-tertiary-shade-1'>
              You can&apos;t accept any bookings, Login to accept bookings.
            </span>
          </div>
        )}

        {!fetchingBookings ? (
          sortedBookings?.length ? (
            sortedBookings.map((booking) => (
              <div
                key={booking.id}
                className='bg-ab-tertiary dark:bg-ab-primary-shade-1 border border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100 rounded-lg overflow-hidden'
              >
                <BookingDetailsCardView
                  booking={booking}
                  selectedTimeSlot={selectedTimeSlot}
                  setSelectedTimeSlot={setSelectedTimeSlot}
                />

                <hr className='mx-4 border-t border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100' />

                <div className='flex justify-between p-4'>
                  <div className='flex-grow flex items-center text-ab-primary dark:text-ab-tertiary font-normal'>
                    {booking?.SubTopic?.duration && (
                      <span className='font-medium opacity-60 ml-4'>{`Duration: ${booking.SubTopic.duration} Mins`}</span>
                    )}
                  </div>
                  {booking.isNewSlotsRequested ? (
                    <div className='font-medium text-yellow-c1 flex items-center gap-2 opacity-80'>
                      <Info />
                      <span>Requested for new time slots</span>
                    </div>
                  ) : (
                    <>
                      {booking.exclusiveForCoaches &&
                        booking.exclusiveForCoaches.includes(loggedInUser.id) && (
                          <button
                            onClick={() => setShowRequestNewSlotsModal(booking)}
                            className='px-8 py-2 opacity-80'
                          >
                            Request New Time Slots
                          </button>
                        )}
                      <Tooltip
                        arrow
                        disableHoverListener={selectedTimeSlot.bookingId === booking.id}
                        disableFocusListener={selectedTimeSlot.bookingId === booking.id}
                        disableTouchListener={selectedTimeSlot.bookingId === booking.id}
                        title={
                          <span className='text-base'>Select time slot to Accept Session</span>
                        }
                      >
                        <button
                          onClick={() => handleAcceptBooking(booking)}
                          disabled={
                            !loggedInUser || isLoading || selectedTimeSlot.bookingId !== booking.id
                          }
                          className={`text-white rounded focus:outline-none py-2 w-48 bg-green-c1 dark:bg-ab-secondary ${
                            !loggedInUser ||
                            (selectedTimeSlot.bookingId !== booking.id &&
                              ' opacity-50 cursor-not-allowed')
                          }`}
                        >
                          {isLoading === booking.id ? (
                            <CircularProgress color='inherit' size='1.5rem' className='-mb-1.5' />
                          ) : (
                            <span>Accept Session</span>
                          )}
                        </button>
                      </Tooltip>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className='m-auto text-center'>
              <img src={desertSvg} className='w-32 inline' alt='' />
              <h1 className='font-medium text-2xl mt-4 mb-8'>No Bookings To Show</h1>
              <Link
                to={PATH.MENTORSHIP.MANAGE_BOOKINGS}
                className='bg-green-c1 dark:bg-ab-secondary text-lg text-ab-tertiary rounded px-8 py-2'
              >
                Manage Bookings
              </Link>
            </div>
          )
        ) : (
          <div className='h-full flex items-center justify-center text-blue-c1 dark:text-ab-tertiary'>
            <CircularProgress size='4rem' color='inherit' />
          </div>
        )}
      </div>

      <SideBar
        themes={themes}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        openMode={true}
        sortOptions={sortOptions}
        setSortOptions={setSortOptions}
      />

      {showRequestNewSlotsModal && (
        <RequestNewSlotsConfirmation
          booking={showRequestNewSlotsModal}
          onClose={() => setShowRequestNewSlotsModal(false)}
        />
      )}
    </div>
  );
};

export default OpenBookings;
