import React, { useState, useEffect } from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@material-ui/core';

import { PATH } from '../../../constants';
import { Triangle, ReverseTriangle, LoadingCircle } from '../../utils/Icons';
import { updateCoachExpertise } from '../../../services/userApiService';
import CommonButton from '../../common/CommonButton';
import { coachSubTopicsSelector, setCoachSubTopics } from '../../../redux/authSlice';

const Preferences = ({ editMode, themes }) => {
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [descriptionToggle, setDescriptionToggle] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const coachSubTopics = useSelector(coachSubTopicsSelector);
  const totalSubTopics = useSelector((state) => state.themeReducer.subTopics);
  const dispatch = useDispatch();

  useEffect(() => {
    setData(coachSubTopics);
    setFormData(coachSubTopics);
  }, [coachSubTopics]);

  const handleCheckBox = ({ target }, id) => {
    const arr = [...formData];

    if (target.checked) arr.push({ subTopicId: id, status: 'PENDING' });
    else
      arr.splice(
        arr.findIndex((item) => item.subTopicId === id),
        1
      );

    setFormData(arr);
  };

  const handleSelectAll = () => {
    if (formData?.length === totalSubTopics?.length) setFormData([]);
    else setFormData(totalSubTopics.map(({ id }) => ({ subTopicId: id, status: 'PENDING' })));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsUpdating(true);

    try {
      const updatedData = await updateCoachExpertise(formData);
      setData(updatedData);
      setFormData(updatedData);
      toast.info('Profile Updated!', {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
      dispatch(setCoachSubTopics(updatedData));
    } catch (err) {
      console.error(err);
      toast.error('Failed To Update Profile! \n Please Try Again Later!!', {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }

    setIsUpdating(false);
  };

  return (
    <div className='w-full max-w-4xl mt-6 md:mt-8 text-ab-primary dark:text-ab-tertiary'>
      <div className='font-medium opacity-60'>
        Please select the topics that you would like to conduct interviews on
      </div>

      <div className='flex items-center mt-6 -mb-8'>
        <Checkbox
          checked={formData?.length === totalSubTopics?.length}
          onChange={handleSelectAll}
          color='primary'
        />

        <span className='font-medium opacity-60'>Select All</span>
      </div>

      {/* Render Session Theme list */}
      {themes.map(({ id, name, description, topics }, index) => (
        <div key={id}>
          <div className='text-green-c1 dark:text-ab-tertiary text-xl font-semibold mt-12'>
            {index + 1}. {name}
          </div>

          {/* theme description */}
          <p className='font-normal opacity-60 mt-4'>{description}</p>

          {/* Render Theme Topics */}
          {topics &&
            topics.map(({ id, name, subTopics }) => (
              <div key={id}>
                <div className='text-green-c1 dark:text-ab-tertiary font-semibold mt-6 mb-2 ml-10'>
                  {name}
                </div>

                {/* Render SubTopics */}
                {subTopics &&
                  subTopics.map(({ id, name, description }) => (
                    <div key={id}>
                      <div className='flex items-center'>
                        <Checkbox
                          checked={formData.some((item) => item.subTopicId === id)}
                          onChange={(e) => handleCheckBox(e, id)}
                          color='primary'
                        />

                        {/* subtopic name */}
                        <span
                          onClick={() =>
                            setDescriptionToggle({
                              ...descriptionToggle,
                              [id]: !descriptionToggle[id],
                            })
                          }
                          className='flex-grow cursor-pointer font-medium opacity-60 select-none'
                        >
                          {name}
                        </span>

                        <span
                          onClick={() =>
                            setDescriptionToggle({
                              ...descriptionToggle,
                              [id]: !descriptionToggle[id],
                            })
                          }
                        >
                          {!descriptionToggle[id] && (
                            <ReverseTriangle iconStyle='w-3 inline opacity-30 cursor-pointer' />
                          )}
                          {descriptionToggle[id] && (
                            <Triangle iconStyle='w-3 inline text-blue-c1 cursor-pointer' />
                          )}
                        </span>

                        <span className='w-1/3'>
                          {data.some(
                            (item) => item.subTopicId === id && item.status === 'APPROVED'
                          ) && <CheckCircleIcon style={{ color: '#76CE65', marginLeft: '20px' }} />}
                          {data.some(
                            (item) => item.subTopicId === id && item.status === 'PENDING'
                          ) && (
                            <span className='text-yellow-500 text-xs flex items-center gap-2 ml-4'>
                              <ErrorIcon /> Under Review
                            </span>
                          )}
                        </span>
                      </div>

                      {/* subtopic description */}
                      {descriptionToggle[id] && (
                        <p className='font- font-light opacity-60 px-12 mb-2 max-w-lg text-justify'>
                          {description || '(No description found)'}
                        </p>
                      )}
                    </div>
                  ))}
              </div>
            ))}
        </div>
      ))}

      <div className='grid grid-cols-1 md:grid-cols-2 gap-8 pt-10 md:pt-16 pb-4'>
        <CommonButton
          onClick={handleSubmit}
          type='button'
          className='bg-green-c1 dark:bg-ab-secondary text-white font-medium rounded focus:outline-none py-4'
        >
          {isUpdating && <LoadingCircle iconStyle='w-6 mr-2' />}
          {editMode ? 'UPDATE EXPERTISE' : 'NEXT'}
        </CommonButton>

        {!editMode && (
          <Link to={PATH.HOME} className='text-gray-400 m-auto'>
            Skip for now
          </Link>
        )}
      </div>

      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

export default Preferences;
