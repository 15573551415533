import { CircularProgress } from '@material-ui/core';
import { useState } from 'react';
import { requestNewTimeSlots } from '../../../services/bookingApiService';

const RequestNewSlotsConfirmation = ({ booking, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      await requestNewTimeSlots(booking.id);
      booking.isNewSlotsRequested = true;
      onClose();
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };
  return (
    <div className='fixed inset-0 bg-black bg-opacity-80 flex z-20'>
      <div className='m-auto bg-white rounded-lg p-4'>
        <h1 className='text-2xl font-medium px-2'>Request New Time Slots</h1>
        <p className='px-2 mt-2 font-normal opacity-80'>
          An email will be sent to student requesting new time slots. <br />
          Kindly coordinate over email to set new time slots.
        </p>
        <div className='mt-6 flex items-center justify-end'>
          <button onClick={onClose} className='px-8 py-1 opacity-60'>
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className='bg-green-c1 dark:bg-ab-secondary text-ab-tertiary rounded px-4 py-1 flex items-center gap-2'
          >
            {isLoading && <CircularProgress color='inherit' size='1.5rem' />}
            <span>Confirm</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RequestNewSlotsConfirmation;
