import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import {
  ChevronLeft,
  ChevronRight,
  // EventAvailable,
  Payment as PaymentIcon,
} from '@material-ui/icons';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { CircularProgress, IconButton, Tooltip } from '@material-ui/core';
import 'react-toastify/dist/ReactToastify.min.css';
import ErrorIcon from '@material-ui/icons/Error';

import {
  EditIcon,
  BriefcaseIcon,
  GraduationHatIcon,
  NotepadCheckedIcon,
  ShieldIcon,
  Verified,
} from '../utils/Icons';
import BasicInfo from './sections/BasicInfo';
import Education from './sections/Education';
import Preferences from './sections/Preferences';
import Password from './sections/Password';
import WorkExperience from './sections/WorkExperience';
import Payment from './sections/Payment';
import KycDetails from './sections/KycDetails';
import { getMyKycDetails } from '../../services/userApiService';

const OPTIONS = {
  A: 'Edit Basic Info',
  B: 'Education',
  C: 'Work Experience',
  D: 'Preferences',
  E: 'Password',
  // F: 'Availability',
  G: 'Payment',
  F: 'KYC',
};

const EditProfile = () => {
  const [activeOption, setActiveOption] = useState(OPTIONS.A);
  const [kycDetails, setKycDetails] = useState(null);
  const me = useSelector(({ authReducer }) => authReducer.loggedInUser);
  const coachInfo = useSelector(({ authReducer }) => authReducer.coachInfo);
  const unverifiedEducation = coachInfo?.unverified?.education || [];
  const education = coachInfo?.verified?.education || [];
  const workExperience = coachInfo?.verified?.workExperience || [];
  const unverifiedWorkExperience = coachInfo?.unverified?.workExperience || [];
  const themes = useSelector(({ themeReducer }) => themeReducer.themes);

  async function getKycDetails() {
    const coachKycDetails = await getMyKycDetails();
    setKycDetails(coachKycDetails || {});
  }

  useEffect(() => {
    getKycDetails();
  }, []);

  if (!me || !kycDetails)
    return (
      <div className='mt-32 flex items-center justify-center text-blue-c1 dark:text-ab-tertiary'>
        <CircularProgress color='inherit' />
      </div>
    );

  return (
    <div className='text-left grid grid-cols-6 md:grid-cols-12 bg-ab-tertiary dark:bg-ab-primary border-t border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100 md:gap-20 font-medium'>
      <div className='col-span-1 md:col-span-3'>
        <Sidebar
          activeOption={activeOption}
          kycStatus={kycDetails}
          setActiveOption={setActiveOption}
        />
      </div>
      <div
        className='col-span-5 md:col-span-9 w-full flex justify-center md:mx-0 overflow-auto px-5'
        style={{ height: 'calc(100vh - 90px)' }}
      >
        {activeOption === OPTIONS.A && <BasicInfo editMode={true} me={me} />}
        {activeOption === OPTIONS.B && (
          <Education
            editMode={true}
            education={education}
            unverifiedEducation={unverifiedEducation}
            // newEducation={me.newEducation}
          />
        )}
        {activeOption === OPTIONS.C && (
          <WorkExperience
            editMode={true}
            workExperience={workExperience}
            unverifiedWorkExperience={unverifiedWorkExperience}
          />
        )}
        {activeOption === OPTIONS.D && <Preferences editMode={true} themes={themes} />}
        {activeOption === OPTIONS.E && (
          <Password editMode={true} paymentDetails={me.paymentDetails} />
        )}
        {activeOption === OPTIONS.G && (
          <Payment editMode={true} paymentDetails={me.paymentDetails} />
        )}
        {activeOption === OPTIONS.F && (
          <KycDetails
            setCoachKycDetails={setKycDetails}
            getKycDetails={getKycDetails}
            coachKycDetails={kycDetails}
            userInfo={me}
          />
        )}
      </div>
    </div>
  );
};

const Sidebar = ({ activeOption, setActiveOption, kycStatus }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const nonActiveClass =
    'border-b border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100 text-ab-primary dark:text-ab-tertiary text-opacity-30 dark:text-opacity-60 py-4 md:py-5 pl-5 md:pl-8 cursor-pointer flex';
  const activeClass =
    'border-b border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100 text-green-c1 dark:text-ab-tertiary font-semibold py-4 md:py-5 pl-5 md:pl-8 bg-white bg-opacity-10 box-border flex';

  const handleSidebar = () => {
    setSidebarOpen((prevState) => !prevState);
  };

  return (
    <>
      <div
        className={`relative z-20 ${
          sidebarOpen ? 'w-64' : 'w-full'
        } trasnsition-all duration-300 bg-ab-tertiary dark:bg-ab-primary-shade-1 md:dark:bg-black`}
      >
        <div
          className='text-left md:w-full border-r border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100 flex flex-col'
          style={{ height: 'calc(100vh - 85px)' }}
        >
          {/* ---Basic Info--- */}
          <div
            onClick={() => {
              setActiveOption(OPTIONS.A);
              setSidebarOpen(false);
            }}
            className={activeOption === OPTIONS.A ? activeClass : nonActiveClass}
          >
            <span>
              <EditIcon iconStyle='w-6 inline md:mx-6' />
            </span>
            <span
              className={`${
                sidebarOpen ? 'inline ml-4 tracking-wide truncate' : 'hidden'
              } md:inline`}
            >
              {OPTIONS.A}
            </span>
          </div>
          {/* ---Education--- */}
          <div
            onClick={() => {
              setActiveOption(OPTIONS.B);
              setSidebarOpen(false);
            }}
            className={activeOption === OPTIONS.B ? activeClass : nonActiveClass}
          >
            <span>
              <GraduationHatIcon iconStyle='w-6 inline md:mx-6' />
            </span>
            <span
              className={`${
                sidebarOpen ? 'inline ml-4 tracking-wide truncate' : 'hidden'
              } md:inline`}
            >
              {OPTIONS.B}
            </span>
          </div>
          {/* ---Work Experience--- */}
          <div
            onClick={() => {
              setActiveOption(OPTIONS.C);
              setSidebarOpen(false);
            }}
            className={activeOption === OPTIONS.C ? activeClass : nonActiveClass}
          >
            <span>
              <BriefcaseIcon iconStyle='w-5 inline md:mx-6' />
            </span>
            <span
              className={`${
                sidebarOpen ? 'inline ml-4 tracking-wide truncate' : 'hidden'
              } md:inline`}
            >
              {OPTIONS.C}
            </span>
          </div>
          {/* ---Expertise--- */}
          <div
            onClick={() => {
              setActiveOption(OPTIONS.D);
              setSidebarOpen(false);
            }}
            className={activeOption === OPTIONS.D ? activeClass : nonActiveClass}
          >
            <span>
              <NotepadCheckedIcon iconStyle='w-6 inline md:mx-6' />
            </span>
            <span
              className={`${
                sidebarOpen ? 'inline ml-4 tracking-wide truncate' : 'hidden'
              } md:inline`}
            >
              {OPTIONS.D}
            </span>
          </div>
          {/* ---Password--- */}
          <div
            onClick={() => {
              setActiveOption(OPTIONS.E);
              setSidebarOpen(false);
            }}
            className={activeOption === OPTIONS.E ? activeClass : nonActiveClass}
          >
            <span>
              <ShieldIcon iconStyle='w-4 inline md:mx-7' />
            </span>
            <span
              className={`${
                sidebarOpen ? 'inline ml-4 tracking-wide truncate' : 'hidden'
              } md:inline`}
            >
              {OPTIONS.E}
            </span>
          </div>
          {/* ---Availability--- */}
          {/* <div
            onClick={() => {
              setActiveOption(OPTIONS.F);
              setSidebarOpen(false);
            }}
            className={
              activeOption === OPTIONS.F ? activeClass : nonActiveClass
            }
            style={
              activeOption === OPTIONS.F
                ? { borderLeft: 'solid 4px #CC0000' }
                : { borderLeft: 'solid 4px transparent' }
            }
          >
            <span className='mx-6'>
              <EventAvailable />
            </span>
            <span
              className={`${
                sidebarOpen ? 'inline ml-4 tracking-wide truncate' : 'hidden'
              } md:inline`}
            >
              {OPTIONS.F}
            </span>
          </div> */}
          {/* ---Payment--- */}
          <div
            onClick={() => {
              setActiveOption(OPTIONS.G);
              setSidebarOpen(false);
            }}
            className={activeOption === OPTIONS.G ? activeClass : nonActiveClass}
          >
            <span className='md:mx-6'>
              <PaymentIcon />
            </span>
            <span
              className={`${
                sidebarOpen ? 'inline ml-4 tracking-wide truncate' : 'hidden'
              } md:inline`}
            >
              {OPTIONS.G}
            </span>
          </div>
          <div
            onClick={() => {
              setActiveOption(OPTIONS.F);
              setSidebarOpen(false);
            }}
            className={activeOption === OPTIONS.F ? activeClass : nonActiveClass}
          >
            <span className='md:mx-6'>
              <VerifiedUserOutlinedIcon />
            </span>
            <span
              className={`${
                sidebarOpen ? 'inline ml-4 tracking-wide truncate' : 'hidden'
              } md:inline`}
            >
              {OPTIONS.F}
            </span>
            {kycStatus?.status &&
              (kycStatus.status === 'APPROVED' ? (
                <Verified iconStyle='w-6 ml-auto mr-7 inline text-green-500' />
              ) : (
                <Tooltip
                  arrow
                  title={
                    <span className='text-base'>
                      {kycStatus.status === 'REJECTED'
                        ? 'Verification Failed'
                        : 'Verification Pending'}
                    </span>
                  }
                >
                  <ErrorIcon
                    className={`ml-auto mr-7  ${
                      kycStatus.status === 'REJECTED' ? 'text-red-500' : 'text-yellow-300'
                    }`}
                  />
                </Tooltip>
              ))}
          </div>
          <div className='justify-self-end inline-flex justify-center items-center mt-auto mb-3 md:hidden'>
            <IconButton onClick={handleSidebar}>
              {sidebarOpen ? (
                <>
                  <ChevronLeft />
                  <span className='text-base'>Hide</span>
                </>
              ) : (
                <ChevronRight />
              )}
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
