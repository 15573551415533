import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { useSelector } from 'react-redux';

import { CalendarIcon } from '../utils/Icons';
import RenderThumbnail from '../utils/RenderThumbnail';
import { makeStyles, Tooltip } from '@material-ui/core';
import pageIcon from '../../static/img/single-page.png';
import starPageIcon from '../../static/img/star-page.png';
import phoneIcon from '../../static/svg/phone.svg';
import { DATE_FORMAT_1 } from '../../constants';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

import FeedbackModal from './FeedbackModal';
import PreviousFeedbackModal from './PreviousFeedbackModal';

import SubmittedSolutionModal from './SubmittedSolutionModal';

const STATUS_STYLES = {
  'NOT NEEDED': 'bg-blue-c1 text-blue-c1',
  PENDING: 'border border-solid border-yellow-c1 rounded px-2 py-1 text-sm rounded text-yellow-c1',
  COMPLETED: 'border border-solid border-green-c2 rounded px-2 py-1 text-sm rounded text-green-c2',
  REJECTED: 'border border-solid border-red-700 rounded px-2 py-1 text-sm rounded text-red-700',
};

const useStyles = makeStyles(() => ({
  tooltip: {
    color: '#000',
    backgroundColor: '#fff',
    fontSize: '16px',
    padding: '10px',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.2)',
    borderRadius: '8px',
  },
  tooltipArrow: {
    color: 'rgba(100,100,100, 0.6)',
  },
}));

const DetailsCard = ({ item, onEvaluationSubmit }) => {
  const myUserDetails = useSelector((state) => state.authReducer.loggedInUser);

  const classes = useStyles();

  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const handleOpenFeedbackModal = () => setOpenFeedbackModal(true);
  const handleCloseFeedbackModal = () => setOpenFeedbackModal(false);

  const [openPrevFeedbackModal, setOpenPrevFeedbackModal] = useState(false);
  const handleOpenPrevFeedbackModal = () => setOpenPrevFeedbackModal(true);
  const handleClosePrevFeedbackModal = () => setOpenPrevFeedbackModal(false);

  const [openSubmittedSolutionModal, setOpenSubmittedSolutionModal] = useState(false);

  const [previousFeedbackObj, setPreviousFeedbackObj] = useState(null);

  const isPreviousFeedback =
    item.reEvaluationRequested != null &&
    item.previousCredits != null &&
    previousFeedbackObj != null;

  useEffect(() => {
    if (item.reEvaluationRequested) {
      item.previousSolutions.forEach((prevSol) => {
        if (prevSol.coachFeedback) {
          setPreviousFeedbackObj(prevSol);
        }
      });
    }
  }, []);

  return (
    <div className='w-full bg-white p-6 text-gray-600 rounded-lg shadow-md border border-solid border-gray-100'>
      {/* Top Section */}
      <section className='flex flex-col gap-3'>
        <div className='flex justify-between items-center'>
          <span className='flex items-center gap-6'>
            <h1 className='text-2xl font-semibold text-ab-primary'>
              {item?.project?.attributes.name}
            </h1>
          </span>
          <div className='flex'>
            {isPreviousFeedback && (
              <span
                className='border border-solid px-2 py-1 text-sm rounded  mr-2'
                style={{ borderColor: '#CF40D2', color: '#CF40D2' }}
              >
                Revaluation Requested
              </span>
            )}
            <span
              className={
                (STATUS_STYLES[
                  item?.evaluationStatus ? item?.evaluationStatus?.toUpperCase() : 'PENDING'
                ] || 'bg-purple-500 text-purple-500') +
                ' bg-opacity-25 py-1 px-2 rounded capitalize'
              }
            >
              {item?.evaluationStatus ? item?.evaluationStatus : 'PENDING'}
            </span>
          </div>
        </div>
        {/* <span className='text-sm text-ab-primary text-opacity-40'>
          Session ID #{cardData.id}
        </span> */}
        <h3 className='text-lg font-medium text-ab-primary text-opacity-60'>
          {item?.project?.attributes?.project_category?.data?.attributes?.name}
        </h3>
        <div className='flex items-center gap-6 text-sm'>
          <div className='flex items-center gap-2'>
            <span className='text-gray-400'>Submitted on:</span>
            <span className='inline-flex items-center font-medium bg-blue-c5 p-2 rounded-md '>
              <CalendarIcon iconStyle='w-4 inline mr-2' />
              {dayjs(item?.submittedAt).format(DATE_FORMAT_1)}
            </span>
          </div>
          {item.evaluatedAt && (
            <div className='flex items-center gap-2'>
              <span className='text-gray-400'>Evaluated on:</span>
              <span className='inline-flex items-center font-medium bg-blue-c5 p-2 rounded-md'>
                <CalendarIcon iconStyle='w-4 inline mr-2' />
                {dayjs(item?.evaluatedAt).format(DATE_FORMAT_1)}
              </span>
            </div>
          )}
        </div>
      </section>
      <hr className='my-5' />

      {/* mid section */}
      <section className='flex flex-col gap-4 my-5'>
        <div className='flex flex-col md:flex-row md:items-center gap-2 md:gap-10'>
          {/* evaluator details */}
          <div className='mr-2 space-y-1 border border-solid border-ab-primary border-opacity-20 rounded-md px-4 py-2 flex items-center gap-3'>
            <RenderThumbnail
              name={myUserDetails.firstName}
              thumbnail={myUserDetails?.thumbnail}
              size='3'
            />

            <div className='flex flex-col gap-2'>
              <h3 className='font-medium'>
                {myUserDetails.firstName} {myUserDetails.lastName}
              </h3>
              <span className='flex items-center gap-2'>
                <span className='bg-blue-c1 bg-opacity-10 text-blue-c1 text-opacity-80 px-2 rounded text-sm'>
                  Evaluator
                </span>
                <Tooltip
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  title={myUserDetails?.phone || ''}
                >
                  <img src={phoneIcon} alt='Phone' />
                </Tooltip>
              </span>
            </div>
          </div>
          {/* Candidate Details */}
          <div className='mr-2 space-y-1 border border-solid border-ab-primary border-opacity-20 rounded-md px-4 py-2 flex items-center gap-3'>
            <RenderThumbnail
              name={item?.student?.firstName}
              thumbnail={item?.student?.thumbnail}
              size='3'
            />
            <div className='flex flex-col gap-2'>
              <h3 className='font-medium'>
                {item?.student?.firstName} {item?.student?.lastName}
              </h3>
              <span className='flex items-center gap-2'>
                <span className='bg-yellow-c1 bg-opacity-10 text-yellow-c1 px-2 rounded text-sm'>
                  Candidate
                </span>
                <Tooltip
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  title={item?.student?.phone || ''}
                >
                  <img src={phoneIcon} alt='Phone' />
                </Tooltip>
              </span>
            </div>
          </div>
        </div>

        {/* links */}
        <div className='flex items-center gap-8 text-blue-c2 font-medium'>
          <a
            href={item?.project?.attributes?.correctSolution}
            target='_blank'
            rel='noreferrer'
            className='flex items-center gap-2 cursor-pointer'
          >
            <span className='bg-blue-c5 p-2 rounded'>
              <img src={starPageIcon} alt='Pages' className='w-6' />
            </span>
            Correct Solution
          </a>
          <a
            // href={item.submittedSolution}
            target='_blank'
            rel='noreferrer'
            className='flex items-center gap-2 cursor-pointer'
            onClick={() => setOpenSubmittedSolutionModal(true)}
          >
            <span className='bg-blue-c5 p-2 rounded'>
              <img src={pageIcon} alt='Pages' className='w-6' />
            </span>
            Submitted Solution
          </a>
        </div>
      </section>
      <hr className='my-5' />

      {/* bottom section */}
      <section>
        <div className='border-t border-gray-300'>
          <div className='flex flex-col-reverse md:flex-row gap-4 md:gap-8 items-center'>
            <div className='flex-grow flex items-center'>
              <span className='font-semibold text-gray-600 mr-2 md:mr-4 pr-2 md:pr-4 border-r border-gray-300'>
                Credits:{' '}
                <span className='bg-blue-c5 p-2 rounded-md ml-2'>
                  {item?.credits || '--'} / 100
                </span>
              </span>

              {isPreviousFeedback && (
                <div
                  className='text-blue-c2 font-medium cursor-pointer'
                  onClick={handleOpenPrevFeedbackModal}
                >
                  Previous Credits: {item.previousCredits}
                </div>
              )}

              <a
                // href=''
                // target='_blank'
                // rel='noreferrer'
                className='ml-auto px-10 py-2 bg-green-c6 text-ab-tertiary rounded font-medium cursor-pointer'
                onClick={handleOpenFeedbackModal}
              >
                {!item?.evaluationStatus || item?.evaluationStatus === 'pending'
                  ? 'Evaluate Now'
                  : 'View Evaluation'}
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* previous feedback */}
      {isPreviousFeedback && (
        <PreviousFeedbackModal
          open={openPrevFeedbackModal}
          handleOpen={handleOpenPrevFeedbackModal}
          handleClose={handleClosePrevFeedbackModal}
          item={item}
          previousCoachFeedbackObj={previousFeedbackObj}
        />
      )}

      {/* ratings & feedback */}
      <FeedbackModal
        open={openFeedbackModal}
        handleOpen={handleOpenFeedbackModal}
        handleClose={handleCloseFeedbackModal}
        item={item}
        onEvaluationSubmit={onEvaluationSubmit}
      />

      {/* submitted solution */}
      <SubmittedSolutionModal
        open={openSubmittedSolutionModal}
        handleOpen={() => setOpenSubmittedSolutionModal(true)}
        handleClose={() => setOpenSubmittedSolutionModal(false)}
        item={item}
      />
    </div>
  );
};

export default DetailsCard;
