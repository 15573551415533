import React, { useState, useEffect } from 'react';

import { Modal } from '@material-ui/core';

export default function SubmittedSolutionModal({ open, handleClose, item }) {
  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className=''
    >
      <div className='relative top-2 md:top-10 m-auto bg-ab-tertiary dark:bg-ab-primary-shade-1 rounded-lg md:px-8 px-3 py-4 z-50 lg:w-1/2 w-11/12 border-solid border border-gray-600'>
        <div className='absolute right-2 top-2 cursor-pointer' onClick={handleClose}>
          X
        </div>
        <h3 className='font-medium'>Submitted Solution</h3>

        <hr className='mt-4 mb-4'></hr>

        <div className='flex'>
          <span className='text-sm font-normal opacity-60'>Codelab link:</span>
          <a
            href={item.submittedSolution?.codeLink}
            target='_blank'
            rel='noreferrer'
            className='flex items-center gap-2 ml-5 text-sm font-normal w-10/12 break-all'
          >
            {item.submittedSolution?.codeLink}
          </a>
        </div>
      </div>
    </Modal>
  );
}
