import React from 'react';

import { Link } from 'react-router-dom';

import { PATH } from '../../../../constants';

const CompletionPage = () => {
  return (
    <div className='bg-ab-tertiary dark:bg-opacity-10 border border-solid border-ab-primary-shade-2 border-opacity-20 rounded-xl mt-4 p-4'>
      <div className='flex'>
        <div className='m-auto p-12'>
          <svg
            className='w-48'
            viewBox='0 0 151 118'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <circle opacity='0.3' cx='74' cy='60' r='35' stroke='#76CE65' strokeWidth='10' />
            <circle opacity='0.1' cx='74' cy='60' r='45' stroke='#76CE65' strokeWidth='10' />
            <rect x='44' y='30' width='60' height='60' rx='30' fill='white' />
            <path
              d='M74 30C57.44 30 44 43.44 44 60C44 76.56 57.44 90 74 90C90.56 90 104 76.56 104 60C104 43.44 90.56 30 74 30ZM68 75L53 60L57.23 55.77L68 66.51L90.77 43.74L95 48L68 75Z'
              fill='#76CE65'
            />
            <circle opacity='0.8' cx='104' cy='13' r='4' fill='#8A3FFC' />
            <circle opacity='0.5' cx='145' cy='39' r='6' fill='#148EFF' />
            <circle opacity='0.5' cx='109' cy='106' r='5' fill='#7277F9' />
            <circle opacity='0.5' cx='21' cy='6' r='4' fill='#7277F9' />
            <circle opacity='0.5' cx='6' cy='62' r='6' fill='#7277F9' />
            <path
              opacity='0.8'
              d='M118.469 63.6351L132.292 64.5922L124.552 76.085L118.469 63.6351Z'
              fill='#148EFF'
            />
            <path
              opacity='0.5'
              d='M71.6045 111L74.6356 116.25H68.5734L71.6045 111Z'
              fill='#148EFF'
            />
            <path
              opacity='0.5'
              d='M37.6191 97.3736L50.7064 99.4234L44.4379 108.731L37.6191 97.3736Z'
              fill='#148EFF'
            />
            <path
              opacity='0.5'
              d='M58.4069 1.14596L66.7814 12.6397L47.8325 10.6549L58.4069 1.14596Z'
              fill='#148EFF'
            />
            <rect
              opacity='0.8'
              x='18'
              y='74.4277'
              width='10'
              height='4'
              transform='rotate(-40 18 74.4277)'
              fill='#8A3FFC'
            />
            <rect
              opacity='0.8'
              x='33.8359'
              y='33.0767'
              width='14'
              height='5'
              transform='rotate(-171.228 33.8359 33.0767)'
              fill='#148EFF'
            />
            <rect
              opacity='0.8'
              x='120'
              y='37.4834'
              width='12'
              height='2'
              transform='rotate(-73.1295 120 37.4834)'
              fill='#148EFF'
            />
          </svg>
        </div>
      </div>

      <h1 className='text-2xl font-semibold text-center'>Feedback submitted successfully</h1>
      <p className='text-center opacity-60 font-normal mt-4'>
        Your Session will be reviewed by Admin for approval. Thank you for being an active Coach
        with AlmaBetter
      </p>

      <div className='flex my-8'>
        <Link
          to={PATH.MENTORSHIP.MANAGE_BOOKINGS}
          className='py-3 px-8 m-auto bg-green-c1 dark:bg-ab-secondary font-medium text-white rounded'
        >
          Manage Booking
        </Link>
      </div>
    </div>
  );
};

export default CompletionPage;
