import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import { ArrowBack, CalendarTodayOutlined, LinkedIn } from '@material-ui/icons';

import dayjs from 'dayjs';

import { DATE_ONLY_FORMAT_3, PATH } from '../../../constants';
import RenderThumbnail from '../../../components/utils/RenderThumbnail';
import RichTextEditor from '../../../components/utils/RichTextEditor';

const InterviewGuidelines = ({ booking, setShowGuidelines }) => {
  const [isTicked, setIsTicked] = useState(false);
  const student = booking.Student;

  return (
    <div className='h-screen bg-ab-tertiary dark:bg-ab-primary mx-auto py-8'>
      <header className='container mx-auto'>
        <Link
          to={PATH.MENTORSHIP.MANAGE_BOOKINGS}
          className='float-left opacity-60 flex gap-2 items-center'
        >
          <ArrowBack />
          <span>Back</span>
        </Link>
        <div className='text-3xl font-semibold text-center'>Conduct Session</div>
      </header>

      <section className='container bg-ab-tertiary dark:bg-ab-primary-shade-1 text-left font-medium border border-solid border-ab-primary-shade-2 border-opacity-20 rounded-lg py-8 px-16 mt-8 mx-auto'>
        <div>
          <div className='my-2 mt-4 flex items-center'>
            <h1 className='text-2xl font-semibold'>{booking.themeName}</h1>
            <span className='float-right opacity-60 inline-flex items-center gap-2'>
              <CalendarTodayOutlined />
              <span>Date: {dayjs(booking.startTime).format(DATE_ONLY_FORMAT_3)}</span>
            </span>
            <div className='text-lg font-medium opacity-60 mt-1'>{booking.subTopicName}</div>
          </div>
        </div>

        <div className='text-sm font-normal opacity-60 mt-6 mb-2'>Candidate</div>
        <div className='border-b border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100 border-solid opacity-80 mb-8 pb-8 flex items-center'>
          <RenderThumbnail thumbnail={student.thumbnail} name={student.firstName} size='3.5' />
          <span className='mr-2 ml-4'>
            {student.firstName} {student.lastName}
          </span>
          {student.linkedInUrl && (
            <a href={student.linkedInUrl}>
              <LinkedIn htmlColor='#0275B4' />
            </a>
          )}
          <span className='mx-2 font-thin text-xl'>|</span>
          <span className='mx-2'>{student.phone}</span>
          <span className='mx-2 font-thin text-xl'>|</span>
          <span className='mx-2'>{student.email}</span>
          {/* <span className='mx-2'>|</span>
          <a href='#' className='mx-2 font-semibold text-blue-c1'>
            Download Resume
          </a> */}
        </div>

        <div>
          {booking.SubTopic?.instructionForCoach && (
            <>
              <div className='my-2 mt-4'>
                <h1 className='text-2xl font-medium opacity-80'>Instructions</h1>
              </div>
              <div className='font-normal opacity-80'>
                <RichTextEditor value={booking.SubTopic.instructionForCoach} />
              </div>
            </>
          )}

          <div className='my-2 mt-8'>
            <h1 className='text-2xl font-medium opacity-80'>Guidelines</h1>
          </div>
          <ul className='list-disc opacity-60 font-normal ml-4 mt-4'>
            <li className='mb-2'>
              Mentorship Video session will be recorded for verification. This is mandatory for
              Admin approval of mentorship session
            </li>
            <li className='mb-2'>
              Please ask Mentee to share their screen and scribble on Google docs if required
            </li>
            <li className='mb-2'>
              Please click on "Ask Question" when you ask a question to the student
            </li>
          </ul>
          <div className='mt-8 -ml-4 flex items-center gap-2'>
            <Checkbox
              onChange={(e) => setIsTicked(e.target.checked)}
              checked={isTicked}
              className={!isTicked && 'opacity-60'}
              color='primary'
            />
            <span className='opacity-60 -ml-2'>
              I have read & accepted all Instructions & Guidelines
            </span>
          </div>
          <div className='text-center mb-4 mt-8'>
            <button
              onClick={() => setShowGuidelines(false)}
              disabled={!isTicked}
              className={
                'px-6 py-3 text-white font-medium rounded bg-green-c1 dark:bg-ab-secondary ' +
                (!isTicked && ' opacity-50 cursor-not-allowed')
              }
            >
              Conduct Session
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default InterviewGuidelines;
