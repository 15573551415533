import { createSlice } from '@reduxjs/toolkit';
import { getThemes } from '../services/bookingApiService';

const initialState = {
  themes: [],
  topics: [],
  subTopics: [],
};

const themeSlice = createSlice({
  name: 'themeReducer',
  initialState,
  reducers: {
    setThemes: (state, action) => {
      state.themes = action.payload;

      const Topics = [];
      action.payload.forEach(({ topics }) => Topics.push(...topics));
      state.topics = Topics;

      const SubTopics = [];
      Topics.forEach(({ subTopics }) => SubTopics.push(...subTopics));
      state.subTopics = SubTopics;
    },
  },
});

export const { setThemes } = themeSlice.actions;

export const themesSelector = (state) => state.themeReducer.themes;

export const topicsSelector = (state) => state.themeReducer.topics;

export function fetchAllThemes() {
  return async (dispatch, getState) => {
    try {
      const existingThemes = themesSelector(getState());
      if (!existingThemes.length) {
        const themes = await getThemes();
        dispatch(setThemes(themes));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export default themeSlice.reducer;
