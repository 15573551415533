import React, { useState, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { Storage } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { InputAdornment, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import DateFnsUtils from '@date-io/date-fns';

import { PATH, IMAGE_FILE_SIZE_MAX } from '../../../constants';
import { Verified, EditIcon, LoadingCircle } from '../../utils/Icons';
import { updateUser } from '../../../redux/authSlice';
import { sendOTP, validateOTP } from '../../../services/userApiService';
import awsConfig from '../../../aws-exports';
import styles from './BasicInfo.module.css';
import RenderThumbnail from '../../../components/utils/RenderThumbnail';
import { getFullName } from '../../../utils';
import CommonButton from '../../common/CommonButton';

const BasicInfo = ({ editMode, setActiveOption, me }) => {
  const dispatch = useDispatch();
  const fileInput = useRef('fileInput');

  const [basicInfo, setBasicInfo] = useState({
    firstName: me.firstName,
    lastName: me.lastName || '',
    dob: me.dob || '',
    about: me.about || '',
    linkedInUrl: me.linkedInUrl || '',
    thumbnail: me.thumbnail,
  });

  const [otp, setOtp] = useState({
    isEmailNotVerified: me?.isEmailNotVerified,
    isPhoneNotVerified: true,
    emailVerificationSent: true,
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleImageUpload = () => {
    fileInput.current.click();

    fileInput.current.onchange = async ({ target }) => {
      try {
        const file = target.files[0];

        // if file selection is cancelled
        if (!file) return;

        if (file.size > IMAGE_FILE_SIZE_MAX) {
          setErrorMessage('Image file size should be less than 1 MB');
          return;
        }

        // upload file
        const res = await Storage.put(
          `user/${me.id}/image/${uuidv4()}.${file.type.split('/')[1]}`,
          file,
          {
            contentType: file.type,
          }
        );

        const { key } = res;
        if (key) {
          toast.info('Profile Picture Uploaded!', toastProps);

          // save uploaded file's key in db
          setBasicInfo({
            ...basicInfo,
            thumbnail: `https://${awsConfig.Storage.AWSS3.bucket}.s3.${awsConfig.Storage.AWSS3.region}.amazonaws.com/public/${key}`,
          });

          setErrorMessage(null);
        } else throw res;
      } catch (e) {
        console.error(e);
        setErrorMessage(null);
        toast.error('Image uploading failed,\n please try again later!', toastProps);
      }
    };
  };

  const handleInput = ({ target }) => setBasicInfo({ ...basicInfo, [target.name]: target.value });

  const sendEmailOTP = async () => {
    try {
      const res = await sendOTP('email', me.email);
      if (res.success) {
        setOtp({ ...otp, emailVerificationSent: true });
        toast.info('OTP sent! \n Please check your inbox', toastProps);
      } else {
        console.error(res.message);
        toast.error(res.message, toastProps);
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to send OTP, \n Please check your email!', toastProps);
    }
  };

  const sendPhoneOTP = async () => {
    try {
      const res = await sendOTP('phone', me.phone);
      if (res.success) {
        setOtp({ ...otp, phoneVerificationSent: true });
        toast.info('OTP sent! \n Please check your phone', toastProps);
      } else {
        console.error(res.message);
        toast.error(res.message, toastProps);
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to send OTP, \n Please check your phone number!', toastProps);
    }
  };

  const verifyOTP = async (resource, otpValue) => {
    try {
      const res = await validateOTP(resource, Number(otpValue));
      if (res && res.success) {
        toast.info(resource + ' updated successfully!', toastProps);

        // hide otp input again & show verified
        if (resource === 'email')
          setOtp({
            ...otp,
            isEmailNotVerified: false,
            emailVerificationSent: false,
          });
        if (resource === 'phone')
          setOtp({
            ...otp,
            isPhoneNotVerified: false,
            phoneVerificationSent: false,
          });
        return true;
      } else {
        toast.error(res.message, toastProps);
        return false;
      }
    } catch (err) {
      console.error(err);
      toast.error('Failed to verify OTP!', toastProps);
    }
  };

  const handleOTPinput = async ({ target }) => {
    let otpValue = otp[target.name] || '';
    otpValue += target.value;

    if (target.nextElementSibling.nodeName === 'INPUT') {
      setOtp({ ...otp, [target.name]: otpValue });
      target.nextElementSibling.focus();
    } else {
      target.blur();

      const res = await verifyOTP(target.name, otpValue);

      // clear otp input if otp is incorrect
      if (!res) {
        let currTarget = target;
        currTarget.value = '';
        while (currTarget.previousElementSibling) {
          currTarget = currTarget.previousElementSibling;
          currTarget.value = '';
        }
        currTarget.focus();
        setOtp({ ...otp, [target.name]: '' });
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    const obj = {
      profile: {
        ...basicInfo,
      },
    };

    try {
      await dispatch(updateUser(obj));
      toast.info('Profile Updated!', toastProps);
    } catch (err) {
      console.error(err);
      toast.error('Failed To Update Profile! \n Please Try Again Later!!', toastProps);
    }

    setLoading(false);

    if (!editMode) setActiveOption('B');
  };

  if (!basicInfo)
    return (
      <div className='h-full flex'>
        <LoadingCircle iconStyle='w-16 text-blue-500 m-auto' />
      </div>
    );

  return (
    <div className='w-full max-w-3xl my-7'>
      {/* header & image input */}
      <div className='flex items-center md:gap-8 mb-8 md:mb-12'>
        <div className='relative'>
          <Thumbnail
            firstName={basicInfo.firstName}
            thumbnail={basicInfo.thumbnail}
            onEditButtonClicked={handleImageUpload}
          />
        </div>
        <div>
          <div className='flex items-center mt-2 ml-5 md:ml-0'>
            <span className='text-xl font-semibold'>{getFullName(basicInfo)}</span>
            <Verified iconStyle='w-6 ml-2 inline text-blue-500' />
          </div>

          {/* <div className="text-gray-400 text-sm mt-2 mb-4">Your User ID #{me.id}</div> */}

          {/* <Link to={PATH.VIEW_PROFILE} className="border border-solid border-gray-300 rounded text-gray-500 text-sm py-2 px-6">
							View Profile
						</Link> */}
        </div>
      </div>
      <input type='file' ref={fileInput} hidden accept='.jpg,.jpeg,.png' />
      {errorMessage && <div className='text-red-500 mb-8 -mt-8'>{errorMessage}</div>}

      {/* Form */}
      <form className='flex flex-col gap-4 md:gap-8' onSubmit={handleSubmit}>
        <div className='flex flex-col md:grid grid-cols-2 gap-4 md:gap-8'>
          <TextField
            label='First Name'
            name='firstName'
            variant='outlined'
            type='text'
            onChange={handleInput}
            value={basicInfo.firstName}
            fullWidth
            required
          />

          <TextField
            label='Last Name'
            name='lastName'
            variant='outlined'
            type='text'
            onChange={handleInput}
            value={basicInfo.lastName}
            fullWidth
            required
          />

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              required
              label='Date of Birth'
              name='dob'
              value={basicInfo.dob || null}
              onChange={(e) => handleInput({ target: { name: 'dob', value: e } })}
              format='MM/dd/yyyy'
              inputVariant='outlined'
            />
          </MuiPickersUtilsProvider>

          <span />

          <TextField
            label='Email ID'
            variant='outlined'
            type='email'
            name='email'
            value={me.email}
            InputProps={otp.isEmailNotVerified ? {} : TickIcon}
            disabled
            fullWidth
          />

          {otp.isEmailNotVerified ? (
            <span className='flex items-center'>
              {otp.emailVerificationSent ? (
                <span>
                  <span className='text-gray-400 text-sm'>
                    Verification code sent on your email id
                  </span>
                  <span className='flex gap-2'>
                    <input
                      name='email'
                      min='0'
                      max='9'
                      onChange={handleOTPinput}
                      type='number'
                      className={OTPinputStyle}
                      autoFocus
                    />
                    <input
                      name='email'
                      min='0'
                      max='9'
                      onChange={handleOTPinput}
                      type='number'
                      className={OTPinputStyle}
                    />
                    <input
                      name='email'
                      min='0'
                      max='9'
                      onChange={handleOTPinput}
                      type='number'
                      className={OTPinputStyle}
                    />
                    <input
                      name='email'
                      min='0'
                      max='9'
                      onChange={handleOTPinput}
                      type='number'
                      className={OTPinputStyle}
                    />
                    <button
                      onClick={sendEmailOTP}
                      type='button'
                      className='text-blue-500 text-sm font-medium ml-4 mt-auto focus:outline-none'
                    >
                      Send again
                    </button>
                  </span>
                </span>
              ) : (
                <span onClick={sendEmailOTP} className='text-blue-500 cursor-pointer'>
                  Verify Email
                </span>
              )}
            </span>
          ) : (
            <span className='w-full my-auto text-sm font-normal text-ab-primary dark:text-ab-tertiary text-opacity-30 dark:text-opacity-60'>
              Your email id is verified
            </span>
          )}

          <TextField
            label='Mobile Number'
            variant='outlined'
            type='text'
            name='phone'
            value={me.phone}
            fullWidth
            disabled={true}
            required
          />
          <div className='none' hidden={true}>
            {otp.isPhoneNotVerified ? (
              <span className='flex items-center'>
                {otp.phoneVerificationSent ? (
                  <span>
                    <span className='text-gray-400 text-sm'>
                      Verification code sent on your phone
                    </span>
                    <span className='flex gap-2'>
                      <input
                        name='phone'
                        onChange={handleOTPinput}
                        type='number'
                        className={OTPinputStyle}
                        autoFocus
                      />
                      <input
                        name='phone'
                        onChange={handleOTPinput}
                        type='number'
                        className={OTPinputStyle}
                      />
                      <input
                        name='phone'
                        onChange={handleOTPinput}
                        type='number'
                        className={OTPinputStyle}
                      />
                      <input
                        name='phone'
                        onChange={handleOTPinput}
                        type='number'
                        className={OTPinputStyle}
                      />
                      <button
                        onClick={sendPhoneOTP}
                        type='button'
                        className='text-blue-500 text-sm font-medium ml-4 mt-auto focus:outline-none'
                      >
                        Send again
                      </button>
                    </span>
                  </span>
                ) : (
                  <span onClick={sendPhoneOTP} className='text-blue-500 cursor-pointer'>
                    Verify Phone
                  </span>
                )}
              </span>
            ) : (
              <span className='w-full my-auto text-sm text-gray-400'>
                Your mobile number is verified
              </span>
            )}
          </div>
        </div>

        <TextField
          label='LinkedIN URL'
          name='linkedInUrl'
          variant='outlined'
          type='url'
          onChange={handleInput}
          value={basicInfo.linkedInUrl}
          fullWidth
        />

        <TextField
          multiline
          rows={4}
          rowsMax={6}
          label='About Yourself'
          name='about'
          variant='outlined'
          type='text'
          onChange={handleInput}
          value={basicInfo.about}
          fullWidth
        />

        <div className='grid grid-cols-1 md:grid-cols-2 gap-8 mt-4 mb-6 md:mx-0 md:mt-0'>
          <CommonButton
            disabled={loading}
            type='submit'
            className='bg-green-c1 dark:bg-ab-secondary text-ab-tertiary font-medium rounded focus:outline-none py-4 px-5'
          >
            {loading && <LoadingCircle iconStyle='h-6 mr-2' />}
            {editMode ? 'UPDATE PROFILE' : 'NEXT'}
          </CommonButton>

          {!editMode && (
            <Link to={PATH.HOME} className='text-gray-400 m-auto'>
              Skip for now
            </Link>
          )}
        </div>
      </form>

      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

const TickIcon = {
  endAdornment: (
    <InputAdornment>
      <CheckCircleIcon style={{ color: '#76CE65' }} />
    </InputAdornment>
  ),
};

function Thumbnail({ thumbnail, firstName, onEditButtonClicked }) {
  return (
    <>
      <RenderThumbnail thumbnail={thumbnail} name={firstName} size='5' />
      <span
        onClick={onEditButtonClicked}
        className='absolute bottom-1 right-1 bg-blue-500 px-2 py-0.5 rounded-full cursor-pointer'
      >
        <EditIcon iconStyle='w-3 inline text-white' />
      </span>
    </>
  );
}

const OTPinputStyle = `appearance-none p-0.5 w-10 text-center text-white bg-ab-primary font-bold border border-gray-300 rounded focus:outline-none focus:ring-1 focus:border-white focus:ring-white hover:ring-1 hover:border-white hover:ring-white ${styles.disabledArrows}`;

const toastProps = {
  position: 'bottom-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
};

export default BasicInfo;
