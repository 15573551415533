import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import { Provider } from 'react-redux';

import App from './App';
import { store } from './redux/store';
import { MyErrorBoundary } from './ErrorBoundary';
import './css/index.css';

Amplify.configure(awsconfig);

ReactDOM.render(
  <Provider store={store}>
    <MyErrorBoundary>
      <App />
    </MyErrorBoundary>
  </Provider>,
  document.getElementById('root')
);
