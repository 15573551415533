import React, { useState, useEffect } from 'react';
import { getClassesForCoach } from '../../services/userApiService';
import { LoadingCircle } from '../utils/Icons';
import ActivityCard from './ActivityCard';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { COACH_ROLES, PATH } from '../../constants';
import _ from 'lodash';
import NavigationBar from '../header/NavigationBar';
import { NavLink } from 'react-router-dom';
import { Launch } from '@material-ui/icons';

import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const resourcesLink = {
  moderator: 'https://almabetter.notion.site/Moderatorship-ff10e1a4dfca42aab16e1862329fd26a',
  instructor: 'https://almabetter.notion.site/Instructorship-7df5b0958e0547c2b680c084766ac74e',
};

const tabs = ['Completed', 'Scheduled'];
const inactiveTabClass = 'text-black border-b-2 border-solid border-transparent';
const activeTabClass = 'text-ab-secondary border-b-2 border-solid border-ab-secondary';

const menuBarComponent = (coachRole) => (
  <div className='flex items-center w-11/12'>
    <NavLink
      className='text-black text-opacity-50 py-3 border-b-4 border-solid border-transparent rounded'
      activeClassName='font-medium border-ab-secondary text-ab-secondary text-opacity-100'
      exact
      to={PATH.CALENDAR_ACTIVITY(coachRole)}
    >
      My Sessions
    </NavLink>
    <a
      href={resourcesLink[coachRole]}
      target='_blank'
      rel='noreferrer'
      className='flex items-center gap-1 bg-blue-c3 bg-opacity-20 text-blue-c2 rounded px-2 py-1 ml-auto'
    >
      Resources
      <Launch />
    </a>
  </div>
);

function CalendarActivity({ location, ...props }) {
  const coachRole = String(location.pathname).split('/')[2] || 'instructor';

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const initialFilter = Object.freeze({
    cohort: '',
    activity: '',
    module: '',
    status: '',
    toDate: '',
    fromDate: '',
  });
  const [filter, setFilter] = useState(initialFilter);
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const { coachRoles } = useSelector((state) => state.authReducer);

  const cohortList = Array.from(new Set(data.map(({ cohortName }) => cohortName)));
  const activityList = Array.from(new Set(data.map(({ topic }) => topic)));
  const moduleList = Array.from(new Set(data.map(({ module }) => module)));
  const statusList = Array.from(new Set(data.map(({ sessionStatus }) => sessionStatus)));

  useEffect(() => {
    const fetchActivity = async () => {
      const response = await getClassesForCoach();
      if (response) {
        setData(response);
        setLoading(false);
      }
    };

    if (
      coachRoles &&
      !_.intersectionWith([COACH_ROLES.INSTRUCTOR, COACH_ROLES.MODERATOR], coachRoles, _.isEqual)
        .length
    )
      props.history.push(PATH.HOME);

    fetchActivity();
  }, [coachRoles]);

  useEffect(() => {
    let tempFilterData = [];
    tempFilterData = filter.cohort
      ? data.filter((item) => item.cohortName === filter.cohort)
      : data;
    tempFilterData = filter.activity
      ? tempFilterData.filter((item) => item.topic === filter.activity)
      : tempFilterData;
    tempFilterData = filter.module
      ? tempFilterData.filter((item) => item.module === filter.module)
      : tempFilterData;
    tempFilterData = filter.status
      ? tempFilterData.filter((item) => item.sessionStatus === filter.status)
      : tempFilterData;
    tempFilterData = filter.fromDate
      ? tempFilterData.filter((item) => dayjs(item.startTime).isSameOrAfter(filter.fromDate, 'day'))
      : tempFilterData;
    tempFilterData = filter.toDate
      ? tempFilterData.filter((item) => dayjs(item.startTime).isSameOrBefore(filter.toDate, 'day'))
      : tempFilterData;

    if (currentTab === 'Completed') {
      tempFilterData = tempFilterData.filter((item) => item.sessionStatus === 'DONE');
      tempFilterData.sort((a, b) => {
        if (dayjs(a?.startTime).isBefore(b?.startTime)) return 1;
        if (dayjs(a?.startTime).isAfter(b?.startTime)) return -1;
        return 0;
      });
    }

    if (currentTab === 'Scheduled') {
      tempFilterData = tempFilterData.filter((item) => item.sessionStatus === 'UPCOMING');
      tempFilterData.sort((a, b) => {
        if (dayjs(a?.startTime).isBefore(b?.startTime)) return -1;
        if (dayjs(a?.startTime).isAfter(b?.startTime)) return 1;
        return 0;
      });
    }

    setFilterData(tempFilterData);
  }, [filter, data, currentTab]);

  return (
    <>
      <NavigationBar menubar={menuBarComponent(coachRole)} />
      <div className='bg-gray-c4 w-full px-20 py-4 flex items-center gap-10'>
        {tabs?.map((singleTab) => {
          return (
            <div
              key={singleTab}
              className={`${
                currentTab === singleTab ? activeTabClass : inactiveTabClass
              } cursor-pointer text-lg`}
              onClick={() => {
                setCurrentTab(singleTab);
              }}
            >
              {singleTab}
            </div>
          );
        })}
      </div>
      <div className='bg-gray-c4 w-full p-4 md:p-10 min-h-screen flex gap-10 flex-col md:flex-row-reverse items-start'>
        {/* --- Filters --- */}
        <div
          className='bg-ab-tertiary shadow-lg rounded-lg overflow-hidden'
          style={{ width: '24rem', minWidth: '300px' }}
        >
          <div className='bg-blue-c5 w-full p-3'>
            <span className='font-medium'>FILTERS</span>
          </div>
          <div className='text-black w-full m-auto flex flex-col p-3 gap-4'>
            <div className='flex flex-col gap-2'>
              <span className='font-medium'>Cohort</span>
              <FormControl variant='outlined' className='bg-white' size='small'>
                <Select
                  labelId='cohort-filter-label'
                  id='cohort-filter'
                  onChange={(e) => setFilter({ ...filter, cohort: e.target.value })}
                >
                  <MenuItem value=''>All</MenuItem>
                  {cohortList.map((item, key) => (
                    <MenuItem key={key} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='flex flex-col gap-2'>
              <span className='font-medium'>Module</span>
              <FormControl variant='outlined' className='bg-white' size='small'>
                <Select
                  labelId='module-filter-label'
                  id='module-filter'
                  onChange={(e) => setFilter({ ...filter, module: e.target.value })}
                >
                  <MenuItem value=''>All</MenuItem>
                  {moduleList.map((item, key) => (
                    <MenuItem key={key} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='flex flex-col gap-2'>
              <span className='font-medium'>Topic</span>
              <FormControl variant='outlined' className='bg-white' size='small'>
                <Select
                  labelId='class-topic-label'
                  id='class-topic'
                  onChange={(e) => setFilter({ ...filter, activity: e.target.value })}
                >
                  <MenuItem value=''>All</MenuItem>
                  {activityList.map((item, key) => (
                    <MenuItem key={key} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='flex flex-col gap-2'>
              <span className='font-medium'>Status</span>
              <FormControl variant='outlined' className='bg-white' size='small'>
                <Select
                  labelId='activity-status-label'
                  id='activity-status'
                  onChange={(e) => setFilter({ ...filter, status: e.target.value })}
                >
                  <MenuItem value=''>All</MenuItem>
                  {statusList.map((item, key) => (
                    <MenuItem key={key} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='flex flex-col gap-4'>
              <span className='font-medium'>Specific Date Range</span>
              <div className='flex flex-col gap-4'>
                <TextField
                  className='bg-white'
                  id='fromDateFilter'
                  label='From'
                  type='date'
                  variant='outlined'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    e.target.value
                      ? setFilter({
                          ...filter,
                          fromDate: dayjs(e.target.value),
                        })
                      : setFilter({ ...filter, fromDate: e.target.value })
                  }
                  size='small'
                />
                <TextField
                  className='bg-white'
                  id='toDateFilter'
                  label='To'
                  type='date'
                  variant='outlined'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    e.target.value
                      ? setFilter({ ...filter, toDate: dayjs(e.target.value) })
                      : setFilter({ ...filter, toDate: e.target.value })
                  }
                  size='small'
                />
              </div>
            </div>
          </div>
        </div>

        {/* --- Activity Cards --- */}
        <div className='w-full md:w-4/5 2xl:w-3/5 grid grid-cols-1 gap-y-8'>
          {loading ? (
            <div className='text-center mt-20'>
              <LoadingCircle iconStyle='w-8 h-8 text-ab-secondary mb-2' />
              <h3 className='text-black text-xl'>Loading</h3>
            </div>
          ) : filterData ? (
            filterData.map((item, i) => <ActivityCard cardData={item} key={i} />)
          ) : (
            data.map((item, i) => <ActivityCard cardData={item} key={i} />)
          )}
        </div>
      </div>
    </>
  );
}

export default CalendarActivity;
