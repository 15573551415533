import React, { useState, useEffect } from 'react';

import { DeleteOutline, EditOutlined, CheckCircle } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import dayjs from 'dayjs';

import { DATE_ONLY_YEAR, PATH } from '../../../constants';
import { LoadingCircle } from '../../utils/Icons';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { addCompanyAction, addJobTitleAction } from '../../../redux/workExperienceSlice';
import { updateCoach } from '../../../redux/authSlice';

const filter = createFilterOptions();

const WorkExperience = ({
  editMode,
  setActiveOption,
  workExperience,
  unverifiedWorkExperience,
}) => {
  const [allWorkExperience, setAllWorkExperience] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [companyValue, setCompanyValue] = useState('');
  const [jobTitleValue, setJobTitleValue] = useState('');

  useEffect(() => setAllWorkExperience(unverifiedWorkExperience), [unverifiedWorkExperience]);

  const dispatch = useDispatch();
  const companies = useSelector(({ workExperienceReducer }) => workExperienceReducer.companies);
  const jobTitles = useSelector(({ workExperienceReducer }) => workExperienceReducer.jobTitles);

  const handleInput = (inputData) => setFormData({ ...formData, ...inputData });

  const updateDetails = async (details) => {
    setLoading(true);
    const workExperienceData = {
      workExperience: details,
    };
    try {
      await dispatch(updateCoach(workExperienceData));
      toast.info('Profile Updated!', {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } catch (err) {
      console.error(err);
      toast.error('Failed To Update Profile! \n Please Try Again Later!!', {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    setLoading(false);
  };

  const addDetails = async (e) => {
    e.preventDefault();

    const details = {
      ...formData,
      companyName: companyValue.companyName,
      companyId: companyValue.companyId,
      jobTitleName: jobTitleValue.jobTitleName,
      jobTitleId: jobTitleValue.jobTitleId,
    };
    setCompanyValue('');
    setJobTitleValue('');
    await updateDetails([...allWorkExperience, details]);
    setFormData({});
    if (!editMode) setActiveOption('D');
  };

  const removeDetails = (i) => {
    const details = [...allWorkExperience];
    details.splice(i, 1);
    updateDetails(details);
  };

  const editDetails = (i) => {
    const details = [...allWorkExperience];
    const [toEdit] = details.splice(i, 1);
    setAllWorkExperience(details);
    setFormData(toEdit);
    setCompanyValue({
      companyName: toEdit.companyName,
      companyId: toEdit.companyId,
    });
    setJobTitleValue({
      jobTitleName: toEdit.jobTitleName,
      jobTitleId: toEdit.jobTitleId,
    });
  };

  return (
    <div className='w-full max-w-4xl mt-6 md:mt-16 flex flex-col'>
      {/* Current Education Info */}
      <div className='flex flex-col md:flex-row gap-8 text-ab-primary dark:text-ab-tertiary mb-8'>
        <div className='md:w-1/4 opacity-30'>Work Experience</div>
        <div className='md:w-3/4'>
          {workExperience && (
            <div>
              {workExperience.map((item, index) => (
                <div className='mb-8 flex justify-between' key={index}>
                  <div>
                    <div className='font-semibold opacity-80'>
                      {item.companyName}
                      <CheckCircle style={{ color: '#76CE65', marginLeft: '0.5rem' }} />
                    </div>
                    <div className='text-sm opacity-60 my-1'>{item.jobTitleName}</div>
                    <div className='text-sm opacity-30'>
                      {dayjs(item.startDate).format(DATE_ONLY_YEAR)} -{' '}
                      {(item.endDate && dayjs(item.endDate).format(DATE_ONLY_YEAR)) || 'Present'}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div>
            {allWorkExperience ? (
              allWorkExperience.map((item, index) => (
                <div className='mb-8 flex justify-between' key={index}>
                  <div>
                    <div className='font-semibold opacity-80'>{item.companyName}</div>
                    <div className='text-sm opacity-60 my-1'>{item.jobTitleName}</div>
                    <div className='text-sm opacity-30'>
                      {dayjs(item.startDate).format(DATE_ONLY_YEAR)} -{' '}
                      {(item.endDate && dayjs(item.endDate).format(DATE_ONLY_YEAR)) || 'Present'}
                    </div>
                  </div>

                  <div className='flex gap-2'>
                    <div onClick={() => editDetails(index)} className='cursor-pointer opacity-30'>
                      <EditOutlined color='inherit' />
                    </div>
                    <div onClick={() => removeDetails(index)} className='cursor-pointer opacity-30'>
                      <DeleteOutline color='inherit' />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <LoadingCircle iconStyle='w-6 h-6 ml-2 my-5' />
            )}
          </div>
        </div>
      </div>

      {/* form */}
      <form onSubmit={addDetails} className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8'>
        <Autocomplete
          freeSolo
          fullWidth
          autoComplete
          id='company-combo-box'
          value={companyValue.companyName || ''}
          onChange={async (event, newValue) => {
            if (newValue) {
              if (newValue.inputValue) {
                // Create a new value from the user input
                const { id, name } = await dispatch(addCompanyAction(newValue.inputValue));
                setCompanyValue({ companyId: id, companyName: name });
              } else {
                setCompanyValue({
                  companyId: newValue.id,
                  companyName: newValue.name,
                });
              }
            } else setCompanyValue('');
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting =
              options.some((option) => inputValue === option.name) ||
              options.some((option) => option.name.includes(inputValue));

            const tagsCheck = options.some((option) => {
              if (option.tags) {
                const flag =
                  option.tags.some((tag) => inputValue === tag) ||
                  option.tags.some((tag) => tag.includes(inputValue));
                if (filtered.length === 0 && flag) filtered.push(option);
                return flag;
              } else return false;
            });

            if (
              inputValue.trim().length !== 0 &&
              !isExisting &&
              !tagsCheck &&
              filtered.length === 0
            ) {
              let newCompany = inputValue.trim();
              newCompany = newCompany.charAt(0).toUpperCase() + newCompany.substr(1);
              filtered.push({
                inputValue: newCompany,
                company: `Add "${newCompany}"`,
              });
            }

            return filtered;
          }}
          options={companies}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.company;
            }
            // Regular option
            return option.name;
          }}
          loading={companies.length === 0}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Name of Company'
              name='company'
              variant='outlined'
              type='text'
              value={formData.company || ''}
              required
              autoFocus
            />
          )}
        />
        <Autocomplete
          freeSolo
          fullWidth
          autoComplete
          id='position-combo-box'
          value={jobTitleValue.jobTitleName || ''}
          onChange={async (event, newValue) => {
            if (newValue) {
              if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                const { id, name } = await dispatch(addJobTitleAction(newValue.inputValue));
                setJobTitleValue({ jobTitleId: id, jobTitleName: name });
              } else {
                setJobTitleValue({
                  jobTitleId: newValue.id,
                  jobTitleName: newValue.name,
                });
              }
            } else setJobTitleValue('');
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting =
              options.some((option) => inputValue === option.name) ||
              options.some((option) => option.name.includes(inputValue));
            if (inputValue.trim().length !== 0 && !isExisting && filtered.length === 0) {
              let newJobTitle = inputValue.trim();
              newJobTitle = newJobTitle.charAt(0).toUpperCase() + newJobTitle.substr(1);
              filtered.push({
                inputValue: newJobTitle,
                jobTitle: `Add "${newJobTitle}"`,
              });
            }

            return filtered;
          }}
          options={jobTitles}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.jobTitle;
            }
            // Regular option
            return option.name;
          }}
          loading={jobTitles.length === 0}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Position'
              name='position'
              variant='outlined'
              type='text'
              value={formData.position || ''}
              required
            />
          )}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            required
            id='startYear'
            label='Start Year'
            name='startDate'
            placeholder=''
            emptyLabel=''
            format='yyyy'
            views={['year']}
            value={formData.startDate || null}
            onChange={(newValue) => handleInput({ startDate: newValue })}
            inputVariant='outlined'
          />
          <DatePicker
            id='endYear'
            label='End Year'
            name='endDate'
            placeholder=''
            emptyLabel=''
            format='yyyy'
            views={['year']}
            value={formData.endDate || null}
            onChange={(newValue) => handleInput({ endDate: newValue })}
            minDate={formData.startDate}
            helperText='Leave empty if currently working'
            inputVariant='outlined'
            clearable
          />
        </MuiPickersUtilsProvider>

        {/* submit button */}
        <button
          disabled={loading}
          type='submit'
          className='bg-green-c1 dark:bg-ab-secondary text-white font-medium rounded py-4 mt-4'
        >
          {loading && <LoadingCircle iconStyle='w-6 mr-2' />}
          {editMode ? 'UPDATE EXPERIENCE' : 'NEXT'}
        </button>

        {!editMode && (
          <Link to={PATH.HOME} className='text-gray-400 m-auto mt-8'>
            Skip for now
          </Link>
        )}
      </form>

      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

export default WorkExperience;
