import { createSlice } from '@reduxjs/toolkit';
import { COLOR_THEME, THEME_PALETTE } from '../constants';

const initialState = {
  colorTheme: {
    palette: THEME_PALETTE.LIGHT,
  },
};

const colorModeSlice = createSlice({
  name: 'colorModeReducer',
  initialState,
  reducers: {
    setColorTheme: (state, action) => {
      state.colorTheme.palette =
        action.payload === COLOR_THEME.DARK ? THEME_PALETTE.DARK : THEME_PALETTE.LIGHT;
    },
  },
});

export const { setColorTheme } = colorModeSlice.actions;

export default colorModeSlice.reducer;
