export const PATH = {
  HOME: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FILL_PROFILE: '/fill-profile',
  EDIT_PROFILE: '/edit-profile',
  VIEW_PROFILE: '/view-profile',
  OPEN_BOOKINGS: '/open-bookings',
  MANAGE_BOOKINGS: '/manage-bookings',
  MY_STATS: '/my-stats',
  MY_WALLET: '/my-wallet',
  VERIFY_EMAIL: '/verify-email',
  RESET_PASSWORD: '/reset-password',
  REGISTER_MOBILE: '/register-mobile',
  PREVIEW_SESSION: '/preview-session',
  CONDUCT_SESSION: '/conduct-session',
  RECORD_SESSION: '/record-session',
  DETAILED_REPORT: '/detailed-report',
  WATCH_RECORDING: '/watch-recording',
  THINKIFIC_LOGIN_SUCCESSFUL: '/thinkific_login_successful/',
  CALENDAR_ACTIVITY: (role) => `/calendar-activity/${role}`,
  MENTORSHIP: {
    HOME: '/polaris',
    CONDUCT_SESSION: '/polaris/manage-bookings/conduct-session',
    RECORD_SESSION: '/polaris/record-session',
    DETAILED_REPORT: '/polaris/manage-bookings/detailed-report',
    WATCH_RECORDING: '/polaris/manage-bookings/watch-recording',
    PREVIEW_SESSION: '/polaris/preview-session',
    OPEN_BOOKINGS: '/polaris/open-bookings',
    MANAGE_BOOKINGS: '/polaris/manage-bookings',
    POST_INTERVIEW: '/polaris/post-interview',
    LEGACY: '/polaris/legacy',
  },
  AUTH: {
    HOME: '/auth',
    get LOGIN() {
      return `${this.HOME}/login`;
    },
    get SIGN_UP() {
      return `${this.HOME}/signup`;
    },
    get FORGOT_YOUR_PASSWORD() {
      return `${this.HOME}/forgot_your_password`;
    },
    get LOGIN_SUCCESSFUL() {
      return `${this.HOME}/login_successful`;
    },
    get LOGOUT() {
      return `${this.HOME}/logout`;
    },
    get LOGOUT_SUCCESSFUL() {
      return `${this.HOME}/logout_successful`;
    },
    get EMAIL_VERIFICATION() {
      return `${this.HOME}/verify_email`;
    },
    get ONBOARDING() {
      return `${this.HOME}/onboarding`;
    },
  },
  DASHBOARD: '/my-dashboard',
  PROJECT_EVALUATIONS_DASHBOARD: '/project-evaluation-dashboard',
  ALMAX_ASSIGNMENT_EVALUATIONS_DASHBOARD: '/almax-assignment-evaluation-dashboard',

  INTERVIEW_ROSTER: '/interview-roster',
};

export const IMAGE_FILE_SIZE_MAX = 1449616;

export const SESSION_TYPE = {
  PREVIEW: 'preview_session',
  CONDUCT: 'conduct_session',
};

export const getDomain = () => {
  const domain = `${window.location.protocol}//${window.location.host}`;
  return domain;
};

const DOMAIN = getDomain() || 'http://localhost:3000';

export const THINKIFIC_CONSTANTS = {
  DEFAULT_REDIRECT_URL_AFTER_LOGIN: 'https://www.almabetter.com/enrollments',
  SSO_LOGIN: {
    URL: 'https://almabetter.thinkific.com/api/sso/v2/sso/jwt',
    REDIRECT_URL: `${DOMAIN}/thinkific_login_successful`,
    ERROR_URL: `${DOMAIN}/thinkific_login_error`,
  },
  SSO_LOGOUT_URL: 'https://www.almabetter.com/users/sign_out',
  LINK_TO_DASHBOARD: 'https://www.almabetter.com/enrollments',
};

export const LOCAL_STORAGE_KEYS = {
  REDIRECT_TO: 'REDIRECT_TO',
  LOGIN_PROCESS_STAGE: 'LOGIN_PROCESS_STAGE',
  LOGOUT_PROCESS_STAGE: 'LOGOUT_PROCESS_STAGE',
};

export const LOGIN_LOGOUT_PROCESS_STAGE_VALUES = {
  COGNITO_AUTH_SUCCESSFUL: 'COGNITO_AUTH_SUCCESSFUL',
  LOGOUT_STARTED: 'LOGOUT_STARTED',
};
export const BOOKING_STAGE = {
  TIME_SLOTS_ADDED: 'timeSlotsAdded',
  COACH_ASSIGNED: 'coachAssigned',
  SESSION_CONDUCTED: 'sessionConducted',
  FEEDBACK_SUBMITTED: 'feedbackSubmitted',
  ADMIN_ON_HOLD: 'adminOnHold',
  ADMIN_APPROVED: 'adminApproved',
  CANCELLED: 'cancelled',
};

export const BOOKING_STATUS = {
  ACTIVE: 'active',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
  DEFAULTED: 'defaulted',
};

export const ALMABETTER_HOME_LINKS = {
  ROOT: 'https://www.almabetter.com',
  get TERM_AND_CONDITIONS() {
    return `${this.ROOT}/terms-of-use`;
  },
  get PRIVACY() {
    return `${this.ROOT}/privacy-policy`;
  },
  get ABOUT_US() {
    return `${this.ROOT}/about-us`;
  },
};

export const THINKIFIC_ALMABETTER_LINKS = {
  ROOT: 'https://learn.almabetter.com',
  get PRO_LIVE_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/pro-live-onboarding/multimedia/27676716-almax-pre-registration-form`;
  },
  get PRO_FLEX_ALMAX_PRE_REG_FORM() {
    return `${this.ROOT}/courses/take/pro-flex-onboarding/multimedia/27488097-almax-pre-registration-form`;
  },
  get ENROLLMENTS_PAGE() {
    return `${this.ROOT}/enrollments`;
  },
  get SSO_LOGOUT_URL() {
    return `${this.ROOT}/users/sign_out`;
  },
};

export const COLOR_THEME = {
  LIGHT: 'light',
  DARK: 'dark',
};

export const THEME_PALETTE = {
  LIGHT: {
    type: 'light',
    primary: {
      main: '#148EFF',
    },
    secondary: {
      main: '#134F5C',
    },
  },
  DARK: {
    type: 'dark',
    primary: {
      main: '#148EFF',
    },
    secondary: {
      main: '#134F5C',
    },
  },
};

export const COURSE_TYPE = {
  DATA_SCIENCE: 'data-science',
  WEB3: 'web3',
  UG_DIPLOMA_CS: 'ug-diploma-cs',
};

export const GOAL_TYPE = {
  DATA_SCIENCE: 'dataScience',
  WEB3: 'web3',
  UG_DIPLOMA_CS: 'ugDiplomaCS',
};

export const GOAL_TYPE_NAMES_MAPPING = {
  [GOAL_TYPE.DATA_SCIENCE]: 'Full Stack Data Science',
  [GOAL_TYPE.WEB3]: 'Full Stack Web Development with Web3',
  [GOAL_TYPE.UG_DIPLOMA_CS]: 'UG Diploma in Computer Science',
};

export const COURSE_TYPE_NAMES_MAPPING = {
  [COURSE_TYPE.DATA_SCIENCE]: 'Full Stack Data Science',
  [COURSE_TYPE.WEB3]: 'Full Stack Web Development with Web3',
  [COURSE_TYPE.UG_DIPLOMA_CS]: 'UG Diploma in Computer Science',
};

export const COMMUNITY = 'https://community.almabetter.com/';

export const DOUBT_RESOLUTION = 'https://community.almabetter.com/groups/subjectmatterexperts';

export const DATE_FORMAT_1 = 'Do MMM YYYY, hh:mm A';
export const DATE_FORMAT_2 = 'DD/MM/YYYY, HH:mm A';
export const DATE_ONLY_FORMAT_1 = 'Do MMM YYYY';
export const DATE_ONLY_FORMAT_2 = 'dddd, DD MMM YYYY';
export const DATE_ONLY_FORMAT_3 = 'DD/MM/YYYY';
export const DATE_ONLY_YEAR = 'YYYY';
export const TIME_FORMAT_1 = 'hh:mm A';

export const COACH_ROLES = {
  MENTOR: 'mentor',
  SUBJECT_MATTER_EXPERT: 'subject_matter_expert',
  INSTRUCTOR: 'instructor',
  EVALUATOR: 'evaluator',
  CAREER_COACH: 'career_coach',
  MODERATOR: 'moderator',
  PROJECT_EVALUATOR: 'project_evaluator',
};

export const MENTORSHIP_RESOURCE_LINK =
  'https://almabetter.notion.site/Mentorship-Dashboard-Walkthrough-6874fdd70a374f2096f3cfcc536f2156';

export const GROW_PLATFORM_LINK = 'https://grow.almabetter.com';
