import { createSlice } from '@reduxjs/toolkit';
import { getExpertiseGigsStatus } from '../services/userApiService';

const initialState = {
  domainKnowledge: [],
  gigs: [],
  validationStatus: null,
};

const expertiseSlice = createSlice({
  name: 'expertiseReducer',
  initialState,
  reducers: {
    setDomainKnowledge: (state, action) => {
      state.domainKnowledge = action.payload;
    },
    setGigs: (state, action) => {
      state.gigs = action.payload;
    },
    setValidationStatus: (state, action) => {
      state.validationStatus = action.payload;
    },
  },
});

export const { setDomainKnowledge, setGigs, setValidationStatus } = expertiseSlice.actions;

export function fetchDomainKnowledge(userId) {
  return async (dispatch) => {
    try {
      const userExpertise = await getExpertiseGigsStatus(userId);
      dispatch(setDomainKnowledge(userExpertise?.domainKnowledge));
      dispatch(setGigs(userExpertise?.gigs));
      dispatch(setValidationStatus(userExpertise?.validationStatus));
    } catch (error) {
      console.error(error);
    }
  };
}

export const validationStatusSelector = (state) => state.expertiseReducer.validationStatus;

export default expertiseSlice.reducer;
