import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LockOutlined, ReportProblem } from '@material-ui/icons';
import { Checkbox, CircularProgress, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

import BookingDetailsCardView from './components/BookingDetailsCardView';
import { BOOKING_STAGE, BOOKING_STATUS, PATH, SESSION_TYPE } from '../../constants';
import ProblemReportingPopup from './components/ProblemReportingPopup';
import SideBar from './SideBar';
import SessionStatusBar from './components/SessionStatusBar';
import desertSvg from '../../static/svg/desert.svg';
import { acceptBooking, cancelBooking, getMyBookings } from '../../services/bookingApiService';
import RequestNewSlotsConfirmation from './components/RequestNewSlotsConfirmation';
dayjs.extend(isBetween);

const SORT_OPTION_KEYWORDS = {
  HIGH_TO_LOW: 'high-to-low',
  LOW_TO_HIGH: 'low-to-high',
  NEAR_DATES_FIRST: 'near-dates',
  FAR_DATES_FIRST: 'far-dates',
};

const PROBLEM_MESSAGES = [
  'Student not responding',
  'I am not able to take this session',
  'Spam booking',
  'Facing technical issue in joining session',
];

const PAGE_SIZE = 25;

const ManageBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState(null);
  const [reportedBookingId, setReportedBookingId] = useState(null);
  const [cancelledBooking, setCancelledBooking] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchingBookings, setFetchingBookings] = useState(false);
  const [fetchingMoreBookings, setFetchingMoreBookings] = useState(false);
  const [fetchedAllBookings, setFetchedAllBookings] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    stage: [BOOKING_STAGE.COACH_ASSIGNED],
    status: [],
    theme: [],
    fromDate: null,
    toDate: null,
  });
  const [sortOptions, setSortOptions] = useState({
    sortBy: SORT_OPTION_KEYWORDS.NEAR_DATES_FIRST,
  });
  const [bookingCount, setBookingCount] = useState({});
  const [showRequestNewSlotsModal, setShowRequestNewSlotsModal] = useState(false);

  const scrollContainerRef = useRef();

  const { loggedInUser } = useSelector(({ authReducer }) => authReducer);
  const themes = useSelector(({ themeReducer }) => themeReducer.themes);

  const fetchBookings = async (pageNo) => {
    const selectedStages = filterOptions.stage;
    if (selectedStages.includes(BOOKING_STAGE.SESSION_CONDUCTED))
      selectedStages.push(BOOKING_STAGE.FEEDBACK_SUBMITTED, BOOKING_STAGE.ADMIN_ON_HOLD);
    else
      _.remove(selectedStages, (item) =>
        [BOOKING_STAGE.FEEDBACK_SUBMITTED, BOOKING_STAGE.ADMIN_ON_HOLD].includes(item)
      );
    return await getMyBookings(
      PAGE_SIZE,
      pageNo,
      filterOptions.stage.length ? filterOptions.stage : undefined,
      filterOptions.status.length ? filterOptions.status : undefined,
      filterOptions.theme.length ? filterOptions.theme : undefined,
      filterOptions.fromDate || undefined,
      filterOptions.toDate || undefined
    );
  };

  const loadBookings = async () => {
    setFetchingBookings(true);
    try {
      const { items, counts } = await fetchBookings(0);
      setBookings(items);
      setBookingCount(counts);
    } catch (err) {
      console.error(err);
    }
    setFetchingBookings(false);
    setFetchedAllBookings(false);
    setPageNo(0);
  };

  useEffect(() => {
    loadBookings();
  }, [filterOptions]);

  const loadMoreBookings = async () => {
    setFetchingMoreBookings(true);
    try {
      const { items } = await fetchBookings(pageNo);
      setBookings([...bookings, ...items]);
      setFetchedAllBookings(items.length < PAGE_SIZE);
    } catch (err) {
      console.error(err);
    }
    setFetchingMoreBookings(false);
  };

  useEffect(() => {
    if (pageNo) loadMoreBookings();
  }, [pageNo]);

  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting && !fetchingBookings && !fetchingMoreBookings && !fetchedAllBookings) {
      setPageNo(pageNo + 1);
    }
  });

  useEffect(() => {
    observer.observe(scrollContainerRef.current.lastElementChild);
    return () => observer.disconnect();
  });

  const handleCanceldBooking = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await cancelBooking(cancelledBooking);
      await loadBookings();
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const sortedBookings = [...bookings];
    switch (sortOptions.sortBy) {
      case SORT_OPTION_KEYWORDS.HIGH_TO_LOW:
        sortedBookings.sort((a, b) => b.totalEarning - a.totalEarning);
        break;
      case SORT_OPTION_KEYWORDS.LOW_TO_HIGH:
        sortedBookings.sort((a, b) => a.totalEarning - b.totalEarning);
        break;
      case SORT_OPTION_KEYWORDS.NEAR_DATES_FIRST:
        sortedBookings.sort((a, b) => new Date(b.startTime) - new Date(a.startTime));
        break;
      case SORT_OPTION_KEYWORDS.FAR_DATES_FIRST:
        sortedBookings.sort((a, b) => new Date(a.startTime) - new Date(b.startTime));
        break;
      default:
        break;
    }

    setFilteredBookings(sortedBookings);
  }, [sortOptions, bookings]);

  if (!loggedInUser)
    return (
      <div className='h-full w-full flex flex-col gap-8 items-center justify-center'>
        <svg
          width='60'
          height='60'
          viewBox='0 0 60 60'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0)'>
            <path
              d='M1.75796 49.3358H10.547V54.6093C10.547 55.5801 11.334 56.3671 12.3048 56.3671H58.2422C59.213 56.3671 60 55.5801 60 54.6093V8.90624C60 7.93546 59.213 7.14843 58.2422 7.14843H49.4532V5.39062C49.4532 4.41984 48.6661 3.63281 47.6954 3.63281C46.7246 3.63281 45.9376 4.41984 45.9376 5.39062V7.14843H37.0313V5.39062C37.0313 4.41984 36.2443 3.63281 35.2735 3.63281C34.3027 3.63281 33.5157 4.41984 33.5157 5.39062V7.14843H24.7267V5.39062C24.7267 4.41984 23.9396 3.63281 22.9688 3.63281C21.9981 3.63281 21.211 4.41984 21.211 5.39062V7.14843H12.3048C11.334 7.14843 10.547 7.93546 10.547 8.90624V19.4531C10.547 32.4081 5.16283 42.3745 0.632606 46.2277C0.0640136 46.7015 -0.146806 47.4805 0.105029 48.1765C0.357099 48.8724 1.0178 49.3358 1.75796 49.3358ZM56.4844 52.8515H14.0626V49.3358H47.6954C48.1066 49.3358 48.5048 49.1917 48.8207 48.9284C50.9338 47.1684 54.207 42.978 56.4844 37.611V52.8515ZM14.0626 10.664H21.211V12.4219C21.211 13.3926 21.9981 14.1797 22.9688 14.1797C23.9396 14.1797 24.7267 13.3926 24.7267 12.4219V10.664H33.5157V12.4219C33.5157 13.3926 34.3027 14.1797 35.2735 14.1797C36.2443 14.1797 37.0313 13.3926 37.0313 12.4219V10.664H45.9376V12.4219C45.9376 13.3926 46.7246 14.1797 47.6954 14.1797C48.6661 14.1797 49.4532 13.3926 49.4532 12.4219V10.664H56.4844V17.6953H14.0626V10.664ZM14.0325 21.2097H56.4532C56.0571 32.3607 51.7773 41.1343 47.0061 45.8202H5.75627C11.1625 38.9318 13.7425 29.9627 14.0325 21.2097Z'
              fill='black'
              fillOpacity='0.6'
            />
          </g>
          <defs>
            <clipPath id='clip0'>
              <rect width='60' height='60' fill='white' />
            </clipPath>
          </defs>
        </svg>

        <div className='text-3xl font-semibold'>Please Log in To View Bookings</div>
      </div>
    );

  return (
    <div className='flex gap-4 p-4 bg-gray-c1 dark:bg-ab-primary font-medium min-h-full'>
      <div ref={scrollContainerRef} className='flex-grow flex flex-col gap-6'>
        {!fetchingBookings ? (
          filteredBookings?.length ? (
            <>
              {filteredBookings.map((booking, index) => (
                <ManageBookingCard
                  key={index}
                  booking={booking}
                  cancelledBooking={cancelledBooking}
                  handleCanceldBooking={handleCanceldBooking}
                  setReportedBookingId={setReportedBookingId}
                  setCancelledBooking={setCancelledBooking}
                  loading={loading}
                  setLoading={setLoading}
                  setShowRequestNewSlotsModal={setShowRequestNewSlotsModal}
                />
              ))}
              {fetchingMoreBookings && (
                <div className='my-8 flex justify-center text-blue-c1 dark:text-ab-tertiary'>
                  <CircularProgress color='inherit' />
                </div>
              )}
            </>
          ) : (
            <div className='m-auto text-center'>
              <img src={desertSvg} className='w-32 inline' alt='' />
              <h1 className='font-medium text-2xl mt-4 mb-8'>No Bookings To Manage</h1>
              <Link
                to={PATH.MENTORSHIP.OPEN_BOOKINGS}
                className='bg-green-c1 dark:bg-ab-secondary text-lg text-white rounded px-8 py-2'
              >
                Accept Bookings
              </Link>
            </div>
          )
        ) : (
          <div className='h-full flex items-center justify-center text-blue-c1 dark:text-ab-tertiary'>
            <CircularProgress size='4rem' color='inherit' />
          </div>
        )}
      </div>

      <SideBar
        themes={themes}
        filterOptions={filterOptions}
        setFilterOptions={setFilterOptions}
        sortOptions={sortOptions}
        setSortOptions={setSortOptions}
        bookingCount={bookingCount}
      />

      {reportedBookingId && (
        <ProblemReportingPopup
          bookingId={reportedBookingId}
          closePopup={() => setReportedBookingId(null)}
          problemMessages={PROBLEM_MESSAGES}
        />
      )}

      {showRequestNewSlotsModal && (
        <RequestNewSlotsConfirmation
          booking={showRequestNewSlotsModal}
          onClose={() => setShowRequestNewSlotsModal(false)}
        />
      )}
    </div>
  );
};

export default ManageBookings;

const ManageBookingCard = ({
  booking,
  cancelledBooking,
  handleCanceldBooking,
  setReportedBookingId,
  setCancelledBooking,
  loading,
  setLoading,
  setShowRequestNewSlotsModal,
}) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const [reschedule, setReschedule] = useState(false);

  const handleRescheduleBooking = async () => {
    setLoading(true);
    try {
      const { bookingId, date, startTime, endTime, timeZone } = selectedTimeSlot;

      const payload = {
        id: bookingId,
        startTime: dayjs(`${date} ${startTime}`)
          .tz(timeZone || 'Asia/Calcutta')
          .toISOString(),
        endTime: dayjs(`${date} ${endTime}`)
          .tz(timeZone || 'Asia/Calcutta')
          .toISOString(),
      };
      const updatedBooking = await acceptBooking(payload);
      booking.startTime = updatedBooking.startTime;
      booking.endTime = updatedBooking.endTime;
      setReschedule(false);
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  const isCancelAllowed = useMemo(() => {
    const cancellingLimit = dayjs().add(2, 'hour');
    const endDate = dayjs(booking.startTime);
    return booking.stage === BOOKING_STAGE.COACH_ASSIGNED && cancellingLimit.isBefore(endDate);
  }, [booking]);

  const isConductAllowed = () => {
    const isAllowed = dayjs().isBetween(
      dayjs(booking.startTime).add(1, 'day'),
      dayjs(booking.startTime).subtract(1, 'day')
    );
    return !isAllowed;
  };

  const isSubmitFeedbackAllowed = () => {
    const isAllowed =
      dayjs().isAfter(booking.startTime) && booking.stage === BOOKING_STAGE.COACH_ASSIGNED;
    return isAllowed;
  };

  return (
    <div
      id={booking.id}
      className='bg-ab-tertiary dark:bg-ab-primary-shade-1 text-ab-primary dark:text-ab-tertiary border border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100 rounded-lg overflow-hidden relative'
    >
      {(booking.problemReportedByStudent ||
        booking.problemReportedByCoach ||
        booking.status === BOOKING_STATUS.DEFAULTED) && (
        <Tooltip
          arrow
          title={<span className='text-base'>Please connect with Admin to reactivate session</span>}
        >
          <div className='text-center absolute bg-ab-tertiary dark:bg-ab-primary-shade-1 bg-opacity-80 dark:bg-opacity-70 w-full h-full z-20 text-gray-c2 dark:text-ab-tertiary flex flex-col gap-4 items-center justify-center'>
            <LockOutlined color='inherit' style={{ fontSize: '4rem' }} />
            <h1 className='flex items-center gap-1'>
              <ReportProblem />
              {booking.problemReportedByStudent || booking.problemReportedByCoach ? (
                <span>
                  Problem reported by {booking.problemReportedByStudent ? 'student' : 'you'}
                </span>
              ) : (
                <span>Defaulted Session</span>
              )}
            </h1>
          </div>
        </Tooltip>
      )}

      {/* booking status, stage, etc */}
      <SessionStatusBar booking={booking} />

      <hr className='mx-4 border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100' />

      {/* booking details */}
      <BookingDetailsCardView
        booking={booking}
        selectedTimeSlot={selectedTimeSlot}
        setSelectedTimeSlot={setSelectedTimeSlot}
        reschedule={reschedule}
        setReschedule={setReschedule}
      />

      {/* all actions on a booking */}
      {!(
        booking.status === 'cancelled' ||
        booking.problemReportedByCoach ||
        booking.problemReportedByStudent
      ) && (
        <>
          <hr className='mx-4 border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100' />
          {booking.stage === BOOKING_STAGE.COACH_ASSIGNED && (
            <>
              {cancelledBooking === booking ? (
                // cancel booking confirmation
                <form onSubmit={handleCanceldBooking} className='p-4'>
                  <div className='flex items-center'>
                    <Checkbox color='primary' required />
                    <span className='opacity-60 text-sm font-normal'>
                      I understand that a fine of Rs 200 will be applied if I cancel this session.
                      This fine will reflect a negative balance on my wallet.
                    </span>
                  </div>
                  <div className='flex justify-end'>
                    <button
                      type='reset'
                      onClick={() => setCancelledBooking(null)}
                      className='opacity-60 px-8'
                    >
                      Go Back
                    </button>
                    <button
                      type='submit'
                      className='bg-ab-secondary text-ab-tertiary rounded focus:outline-none py-3 px-8 flex items-center gap-2'
                      disabled={loading}
                    >
                      {loading && <CircularProgress size='1.5rem' color='inherit' />}
                      Confirm Cancellation
                    </button>
                  </div>
                </form>
              ) : // actions before session is conducted
              reschedule ? (
                <div className='flex items-center gap-8 p-4'>
                  <button
                    onClick={() => setShowRequestNewSlotsModal(booking)}
                    className='px-4 py-2 opacity-80'
                  >
                    Request New Time Slots
                  </button>
                  <span className='flex-grow' />
                  <button onClick={() => setReschedule(false)} className='opacity-60'>
                    Go Back
                  </button>
                  <button
                    onClick={handleRescheduleBooking}
                    disabled={_.isEmpty(selectedTimeSlot) || loading}
                    className={`bg-blue-c1 text-ab-tertiary rounded focus:outline-none py-2 px-6 flex items-center gap-2 ${
                      _.isEmpty(selectedTimeSlot) && 'opacity-50 cursor-not-allowed'
                    }`}
                  >
                    {loading && <CircularProgress size='1.5rem' color='inherit' />}
                    Confirm Reschedule
                  </button>
                </div>
              ) : (
                <div className='border-t border-gray-300 text-right px-4 py-6 flex justify-between items-center'>
                  {/* <button
                    onClick={() => setReportedBookingId(booking.id)}
                    className='text-gray-c2 dark:text-ab-tertiary dark:bg-opacity-80 focus:outline-none px-4 flex gap-2'
                  >
                    <ReportProblem />
                    <span className='pt-0.5'>Report a problem</span>
                  </button> */}
                  <div className='flex items-center gap-4 text-ab-primary dark:text-ab-tertiary font-normal'>
                    {booking?.SubTopic?.duration && (
                      <span className='font-medium opacity-60 ml-4'>{`Duration: ${booking.SubTopic.duration} Mins`}</span>
                    )}
                  </div>

                  <div>
                    <Tooltip
                      arrow
                      title={<span className='text-base'>Submit Feedback For Session</span>}
                    >
                      <Link
                        className={`${
                          !isSubmitFeedbackAllowed() && 'pointer-events-none opacity-40'
                        } bg-green-c1 dark:bg-ab-secondary text-white rounded focus:outline-none py-3 px-6 ml-4`}
                        to={{
                          pathname: PATH.MENTORSHIP.POST_INTERVIEW,
                          state: {
                            booking,
                            sessionType: SESSION_TYPE.CONDUCT,
                          },
                        }}
                      >
                        Submit Feedback
                      </Link>
                    </Tooltip>

                    <Tooltip
                      arrow
                      title={
                        <span className='text-base'>
                          Cancellation is not allowed within 2 hours of the session
                        </span>
                      }
                    >
                      <button
                        onClick={() => setCancelledBooking(booking)}
                        disabled={!isCancelAllowed}
                        className={`text-ab-primary dark:text-ab-tertiary px-8 ${
                          isCancelAllowed ? 'text-opacity-60' : 'text-opacity-30'
                        }`}
                      >
                        <span>Cancel Session</span>
                      </button>
                    </Tooltip>

                    <Tooltip
                      arrow
                      title={
                        <span className='text-base'>
                          View questions to ask for upcoming session
                        </span>
                      }
                    >
                      <Link
                        className='border border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100 text-ab-primary dark:text-ab-tertiary text-opacity-60 dark:text-opacity-60 rounded focus:outline-none py-3 px-8'
                        to={{
                          pathname: PATH.MENTORSHIP.PREVIEW_SESSION,
                          state: {
                            booking,
                            sessionType: SESSION_TYPE.PREVIEW,
                          },
                        }}
                      >
                        Preview Session
                      </Link>
                    </Tooltip>

                    <Tooltip
                      arrow
                      title={<span className='text-base'>Start your session here</span>}
                    >
                      <Link
                        className={`${
                          isConductAllowed() && 'pointer-events-none opacity-40'
                        } bg-green-c1 dark:bg-ab-secondary text-white rounded focus:outline-none py-3 px-6 ml-4`}
                        to={{
                          pathname: PATH.MENTORSHIP.CONDUCT_SESSION,
                          state: {
                            booking,
                            sessionType: SESSION_TYPE.CONDUCT,
                          },
                        }}
                      >
                        Conduct Session
                      </Link>
                    </Tooltip>
                  </div>
                </div>
              )}
            </>
          )}
          {[
            BOOKING_STAGE.SESSION_CONDUCTED,
            BOOKING_STAGE.FEEDBACK_SUBMITTED,
            BOOKING_STAGE.ADMIN_APPROVED,
          ].includes(booking.stage) && (
            // actions after session conducted
            <div className='border-t border-gray-300 flex items-center justify-between p-4'>
              <div className='flex items-center gap-4 text-ab-primary dark:text-ab-tertiary font-normal'>
                {booking?.SubTopic?.duration && (
                  <span className='font-medium opacity-60 ml-4'>{`Duration: ${booking.SubTopic.duration} Mins`}</span>
                )}
              </div>

              <Tooltip
                arrow
                title={<span className='text-base'>Review your conducted session here.</span>}
              >
                <Link
                  to={`${PATH.MENTORSHIP.DETAILED_REPORT}/${booking.id}`}
                  className='border border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100 rounded focus:outline-none py-2 px-8'
                >
                  Detailed Report
                </Link>
              </Tooltip>
            </div>
          )}
        </>
      )}
    </div>
  );
};
