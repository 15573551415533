import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { DATE_FORMAT_1 } from '../../constants';

import { Modal, Radio } from '@material-ui/core';

import Rating from '@material-ui/lab/Rating';
import RenderThumbnail from '../utils/RenderThumbnail';

import { Autorenew as LoadingIcon } from '@material-ui/icons';

import { submitAlmaXAssignmentEvaluation } from '../../services/almaXAssignmentEvaluationApiService';

export default function FeedbackModal({ open, handleOpen, handleClose, item, onEvaluationSubmit }) {
  const [rating, setRating] = useState([]);
  const [plaigarism, setPlaigarism] = useState('');
  const [feedbackText, setFeedbackText] = useState('');

  const [isRatingError, setIsRatingError] = useState(false);
  const [isPlaigarismError, setIsPlaigarismError] = useState(false);
  const [isFeedbackTextError, setIsFeedbackTextError] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);

  const [isIterateThroughRatingObj, setIsIterateThroughRatingObj] = useState(false);

  const [loading, setLoading] = useState(false);

  const isEdit = item?.evaluationStatus !== 'completed' && item?.evaluationStatus !== 'rejected';

  function fetchRubricsData() {
    try {
      if (!item?.assignment?.attributes?.rubrics) return;
      const allRubricsArr = JSON.parse(JSON.stringify(item?.assignment?.attributes?.rubrics));
      if (isEdit) {
        setFeedbackText('');
        setPlaigarism('');
      } else {
        setFeedbackText(item?.coachFeedback?.feedback);
        const rubricsValue = item?.coachFeedback?.ratings;
        for (let i = 0; i < allRubricsArr.length; i++) {
          const rubricItem = allRubricsArr[i];
          const rubricItemKey = rubricItem.rubricKey;
          if (rubricsValue && rubricsValue[rubricItemKey]) {
            rubricItem.value = rubricsValue[rubricItemKey];
          } else {
            setIsIterateThroughRatingObj(true);
            break;
          }
        }
        if (item?.coachFeedback?.plagiarisedSolution) {
          setPlaigarism('plagiarised');
        } else {
          setPlaigarism('self_written');
        }
      }
      setRating(allRubricsArr);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchRubricsData();
  }, [isEdit, isIterateThroughRatingObj, item]);

  const handlePlaigarismCLick = (event) => {
    setPlaigarism(event.target.value);
  };

  const onSubmit = async () => {
    const ratingObj = {};

    setIsRatingError(false);
    setIsPlaigarismError(false);
    setIsFeedbackTextError(false);
    setIsSubmitError(false);

    for (let i = 0; i < rating.length; i++) {
      const item = rating[i];

      if (!item.value) {
        setIsRatingError(true);
        return;
      }

      ratingObj[item.rubricKey] = item.value;
    }

    if (plaigarism.length < 2) {
      setIsPlaigarismError(true);
      return;
    }

    if (feedbackText.length < 25) {
      setIsFeedbackTextError(true);
      return;
    }

    setLoading(true);

    const body = {
      assignmentId: item?.assignment?.id,
      submissionId: item.submissionId,
      coachFeedback: {
        ratings: ratingObj,
        feedback: feedbackText,
        plagiarisedSolution: plaigarism === 'plagiarised',
      },
    };

    try {
      await submitAlmaXAssignmentEvaluation(body);
      setLoading(false);
      handleClose();
      onEvaluationSubmit();
    } catch (error) {
      console.error(error);
      setIsSubmitError(true);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='w-screen'
    >
      <div className='relative overflow-y-auto h-5/6 top-2 md:top-10 m-auto bg-ab-tertiary dark:bg-ab-primary-shade-1 rounded-lg md:px-8 px-3 py-4 z-50 lg:w-7/12 w-11/12 border-solid border border-gray-600'>
        <div className='absolute right-2 top-2 cursor-pointer' onClick={handleClose}>
          X
        </div>
        <div className='flex flex-col'>
          <div className='flex mb-4'>
            {item?.assignment?.attributes?.icon?.data?.attributes?.url ? (
              <img
                src={item?.assignment?.attributes?.icon?.data?.attributes?.url}
                className='w-16 h-16 rounded-full'
              />
            ) : (
              <RenderThumbnail name={item?.assignment?.attributes?.name} thumbnail='' size='3' />
            )}
            <div className='flex-auto'>
              <div className='mr-2 px-4 flex items-center gap-3'>
                <div className='flex-auto '>
                  <h3 className='font-medium'>{item?.assignment?.attributes?.name}</h3>

                  {/* <span className='text-gray-600 text-opacity-80 text-sm'>
                    {item?.project?.attributes?.project_category?.data?.attributes?.name}
                  </span> */}
                </div>
                <div>
                  <div className='text-gray-500 text-sm'>
                    Submitted on {dayjs(item?.submittedAt).format(DATE_FORMAT_1)}
                  </div>
                  {!isEdit && item?.evaluatedAt && (
                    <div className='text-gray-500 text-sm '>
                      Evaluated on {dayjs(item?.evaluatedAt).format(DATE_FORMAT_1)}
                    </div>
                  )}
                </div>
              </div>

              <div className='flex items-center px-4 mt-3 rounded w-max text-sm'>
                <div className='font-medium mr-5'>By</div>
                <RenderThumbnail
                  name={item?.student?.firstName}
                  thumbnail={item?.student?.thumbnail}
                  size='2'
                />

                <div className='ml-2'>{`${item?.student?.firstName} ${item?.student?.lastName}`}</div>
              </div>
            </div>
          </div>
          <hr className='mb-4' />
          <div className='font-bold'>Ratings & Feedback</div>

          {!isEdit && isIterateThroughRatingObj
            ? item?.coachFeedback?.ratings &&
              Object.keys(item?.coachFeedback?.ratings).map((objectKey, index) => (
                <div className='flex mb-2' key={index}>
                  <div className='w-64 text-gray-400 text-sm'>{objectKey}</div>
                  <Rating
                    max={10}
                    readOnly={true}
                    name={objectKey}
                    value={item?.coachFeedback?.ratings[objectKey]}
                  />
                </div>
              ))
            : rating.map((rubricItem, index) => (
                <div className='flex mb-2' key={index}>
                  <div className='w-64 text-gray-400 text-sm'>{rubricItem.rubricName}</div>
                  <Rating
                    max={10}
                    readOnly={!isEdit}
                    name={rubricItem.rubricName}
                    value={rubricItem.value}
                    onChange={(event, newValue) => {
                      const newRating = [...rating];
                      newRating[index].value = newValue;
                      setRating(newRating);
                    }}
                  />
                </div>
              ))}
          {isRatingError && <span className='text-red-700 text-sm'>Ratings cannot be empty!</span>}

          <hr className='mt-4 mb-4' />

          <div className='text-gray-400 text-sm'>Plaigarism</div>
          <div className='flex items-center -mb-2 -ml-2'>
            <Radio
              disabled={!isEdit}
              color='primary'
              name='plaigarism'
              value='self_written'
              checked={plaigarism === 'self_written'}
              onChange={handlePlaigarismCLick}
            />
            <span className='text-sm font-normal opacity-60'>Self-written submission</span>
          </div>
          <div className='flex items-center -mb-2 -ml-2'>
            <Radio
              disabled={!isEdit}
              color='primary'
              name='plaigarism'
              value='plagiarised'
              checked={plaigarism === 'plagiarised'}
              onChange={handlePlaigarismCLick}
            />
            <span className='text-sm font-normal opacity-60'>Plagiarised submission</span>
          </div>
          {isPlaigarismError && (
            <span className='text-red-700 text-sm mt-1'>Please select plaigarism status!</span>
          )}

          <div className='text-sm text-gray-400 mt-4'>Feedback</div>
          <textarea
            disabled={!isEdit}
            rows='4'
            className='w-full border border-gray-300 focus:outline-none focus:border-blue-c1 focus:ring-1 focus:ring-blue-c1 rounded-lg p-2'
            value={feedbackText}
            onChange={(event) => {
              setFeedbackText(event.target.value);
            }}
          />

          {isFeedbackTextError && (
            <span className='text-red-700 text-sm mt-1'>
              Feedback should be atleast 25 characters long.
            </span>
          )}

          {isEdit && (
            <div className='flex flex-col items-center justify-center  my-4'>
              {isSubmitError && (
                <span className='text-red-700 text-sm mt-1'>
                  Failed to submit. Please try again.
                </span>
              )}

              <div className='flex justify-center'>
                <button
                  className='w-max bg-red-700 py-2 px-10 font-medium text-white text-sm rounded focus:outline-none'
                  disabled={loading}
                  onClick={onSubmit}
                >
                  {loading ? <LoadingIcon className='animate-spin' /> : 'Submit Feedback'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
