import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { LinkedIn, ArrowBack, ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import isObject from 'lodash/isObject';

import { CircularProgress } from '@material-ui/core';

import RenderThumbnail from '../../components/utils/RenderThumbnail';
import { getBookingQuestions, getDetailedBooking } from '../../services/bookingApiService';
import dayjs from 'dayjs';
import { DATE_FORMAT_1, PATH } from '../../constants';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import RatingTemplateView from './components/RatingTemplateView';
import { DescriptionToolTip } from './components/RatingTemplate';

dayjs.extend(advancedFormat);

function DetailedReport(props) {
  const [booking, setBooking] = useState(null);
  const [bookingQuestions, setBookingQuestions] = useState(null);
  const [solutionToggle, setSolutionToggle] = useState({});

  const ratingTemplateByConceptId = useSelector(
    (state) => state.questionLevelsReducer.ratingTemplateByConceptId
  );

  const { bookingId } = props.match.params;

  const fetchBooking = async () => setBooking(await getDetailedBooking(bookingId));

  const fetchBookingQuestions = async () =>
    setBookingQuestions(await getBookingQuestions(bookingId));

  useEffect(() => {
    try {
      Promise.all([fetchBooking(), fetchBookingQuestions()]);
    } catch (err) {
      console.error(err);
    }
  }, []);

  return booking ? (
    <div className='relative'>
      <h1 className='text-3xl text-center font-semibold tracking-wide'>Detailed Report</h1>

      <button onClick={() => props.history.goBack()} className='absolute left-20 top-2'>
        <ArrowBack />
      </button>

      <div className='container mx-auto mt-8 px-4'>
        {/* Booking Info */}
        <div className='flex items-center gap-16'>
          <h2 className='text-2xl font-medium capitalize'>{booking.themeName}</h2>
          <span className='opacity-60 text-sm'>
            Conducted At: {dayjs(booking.conductedAt).format(DATE_FORMAT_1)}
          </span>
          <span className='ml-auto opacity-30 text-sm'>Booking ID: #{booking.id}</span>
        </div>
        <h3 className='capitalize mt-2'>{booking.subTopicName}</h3>

        <div className='flex mt-6'>
          {/* Candidate Details */}
          <div>
            <div className='text-sm opacity-60 mb-1'>Candidate</div>
            <UserDetails user={booking.Student} />
          </div>

          <span className='mx-10' />

          {/* Coach Details */}
          <div>
            <div className='text-sm opacity-60 mb-1'>Coach</div>
            <UserDetails user={booking.Coach} />
          </div>

          {/* Watch Recording Link */}
          <div className='ml-auto mt-auto'>
            <Link
              to={{
                pathname: `${PATH.MENTORSHIP.WATCH_RECORDING}/${bookingId}`,
                state: { booking },
              }}
              className='bg-green-c1 dark:bg-ab-secondary text-white rounded block px-8 py-2'
            >
              Watch Recording
            </Link>
          </div>
        </div>
      </div>

      {/* Booking Questions */}
      <div className='min-h-screen bg-gray-c1 dark:bg-white dark:bg-opacity-10 p-2 mt-8'>
        {bookingQuestions ? (
          bookingQuestions.map((bq, i) => (
            <div
              className='container mx-auto bg-ab-tertiary dark:bg-ab-primary-shade-1 rounded-lg border border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100 p-4 m-4'
              key={bq.questionId}
            >
              <div className='flex items-center gap-4'>
                <pre>
                  <span className='font-medium whitespace-pre-wrap'>
                    Q{i + 1}. {bq.Question.question}
                  </span>
                </pre>
                {/* Solution toggle */}
                <span
                  onClick={() =>
                    setSolutionToggle({
                      [bq.questionId]: !solutionToggle[bq.questionId],
                    })
                  }
                  className='text-sm opacity-60 cursor-pointer ml-auto'
                >
                  Solution {solutionToggle[bq.questionId] ? <ArrowDropUp /> : <ArrowDropDown />}
                </span>
              </div>

              {/* Solution */}
              {solutionToggle[bq.questionId] && (
                <pre>
                  <code>
                    <p className='text-sm opacity-80 my-4 ml-8'>{bq.Question.solution}</p>
                  </code>
                </pre>
              )}

              {/* tags */}
              <div className='text-xs opacity-60 capitalize mt-2 ml-8'>
                {/* <span>
                  Previously Attended :{' '}
                  <span className='font-semibold'>
                    {bq.alreadyAsked ? 'Yes' : 'No'}
                  </span>
                </span> */}
                {/* <span className='mx-4' /> */}
                <span>
                  Difficulty Level :{' '}
                  <span className='font-semibold capitalize'>
                    {bq.Question.difficultyLevel || '--'}
                  </span>
                </span>
              </div>

              {/* Question Ratings */}
              <div className='mt-4'>
                <div className='grid grid-cols-3 opacity-60 gap-x-8 font-medium whitespace-nowrap mb-8'>
                  <span />
                  <span className='ml-6'>Coach Ratings</span>
                  <span>Self Ratings</span>
                </div>
                <div className='grid px-4 mx-4 my-4 grid-cols-3 gap-x-6 gap-y-5'>
                  {Object.keys({ ...bq.ratingFromCoach, ...bq.ratingFromStudent })
                    ?.filter((key) => key !== 'feedback')
                    ?.map((name) => {
                      const coachRating = bq.ratingFromCoach?.[name];
                      const studentRating = bq.ratingFromStudent?.[name];

                      return (
                        <>
                          <div>
                            <span className='opacity-60'>{name}</span>
                            {coachRating?.description && (
                              <DescriptionToolTip description={coachRating.description} />
                            )}
                          </div>
                          <RatingTemplateView
                            param={
                              !isObject(coachRating)
                                ? coachRating
                                : {
                                    ...coachRating,
                                    value: coachRating?.value,
                                  }
                            }
                          />

                          <RatingTemplateView
                            param={
                              !isObject(studentRating)
                                ? studentRating
                                : {
                                    ...studentRating,
                                    value: studentRating?.value,
                                  }
                            }
                          />
                        </>
                      );
                    })}
                </div>
                {/* Question Feedback */}
                {ratingTemplateByConceptId[bq.Question.conceptId]?.feedback && (
                  <div className='mt-6 px-8'>
                    <div className='font-medium text-sm mb-2'>Feedback</div>
                    <p className='opacity-80'>{bq.ratingFromCoach?.feedback || '--'}</p>
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className='flex items-center justify-center text-blue-c1 dark:text-ab-tertiary h-75h mt-20'>
            <CircularProgress color='inherit' />
          </div>
        )}

        {/* Booking Overall Ratings */}
        <div className='container mx-auto bg-ab-tertiary dark:bg-ab-primary-shade-1 rounded-lg border border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100 p-4 m-4'>
          <div className='text-lg font-medium mb-4 mx-4'>Overall Candidate Rating</div>
          <div className='px-4 my-4 grid grid-cols-2 gap-y-4'>
            {Object.entries(booking.ratingByCoach || {})
              ?.filter(([key]) => key !== 'feedback')
              .map(([key, values]) => (
                <>
                  <div>
                    <span className='opacity-60'>{key}</span>
                    {values?.description && <DescriptionToolTip description={values.description} />}
                  </div>
                  <RatingTemplateView param={values} />
                </>
              ))}
            {booking.ratingByCoach?.feedback && (
              <div>
                <div className='font-medium mt-6 '>Feedback</div>
                <p className='opacity-80 mt-2'>{booking.ratingByCoach.feedback}</p>
              </div>
            )}
          </div>
        </div>

        {/* <div className='text-center mb-8'>
          <button className='bg-ab-secondary rounded px-8 py-2 mx-auto'>Download as PDF</button>
        </div> */}
      </div>
    </div>
  ) : (
    <div className='flex items-center justify-center text-blue-c1 dark:text-ab-tertiary h-75h mt-20'>
      <CircularProgress color='inherit' />
    </div>
  );
}

const UserDetails = ({ user }) => (
  <span className='flex items-center gap-2'>
    <RenderThumbnail thumbnail={user.thumbnail} name={user.firstName} size={3} />
    <span>
      {user.firstName} {user.lastName}
    </span>
    <a href={user.linkedInUrl} target='_blank' rel='noopener noreferrer'>
      <LinkedIn htmlColor='#0275B4' />
    </a>
  </span>
);

export default DetailedReport;
