import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { CheckCircle, DeleteOutline, EditOutlined } from '@material-ui/icons';
import { TextField } from '@material-ui/core';

import { PATH } from '../../../constants';
import { updateCoach } from '../../../redux/authSlice';
import { LoadingCircle } from '../../utils/Icons';

const Education = ({ editMode, setActiveOption, education, unverifiedEducation }) => {
  const dispatch = useDispatch();
  const [allEducation, setAllEducation] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => setAllEducation([...unverifiedEducation]), [unverifiedEducation]);

  const handleInput = ({ target }) => setFormData({ ...formData, [target.name]: target.value });

  const updateDetails = async (details) => {
    setLoading(true);
    try {
      await dispatch(updateCoach({ education: details }));
      toast.info('Profile Updated!', {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } catch (err) {
      console.error(err);
      toast.error('Failed To Update Profile! \n Please Try Again Later!!', {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    setLoading(false);
  };

  const addDetails = async (e) => {
    e.preventDefault();
    const details = [...allEducation];
    details.push(formData);
    await updateDetails(details);
    setFormData({});
    if (!editMode) setActiveOption('C');
  };

  const removeDetails = (i) => {
    const details = [...allEducation];
    details.splice(i, 1);
    updateDetails(details);
  };

  const editDetails = (i) => {
    const details = [...allEducation];
    const [toEdit] = details.splice(i, 1);
    setAllEducation(details);
    setFormData(toEdit);
  };

  const isVerified = (obj, objArray = education) => {
    if (!objArray) return false;
    return objArray.find(
      (elem) => Object.values(elem).sort().toString() === Object.values(obj).sort().toString()
    );
  };

  return (
    <div className='w-full max-w-4xl mt-6 md:mt-16 flex flex-col'>
      {/* Current Education Info */}
      <div className='flex flex-col md:flex-row gap-8 text-ab-primary dark:text-ab-tertiary'>
        <div className='md:w-1/4 opacity-30'>Education</div>
        <div className='md:w-3/4'>
          {education &&
            education.map((item, index) => (
              <div className='mb-8 flex justify-between' key={index}>
                <div>
                  <div className='font-semibold opacity-80'>
                    {item.degree}
                    {/* {isVerified(item) && ( */}
                    <CheckCircle style={{ color: '#76CE65', marginLeft: '0.5rem' }} />
                    {/* )} */}
                  </div>
                  <div className='text-sm opacity-60 my-1'>{item.instituteName}</div>
                  <div className='text-sm'>
                    <span className='opacity-30'>{item.years}</span>
                    {item.grade && <span className='ml-4 opacity-60'>({item.grade} Grade)</span>}
                  </div>
                </div>
              </div>
            ))}
          {allEducation &&
            allEducation.map((item, index) => (
              <div className='mb-8 flex justify-between' key={index}>
                <div>
                  <div className='font-semibold opacity-80'>
                    {item.degree}
                    {isVerified(item) && (
                      <CheckCircle style={{ color: '#76CE65', marginLeft: '0.5rem' }} />
                    )}
                  </div>
                  <div className='text-sm opacity-60 my-1'>{item.instituteName}</div>
                  <div className='text-sm'>
                    <span className='opacity-30'>{item.years}</span>
                    {item.grade && <span className='ml-4 opacity-60'>({item.grade} Grade)</span>}
                  </div>
                </div>

                <div className='flex gap-2'>
                  <div onClick={() => editDetails(index)} className='cursor-pointer opacity-30'>
                    <EditOutlined color='inherit' />
                  </div>
                  <div onClick={() => removeDetails(index)} className='cursor-pointer opacity-30'>
                    <DeleteOutline color='inherit' />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* form */}
      <form
        onSubmit={addDetails}
        className='grid grid-cols-1 md:grid-cols-2 grid-rows-2 gap-4 md:gap-8 my-8'
      >
        <TextField
          label='Name of Institute'
          placeholder='College / School / University'
          name='instituteName'
          variant='outlined'
          type='text'
          value={formData.instituteName || ''}
          onChange={handleInput}
          fullWidth
          required
          autoFocus
        />
        <TextField
          label='Degree'
          placeholder='Master in / Bachelor in'
          name='degree'
          variant='outlined'
          type='text'
          value={formData.degree || ''}
          onChange={handleInput}
          fullWidth
          required
        />
        <TextField
          label='Years'
          placeholder='Ex. 2018 - 2020'
          name='years'
          variant='outlined'
          type='text'
          value={formData.years || ''}
          onChange={handleInput}
          fullWidth
          required
        />
        <TextField
          label='Grade'
          placeholder='As on Result'
          name='grade'
          variant='outlined'
          type='text'
          value={formData.grade || ''}
          onChange={handleInput}
          fullWidth
        />

        {/* submit button */}
        <button
          disabled={loading}
          type='submit'
          className='bg-green-c1 dark:bg-ab-secondary text-white font-medium rounded focus:outline-none py-4 mt-8'
        >
          {loading && <LoadingCircle iconStyle='w-6 mr-2' />}
          {editMode ? 'UPDATE EDUCATION' : 'NEXT'}
        </button>

        {!editMode && (
          <Link to={PATH.HOME} className='text-gray-400 m-auto mt-12'>
            Skip for now
          </Link>
        )}
      </form>

      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

export default Education;
