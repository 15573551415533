import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ChatBox, HMSRoomProvider, HMSThemeProvider } from '@100mslive/hms-video-react';

import InterviewDetailsHeader from './InterviewDetailsHeader';
import Conference from './conference/Conference';
import Questions from './questions/Questions';
import { SESSION_TYPE } from '../../../constants';
import InterviewGuidelines from './InterviewGuidelines';

const TABS = ['SESSION QUESTIONS', 'CHAT'];

const InterviewSimulatorContainer = () => {
  const [currentTab, setCurrentTab] = useState(TABS[0]);
  const [showGuidelines, setShowGuidelines] = useState(true);
  const [rightSideWidth, setRightSideWidth] = useState('40vw');

  const sliderRef = useRef(null);
  const rightSideRef = useRef(null);

  const history = useHistory();
  const booking = history.location.state?.booking;
  const sessionType = history.location.state?.sessionType;

  useEffect(() => {
    if (sliderRef?.current) {
      sliderRef.current.onmousedown = (e) => {
        let dragX = e.clientX;
        document.onmousemove = (e) => {
          setRightSideWidth(rightSideRef.current.offsetWidth - e.clientX + dragX + 'px');
          // leftSideRef.current.style.width =
          //   leftSideRef.current.offsetWidth + e.clientX - dragX + 'px';
          dragX = e.clientX;
        };
      };
      document.onmouseup = () => {
        document.onmousemove = null;
      };
    }
  });

  if (showGuidelines && sessionType === SESSION_TYPE.CONDUCT)
    return <InterviewGuidelines booking={booking} setShowGuidelines={setShowGuidelines} />;

  return (
    <div className='h-screen w-screen fixed top-0 bg-ab-tertiary dark:bg-ab-primary overflow-scroll z-50'>
      <div className='flex h-full'>
        {/* left side */}
        <div className='flex-grow flex flex-col gap-4 p-4'>
          <InterviewDetailsHeader booking={booking} />
          <Conference booking={booking} sessionType={sessionType} />
        </div>

        {/* slider */}
        <div
          ref={sliderRef}
          className='h-96 my-auto w-1.5 bg-gray-c1 dark:bg-ab-primary-shade-2 rounded cursor-move'
        />

        {/* right side */}
        <div
          className='max-h-full flex flex-col gap-4 p-4'
          ref={rightSideRef}
          style={{ maxWidth: '50vw', width: rightSideWidth }}
        >
          <div
            className='grid grid-cols-2 bg-white bg-opacity-10 border border-solid border-ab-primary-shade-2 border-opacity-20 rounded-xl overflow-hidden shadow-lg'
            style={{ minHeight: '3.5rem' }}
          >
            {TABS.map((tab) => (
              <button
                onClick={() => setCurrentTab(tab)}
                key={tab}
                className={
                  'text-center font-medium opacity-60 focus:outline-none py-4' +
                  (currentTab === tab &&
                    ' bg-blue-c1 bg-opacity-20 text-blue-c1 font-semibold opacity-100')
                }
              >
                {tab}
              </button>
            ))}
          </div>

          <Questions
            hidden={currentTab !== TABS[0]}
            bookingId={booking.id}
            bookingSubTopicId={booking.subTopicId}
            sessionType={sessionType}
          />

          <div hidden={currentTab !== TABS[1]} className='flex-grow relative'>
            {sessionType === SESSION_TYPE.CONDUCT ? (
              <>
                <ChatBox />
                <div className='bg-white absolute inset-x-0 top-0 h-12 rounded-t-2xl' />
              </>
            ) : (
              <div className='h-full flex items-center justify-center text-xl font-medium'>
                <p>Chat will be available when you conduct the session</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const InterviewSimulator = () => {
  const colorTheme = useSelector((state) => state.colorModeReducer.colorTheme.palette.type);
  return (
    <HMSThemeProvider
      appBuilder={{
        theme: colorTheme,
        enableChat: true,
        enableScreenShare: true,
        logo: '',
        logoClass: '',
        headerPresent: true,
        videoTileAspectRatio: { width: 1, height: 1 },
        showAvatar: true,
        avatarType: 'initial',
      }}
      toast={(message, options = {}) => console.log(message, options)}
    >
      <HMSRoomProvider>
        <InterviewSimulatorContainer />
      </HMSRoomProvider>
    </HMSThemeProvider>
  );
};

export default InterviewSimulator;
