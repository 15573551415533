import { API } from 'aws-amplify';

import {
  PROJECT_EVALUATION_ENDPOINT_NAME,
  USER_API_GATEWAY_AUTH_FREE_ENDPOINT_NAME,
  USER_API_GATEWAY_ENDPOINT_NAME,
} from '../aws-exports';

export const getMyProfile = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/me', {});

export const updateMyProfile = async (data) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/me', { body: data });

export const uploadMyKycDetails = async (data) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/kyc-detail', {
    body: data,
  });

export const updateMyKycDetails = async (data) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/kyc-detail', {
    body: data,
  });

export const getMyKycDetails = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/kyc-detail', {});

export const sendOTP = async (resource, resourceValue) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/send-otp', {
    body: { resource, resourceValue },
  });

export const validateOTP = async (resource, otp) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/validate-otp', {
    body: { resource, otp },
  });

// Wallet Operations APIs
export const createWalletTransaction = async (walletTransactionDetail) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/wallet/transaction', {
    body: { ...walletTransactionDetail },
  });

export const getUserWalletTransactions = async (userId) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/wallet/filter-transactions', {
    body: { userId: userId },
  });

export const getWalletAmounts = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/wallet-balance', {});

// interview simulator APIs
export const getTwilioAccessToken = async (channel, user = 'coach') =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/interview/token', {
    queryStringParameters: { channel, user },
  });

export const startSessionRecording = async (channel) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/interview/recording/start', {
    body: { channel },
  });

export const stopSessionRecording = async (channel, rid, sid) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/interview/recording/stop', {
    body: { channel, rid, sid },
  });

export const getRecordings = async (channel) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/interview/recordings', {
    queryStringParameters: { channel },
  });

export const getJWTToken = async () => {
  return await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/auth-jwt', {
    queryStringParameters: { type: 'coach' },
  });
};

export const syncUserRole = async () => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/sync-role-and-enrollments', {
    body: { userRole: 'coach' },
  });
};

export const getActivity = async (userEmail) => {
  return await API.get(USER_API_GATEWAY_AUTH_FREE_ENDPOINT_NAME, '/calendar-activity', {
    queryStringParameters: { email: userEmail },
  });
};

export const getClassesForCoach = async () => {
  return await API.get(PROJECT_EVALUATION_ENDPOINT_NAME, '/api/v2/classroom/classes-for-coach', {});
};

export const getWorkExperience = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/work-experience', {});

export const updateWorkExperience = async (payload) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/work-experience', {
    body: payload,
  });

export const getCompanies = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/companies', {});

export const getJobTitles = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/job-titles', {});

export const addNewCompany = async (company) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/companies', {
    body: { name: company },
  });
};

export const addNewJobTitle = async (jobTitle) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/job-titles', {
    body: { name: jobTitle },
  });
};

export const getCoachExpertise = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/sub-topic', {});

export const updateCoachExpertise = async (payload) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/sub-topic', {
    body: payload,
  });

export const getExpertiseGigsStatus = async (userId) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach-onboarding', {
    queryStringParameters: { userId },
  });

export const updateExpertiseGigStatus = async (data) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach-onboarding', {
    body: data,
  });

export const getRolesForCoach = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach-roles');

export const getCoachInfo = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/info');

export const updateCoachInfo = async (data) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/info', {
    body: data,
  });

export const getCoachingRoles = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coaching-role', {});

export const addNewCoachingRole = async (role) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coaching-role', {
    body: { name: role },
  });
};
