import {
  Checkbox,
  makeStyles,
  Radio,
  TextField,
  withStyles,
  createMuiTheme,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import COLORS from './globals/COLORS';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

export const isUrlValid = (url) => {
  const res = url.match(
    /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&/=]*)/g
  );
  return res != null;
};

export const getFullName = (user) => {
  const firstName = user?.firstName ?? '';
  const lastName = user?.lastName ?? '';
  return `${firstName} ${lastName}`.trim();
};

export const getFirstAndLastNamesFromName = (name) => {
  if (!name) {
    return { firstName: null, lastName: null };
  }
  const matches = name.match(/\S+/g);
  const firstName = matches[0];
  const lastName = matches.slice(1).join(' ');
  return { firstName, lastName };
};

export class UserDefinedError extends Error {}

export const isEmailNotVerified = (user) => {
  return user.v === 2 && user.isEmailNotVerified;
};
export const doesUserHaveMissingDetails = (user) => {
  return !user.firstName || !user.lastName || !user.phone;
};

export const useDarkThemeInputStyle = makeStyles({
  root: {
    '& label': {
      color: COLORS.AB_TERTIARY_SHADE_1,
    },
    '& label.Mui-disabled': {
      color: COLORS.AB_TERTIARY_SHADE_1,
    },
    '& label.Mui-focused': {
      color: COLORS.AB_TERTIARY,
    },
    '& input': {
      color: COLORS.AB_TERTIARY,
    },
    '& input.Mui-disabled': {
      color: COLORS.AB_TERTIARY_SHADE_1,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: COLORS.AB_TERTIARY_SHADE_1,
      },
      '&:hover fieldset': {
        borderColor: COLORS.AB_TERTIARY,
      },
      '&.Mui-focused fieldset': {
        borderColor: COLORS.AB_TERTIARY,
      },
      '&.Mui-disabled fieldset': {
        borderColor: COLORS.AB_TERTIARY_SHADE_1,
      },
    },
  },
  icon: {
    fill: COLORS.AB_TERTIARY,
  },
  button: {
    '&:hover': {
      transition: 'transform 0.3s',
      transform: 'scale(1.05)',
    },
    transition: 'transform 0.25s',
    fontSize: 'inherit',
  },
});

export const TextFieldDark = withStyles({
  root: {
    '& label': {
      color: COLORS.AB_TERTIARY_SHADE_1,
    },
    '& label.Mui-disabled': {
      color: COLORS.AB_TERTIARY_SHADE_1,
    },
    '& label.Mui-focused': {
      color: COLORS.AB_TERTIARY,
    },
    '& input': {
      color: COLORS.AB_TERTIARY,
    },
    '& textarea': {
      color: COLORS.AB_TERTIARY,
    },
    '& .MuiSelect-select': {
      color: COLORS.AB_TERTIARY,
    },
    '& input.Mui-disabled': {
      color: COLORS.AB_TERTIARY_SHADE_1,
    },
    '& .MuiSelect-icon': {
      color: COLORS.AB_TERTIARY,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: COLORS.AB_TERTIARY_SHADE_1,
      },
      '&:hover fieldset': {
        borderColor: COLORS.AB_TERTIARY,
      },
      '&.Mui-focused fieldset': {
        borderColor: COLORS.AB_TERTIARY,
      },
      '&.Mui-disabled fieldset': {
        borderColor: COLORS.AB_TERTIARY_SHADE_1,
      },
    },
  },
})(TextField);

export const TextFieldLight = withStyles({
  root: {
    '& label': {
      color: COLORS.AB_GRAY_C5,
    },
    '& label.Mui-disabled': {
      color: COLORS.AB_GRAY_C5,
    },
    '& label.Mui-focused': {
      color: COLORS.AB_GRAY_C5,
    },
    '& input': {
      color: COLORS.AB_GRAY_C6,
    },
    '& textarea': {
      color: COLORS.AB_GRAY_C6,
    },
    '& input.Mui-disabled': {
      color: COLORS.AB_GRAY_C6,
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: COLORS.AB_GRAY_C7,
      },
      '&:hover fieldset': {
        borderColor: COLORS.AB_GRAY_C7,
      },
      '&.Mui-focused fieldset': {
        borderColor: COLORS.AB_GRAY_C7,
      },
      '&.Mui-disabled fieldset': {
        borderColor: COLORS.AB_GRAY_C7,
      },
    },
  },
})(TextField);

export const CheckboxDark = withStyles({
  root: {
    '&$checked': {
      color: COLORS.AB_TERTIARY,
    },
    color: COLORS.AB_TERTIARY,
  },
  checked: {},
})(Checkbox);

export const RadioDark = withStyles({
  root: {
    color: COLORS.AB_TERTIARY,
    '&$checked': {
      color: COLORS.AB_TERTIARY,
    },
    '&:hover': {
      background: COLORS.AB_PRIMARY_SHADE_2,
    },
  },
  checked: {
    '&:hover': {
      background: `${COLORS.AB_PRIMARY_SHADE_2} !important`,
    },
  },
})(Radio);

export const AutocompleteDark = withStyles({
  clearIndicator: {
    color: COLORS.AB_TERTIARY,
  },
  clearIndicatorDirty: {
    color: COLORS.AB_TERTIARY,
  },
  popupIndicator: {
    color: COLORS.AB_TERTIARY,
  },
  listbox: {
    background: COLORS.AB_PRIMARY_SHADE_1,
    color: COLORS.AB_TERTIARY,
  },
  option: {
    '&:hover': {
      background: 'rgba(255,255,255,0.4)',
    },
    '&:focus': {
      background: 'rgba(255,255,255,0.4)',
    },
    '&:active': {
      background: 'rgba(255,255,255,0.4)',
    },
  },
  loading: {
    background: COLORS.AB_PRIMARY_SHADE_1,
    color: COLORS.AB_TERTIARY,
  },
  endAdornment: {
    color: COLORS.AB_TERTIARY,
  },
})(Autocomplete);

export const AutocompleteLight = withStyles({
  clearIndicator: {
    color: COLORS.AB_TERTIARY,
  },
})(Autocomplete);
export function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.floor((R * (100 + percent)) / 100);
  G = Math.floor((G * (100 + percent)) / 100);
  B = Math.floor((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
}

const DatePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: COLORS.AB_PRIMARY_SHADE_1,
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: COLORS.AB_PRIMARY_SHADE_1,
        '& button': {
          color: COLORS.AB_TERTIARY,
        },
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        color: COLORS.AB_TERTIARY,
      },
      dayLabel: {
        color: COLORS.AB_TERTIARY,
      },
      iconButton: {
        color: COLORS.AB_TERTIARY,
        backgroundColor: COLORS.AB_PRIMARY_SHADE_1,
        '&:hover': {
          backgroundColor: COLORS.AB_PRIMARY_SHADE_2,
        },
      },
    },
    MuiPickersDay: {
      day: {
        color: COLORS.AB_TERTIARY,
        '&:hover': {
          background: COLORS.AB_PRIMARY_SHADE_2,
        },
      },
      daySelected: {
        backgroundColor: COLORS.AB_PRIMARY_SHADE_2,
        '&:hover': {
          backgroundColor: COLORS.AB_PRIMARY_SHADE_2,
        },
      },
      dayDisabled: {
        color: COLORS.AB_PRIMARY_SHADE_2,
      },
      current: {
        color: COLORS.AB_TERTIARY_SHADE_1,
      },
    },
    MuiPickersYear: {
      root: {
        color: COLORS.AB_TERTIARY_SHADE_1,
        '&:hover': {
          color: COLORS.AB_SECONDARY,
        },
      },
      yearSelected: {
        color: COLORS.AB_TERTIARY,
      },
    },
    MuiPickersMonth: {
      root: {
        color: COLORS.AB_TERTIARY_SHADE_1,
        '&:hover': {
          color: COLORS.AB_SECONDARY,
        },
      },
      monthSelected: {
        color: COLORS.AB_TERTIARY,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: COLORS.AB_PRIMARY_SHADE_1,
      },
      dialog: {
        background: COLORS.AB_PRIMARY_SHADE_1,
      },
    },
    MuiIconButton: {
      root: {
        color: COLORS.AB_TERTIARY,
        '&:hover': {
          backgroundColor: COLORS.AB_PRIMARY_SHADE_1,
        },
      },
    },
    MuiTextField: {
      root: {
        '& label': {
          color: COLORS.AB_TERTIARY_SHADE_1,
        },
        '& label.Mui-disabled': {
          color: COLORS.AB_TERTIARY_SHADE_1,
        },
        '& label.Mui-focused': {
          color: COLORS.AB_TERTIARY,
        },
        '& input': {
          color: COLORS.AB_TERTIARY,
        },
        '& input.Mui-disabled': {
          color: COLORS.AB_TERTIARY_SHADE_1,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          borderColor: COLORS.AB_TERTIARY_SHADE_1,
        },
        '&:hover fieldset': {
          borderColor: `${COLORS.AB_TERTIARY} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${COLORS.AB_TERTIARY} !important`,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: COLORS.AB_TERTIARY,
      },
    },
  },
});

const DatePickerLightTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: COLORS.AB_BLUE_C1,
      },
    },
    MuiDialogActions: {
      root: {
        backgroundColor: COLORS.AB_BLUE_C1,
        '& button': {
          color: COLORS.AB_TERTIARY,
        },
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        color: COLORS.AB_GRAY_C6,
      },

      iconButton: {
        '&:hover': {
          backgroundColor: COLORS.AB_GRAY_C5,
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: COLORS.AB_GRAY_C6,
        '&:hover': {
          backgroundColor: COLORS.AB_PRIMARY_SHADE_2,
        },
      },
      dayDisabled: {
        color: COLORS.AB_GRAY_C5,
      },
      current: {
        color: COLORS.AB_PRIMARY_SHADE_2,
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: COLORS.AB_GRAY_C6,
      },
    },
    MuiPickersMonth: {
      root: {
        color: COLORS.AB_GRAY_C6,
        '&:hover': {
          color: COLORS.AB_GRAY_C6,
        },
      },
      monthSelected: {
        color: COLORS.AB_GRAY_C5,
        '&:hover': {
          color: COLORS.AB_GRAY_C6,
        },
      },
      current: {
        color: COLORS.AB_TERTIARY_SHADE_1,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: COLORS.AB_BLUE_C1,
      },
      dialog: {
        background: COLORS.AB_GRAY_C7,
      },
    },

    MuiTextField: {
      root: {
        '& label': {
          color: COLORS.AB_GRAY_C5,
          padding: '6px 6px 6px 6px',
          align: 'center',
        },
        '& label.Mui-disabled': {
          color: COLORS.AB_GRAY_C6,
        },
        '& label.Mui-focused': {
          color: COLORS.AB_GRAY_C6,
        },
        '& input': {
          height: '36px',
          align: 'center',
          color: COLORS.AB_GRAY_C6,
        },
        '& input.Mui-disabled': {
          color: COLORS.AB_GRAY_C7,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& fieldset': {
          borderColor: COLORS.AB_GRAY_C7,
        },
        '&:hover fieldset': {
          borderColor: `${COLORS.AB_GRAY_C7} !important`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${COLORS.AB_GRAY_C7} !important`,
        },
      },
    },
  },
});

export function DatePickerDark(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={DatePickerTheme}>
        <DatePicker
          autoOk
          disableFuture
          disableToolbar
          allowKeyboardControl={false}
          emptyLabel='dd/mm/yyyy'
          inputVariant='outlined'
          format='dd/MM/yyyy'
          placeholder='dd/mm/yyyy'
          openTo='year'
          views={['year', 'month', 'date']}
          {...props}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export function DatePickerLight(props) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={DatePickerLightTheme}>
        <KeyboardDatePicker
          disableFuture
          allowKeyboardControl={false}
          inputVariant='outlined'
          size='small'
          clearable
          fullWidth
          {...props}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}
export const formatBytes = (bytes, decimals = 2) => {
  const d = Math.floor(Math.log(bytes) / Math.log(1024));
  return bytes === 0
    ? '0 Bytes'
    : parseFloat((bytes / Math.pow(1024, d)).toFixed(Math.max(0, decimals))) +
        ' ' +
        ['Bytes', 'KB', 'MB', 'GB'][d];
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const returnCourseCamelCaseName = (course) => {
  let selectedCourse = '';
  switch (course) {
    case 'data-science':
      selectedCourse = 'dataScience';
      break;
    default:
      selectedCourse = course;
      break;
  }
  return selectedCourse;
};
