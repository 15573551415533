export const gigsoption = [
  {
    title: 'Mentor',
    value: 'mentor',
    desc: 'Coach learners to become the most employable talent available',
  },
  {
    title: 'Instructor',
    value: 'instructor',
    desc: 'Impart your knowledge to learners over live classes',
  },
  {
    title: 'Subject Matter Expert',
    value: 'subject_matter_expert',
    desc: 'Clear doubts, evaluate assignments with your expertise',
  },
  {
    title: 'Class Moderator',
    value: 'moderator',
    desc: 'Make the show a success.. “behind the curtains”',
  },
  {
    title: 'Technical Writer',
    value: 'writer',
    desc: 'Express your expertise by writing technical blogs, learning content',
  },
];
