import React from 'react';

import { CheckCircle } from '@material-ui/icons';
import { BOOKING_STAGE, BOOKING_STATUS } from '../../../constants';

const STAGE = {
  [BOOKING_STAGE.COACH_ASSIGNED]: 'Session Scheduled',
  [BOOKING_STAGE.SESSION_CONDUCTED]: 'Session Conducted',
  [BOOKING_STAGE.FEEDBACK_SUBMITTED]: 'Feedback Submitted By Student',
  [BOOKING_STAGE.ADMIN_ON_HOLD]: 'Approved By Admin',
  [BOOKING_STAGE.ADMIN_APPROVED]: 'Earnings Secured',
};

const SessionStatusBar = ({ booking }) => {
  const { status, stage } = booking;

  return (
    <div className='mt-4 py-4'>
      {/* step indicator icons */}
      <div className='flex justify-center items-center px-16'>
        {Object.keys(STAGE).map((key, i, array) => {
          const selectedStageIndex = Object.keys(STAGE).findIndex((x) => x === stage);
          const length = array.length;
          const renderStack = [];
          if (status === 'cancelled') {
            if (i < 1) renderStack.push(<DoneIcon key={`${key}-${i}`} />);
            else if (i === 1) renderStack.push(<PendingIconError key={`${key}-${i}`} n={2} />);
            else renderStack.push(<PendingIcon key={`${key}-${i}`} n={i + 1} />);
          } else {
            if (i <= selectedStageIndex) renderStack.push(<DoneIcon key={`${key}-${i}`} />);
            else renderStack.push(<PendingIcon key={`${key}-${i}`} n={i + 1} />);
          }

          if (i + 1 < length)
            renderStack.push(
              <span
                key={`${key}-${i}-connector`}
                className='w-1/5 ring-2 ring-ab-primary-shade-2 opacity-20 dark:bg-opacity-100'
              />
            );
          return renderStack;
        })}
      </div>

      {/* icon headings */}
      <div className='flex items-center text-sm text-center mt-2'>
        {Object.values(STAGE).map((value, index) => {
          if (status === BOOKING_STATUS.CANCELLED && index === 1)
            return (
              <span key={index} className='w-1/5 text-ab-secondary text-opacity-60'>
                {value}
              </span>
            );
          return (
            <span
              key={index}
              className='w-1/5 text-ab-primary dark:text-ab-tertiary text-opacity-30 dark:text-opacity-30'
            >
              {value}
            </span>
          );
        })}
      </div>
    </div>
  );
};

const DoneIcon = () => (
  <span className='z-10 w-6 h-6 m-0 p-0'>
    <CheckCircle style={{ color: '#76CE65', fontSize: '1.75rem' }} />
  </span>
);
const PendingIcon = ({ n }) => (
  <span className='ring ring-ab-primary dark:ring-ab-tertiary ring-opacity-20 dark:ring-opacity-20 text-ab-primary dark:text-ab-tertiary text-opacity-30 dark:text-opacity-30 bg-ab-tertiary dark:bg-ab-primary-shade-1 rounded-full h-6 w-6 py-1 flex items-center justify-center z-10'>
    {n}
  </span>
);

const PendingIconError = ({ n }) => (
  <span className='ring ring-ab-secondary text-ab-secondary bg-ab-tertiary dark:bg-ab-primary-shade-1 rounded-full h-6 w-6 py-1 flex items-center justify-center z-10'>
    {n}
  </span>
);

export default SessionStatusBar;
