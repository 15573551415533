import { createSlice } from '@reduxjs/toolkit';
import { getCoachSubTopics } from '../services/bookingApiService';
import { getCoachInfo, updateCoachInfo, getRolesForCoach } from '../services/userApiService';

const initialState = {
  isLoggedIn: false,
  loggedInUser: null,
  coachSubTopics: null,
  coachRoles: null,
  coachInfo: null,
  unVerifiedCoach: false,
};

const authSlice = createSlice({
  name: 'authReducer',
  initialState,
  reducers: {
    updateAuthStatus: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setLoggedInUser: (state, action) => {
      state.loggedInUser = action.payload;
    },
    setCoachSubTopics: (state, action) => {
      state.coachSubTopics = action.payload;
    },
    setCoachRoles: (state, action) => {
      state.coachRoles = action.payload;
    },
    setCoachInfo: (state, action) => {
      state.coachInfo = action.payload;
    },
    setUnverifiedCoach: (state, action) => {
      state.unVerifiedCoach = action.payload;
    },
  },
});

export const {
  updateAuthStatus,
  setLoggedInUser,
  setCoachSubTopics,
  setCoachRoles,
  setCoachInfo,
  setUnverifiedCoach,
} = authSlice.actions;

export function updateUser(userObj) {
  return async (dispatch) => {
    await updateCoachInfo(userObj);
    dispatch(fetchUserProfile());
  };
}

export function updateCoach(userObj) {
  return async (dispatch) => {
    await updateCoachInfo(userObj);
    dispatch(fetchCoachInfo());
  };
}

function getLatestCoachProfileInfo(user) {
  const verifiedProfileData = user.verified.profile;
  const unverifiedProfiledata = user?.unverified?.profile;
  if (unverifiedProfiledata) {
    for (const key in unverifiedProfiledata) {
      delete verifiedProfileData[key];
    }
    return { ...verifiedProfileData, ...unverifiedProfiledata };
  } else {
    return user.verified.profile;
  }
}

export function loadUser() {
  return async (dispatch) => {
    try {
      const user = await getCoachInfo();
      if (user) {
        const profile = getLatestCoachProfileInfo(user);
        const coachDetails = await getRolesForCoach(user.verified.profile.id);
        dispatch(setCoachRoles(coachDetails?.roles || null));
        dispatch(setLoggedInUser(profile));
        dispatch(setCoachInfo(user));
        const coach = Object.keys(user.verified).length === 1;
        dispatch(setUnverifiedCoach(coach));
      }
      dispatch(updateAuthStatus(true));
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchCoachInfo() {
  return async (dispatch) => {
    try {
      const user = await getCoachInfo();
      if (user) {
        dispatch(setCoachInfo(user));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchUserProfile() {
  return async (dispatch) => {
    try {
      const user = await getCoachInfo();
      if (user) {
        const profile = getLatestCoachProfileInfo(user);
        dispatch(setLoggedInUser(profile));
      }
    } catch (error) {
      console.error(error);
    }
  };
}

export const loggedInUserSelector = (state) => state.authReducer.loggedInUser;
export const coachInfoSelector = (state) => state.authReducer.coachInfo;
export const coachSubTopicsSelector = (state) => state.authReducer.coachSubTopics;

export const fetchCoachSubTopics = () => {
  return async (dispatch, getState) => {
    try {
      const coachSubTopics = coachSubTopicsSelector(getState());
      if (!coachSubTopics) dispatch(setCoachSubTopics(await getCoachSubTopics()));
    } catch (error) {
      console.error(error);
    }
  };
};

export default authSlice.reducer;
