import React, { useEffect, useState } from 'react';

import Storage from '@aws-amplify/storage';

const RenderImageQA = ({ imageKey }) => {
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (imageKey)
      Storage.get(imageKey)
        .then((url) => setUrl(url))
        .catch((err) => console.error(err));
  }, [imageKey]);

  return url ? (
    <a href={url} target='_blank' className='cursor-pointer inline-block' rel='noreferrer'>
      <img src={url} className='rounded-lg object-cover max-h-32' alt='' />
    </a>
  ) : (
    <></>
  );
};

export default RenderImageQA;
