import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

function Layout({ children }) {
  useEffect(() => {
    ReactPixel.pageView();
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return <div>{children}</div>;
}

export default withRouter(Layout);
