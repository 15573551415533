import React from 'react';
import { Link } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useSelector } from 'react-redux';
import quotesLeftImg from '../../static/svg/quotes-left.svg';
import quotesRightImg from '../../static/svg/quotes-right.svg';
import { DASHBOARD_CARDS } from './CoachDashboardCards';
import _ from 'lodash';

function CoachDashboard() {
  const coachRoles = useSelector((state) => state.authReducer.coachRoles);

  if (!coachRoles) {
    return (
      <div className='mx-5 md:mx-20 my-32 mt-48'>
        <div
          className='flex flex-col w-11/12 md:w-9/12 m-auto gap-2 relative p-4 rounded-2xl'
          style={{
            background: 'linear-gradient(91.02deg, #0C0454 -5.5%, #53194D 99.1%)',
          }}
        >
          <img
            className='h-4 md:h-5 absolute -top-1 -left-1'
            src={quotesLeftImg}
            alt='Quotes Left'
          />
          <span className='text-lg font-medium text-ab-tertiary text-center mt-1 whitespace-pre-wrap'>
            Seems like you have already submitted your interest in being a Coach at AlmaBetter.
            <br /> Please check with our helpline +91-9513166012 for further updates
          </span>
          <span className='self-center text-ab-tertiary text-opacity-60'>- Team AlmaBetter</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className='mx-5 md:mx-20 my-10'>
        <div
          className='flex flex-col w-11/12 md:w-9/12 m-auto gap-2 relative p-4 rounded-2xl'
          style={{
            background: 'linear-gradient(91.02deg, #0C0454 -5.5%, #53194D 99.1%)',
          }}
        >
          <img
            className='h-4 md:h-5 absolute -top-1 -left-1'
            src={quotesLeftImg}
            alt='Quotes Left'
          />
          <span className='text-lg font-medium text-ab-tertiary text-center mt-1 whitespace-pre-wrap'>
            We&apos;ve never seen your cape or mask, but we see your superpowers every day! {'\n'}
            Thanks for being an active part of the AlmaBetter Coach Community!!
          </span>
          <span className='self-center text-ab-tertiary text-opacity-60'>- Team AlmaBetter</span>
          <img
            className='h-4 md:h-5 absolute -bottom-1 -right-1'
            src={quotesRightImg}
            alt='Quotes Right'
          />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid-flow-row gap-4 md:gap-6 xl:gap-10 items-stretch pt-10 mb-6'>
          {DASHBOARD_CARDS.map((cardContent, index) => (
            <HomeApplicationCard key={index} {...cardContent} />
          ))}
        </div>
      </div>
    );
  }
}

export default CoachDashboard;

const HomeApplicationCard = ({
  roles,
  locked,
  external,
  to,
  cardImg,
  appName,
  appDescription,
  appLinkText,
  bgColor,
}) => {
  const coachRoles = useSelector((state) => state.authReducer.coachRoles);

  const cardContent = (
    <div className='p-6 flex flex-col gap-2 relative h-full'>
      <span className='flex items-center gap-2'>
        <h1 className='font-bold text-2xl'>{appName}</h1>
        {/* {locked && <img className='relative z-50' src={lockImg} alt='Lock' />} */}
      </span>
      <p>{appDescription}</p>
      <span className='flex items-center gap-1 text-blue-c1'>
        {appLinkText} <ArrowForwardIcon />
      </span>
      <img className='absolute bottom-0 right-0' src={cardImg} alt='Card Image' />
    </div>
  );

  if (roles && !_.intersectionWith(roles, coachRoles, _.isEqual).length) return null;

  return (
    <div className='relative rounded-2xl overflow-hidden select-none'>
      {locked && <div className='bg-white bg-opacity-40 w-full h-full absolute z-40' />}
      <div
        className={locked ? 'pointer-events-none' : 'filter-none'}
        style={{ background: bgColor }}
      >
        {external ? (
          <a href={to} target='_blank' rel='noreferrer'>
            {cardContent}
          </a>
        ) : (
          <Link to={to}>{cardContent}</Link>
        )}
      </div>
    </div>
  );
};
