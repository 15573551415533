import React from 'react';
import { useSelector } from 'react-redux';
import { ArrowBackIos } from '@material-ui/icons';

import polarisLogo from '../../../static/img/polaris-logo.png';
import RenderThumbnail from '../../../components/utils/RenderThumbnail';
import { Link } from 'react-router-dom';
import { PATH } from '../../../constants';

const InterviewDetailsHeader = ({ booking }) => {
  const colorTheme = useSelector((state) => state.colorModeReducer.colorTheme.palette.type);
  return (
    <div className='bg-ab-tertiary dark:bg-opacity-10 border border-solid border-ab-primary-shade-2 border-opacity-20 rounded-2xl relative p-4 shadow-lg'>
      <div className='flex justify-evenly gap-2 items-center'>
        <img src={polarisLogo} className='object-cover h-10 md:h-12' alt='logo' />
        <span className='bg-ab-primary-shade-2 dark:bg-ab-tertiary bg-opacity-20 dark:bg-opacity-30 w-0.5 h-16' />
        <span className='text-center'>
          <h2 className='text-xl font-medium capitalize'>{booking.themeName}</h2>
          <h6 className='opacity-60 capitalize mt-1'>{booking.subTopicName}</h6>
        </span>
        <span className='bg-ab-primary-shade-2 dark:bg-ab-tertiary bg-opacity-20 dark:bg-opacity-30 w-0.5 h-16' />
        <span>
          <div className='flex items-center gap-2 mt-1'>
            <RenderThumbnail
              thumbnail={booking.Student.thumbnail}
              name={booking.Student.firstName}
              size='3'
            />
            <span>
              <p>
                {booking.Student.firstName} {booking.Student.lastName}
              </p>
            </span>
          </div>
        </span>
      </div>

      <Link to={PATH.MENTORSHIP.MANAGE_BOOKINGS} className='absolute top-9 left-4'>
        <ArrowBackIos />
      </Link>
    </div>
  );
};

export default InterviewDetailsHeader;
