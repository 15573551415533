import { FormControl, MenuItem, Select } from '@material-ui/core';
import { GOAL_TYPE, GOAL_TYPE_NAMES_MAPPING } from '../../../constants';
import React from 'react';

export default function Filter({
  totalItems,
  currentNoOfItems,
  onFilterChange,
  currentFilterState,
}) {
  const onGoalTypeChange = (goalType) => {
    onFilterChange({ goalType });
  };

  const onStatusChange = (status) => {
    onFilterChange({ status });
  };

  return (
    <div
      className='bg-ab-tertiary shadow-lg rounded-lg overflow-hidden pb-4'
      style={{ width: '24rem', minWidth: '300px' }}
    >
      <div className='bg-blue-c5 w-full p-3'>
        <span className='font-medium'>FILTERS</span>
      </div>
      {/* info */}
      <span className='text-gray-500 ml-2'>
        Showing Results: {currentNoOfItems} / {totalItems}
      </span>

      {/* filters */}
      <div className='flex flex-col gap-2 p-3 w-full m-auto'>
        {/* course-type filter */}
        <span className='bg-white rounded capitalize flex flex-col gap-2'>
          <span className='font-medium'>Course Type</span>
          <FormControl variant='outlined' size='small' fullWidth>
            <Select
              onChange={({ target }) => onGoalTypeChange(target.value)}
              value={currentFilterState.goalType}
              labelId='courseType'
            >
              {Object.values(GOAL_TYPE).map((goal, index) => (
                <MenuItem value={goal} key={index}>
                  {GOAL_TYPE_NAMES_MAPPING[goal]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </span>

        {/* status filter */}
        <span className='bg-white rounded capitalize flex flex-col gap-2'>
          <span className='font-medium'>Status</span>
          <FormControl variant='outlined' size='small' fullWidth>
            <Select
              onChange={({ target }) => onStatusChange(target.value)}
              value={currentFilterState.status}
              labelId='status'
            >
              <MenuItem value='all'>
                <em>All</em>
              </MenuItem>
              <MenuItem value='pending'>
                <em>Pending</em>
              </MenuItem>
              <MenuItem value='completed'>
                <em>Completed</em>
              </MenuItem>
              <MenuItem value='rejected'>
                <em>Rejected</em>
              </MenuItem>
            </Select>
          </FormControl>
        </span>
      </div>
    </div>
  );
}
