import { Auth } from 'aws-amplify';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from '../../constants';

function Logout() {
  const history = useHistory();
  React.useEffect(() => {
    async function logOut() {
      try {
        await Auth.signOut();
        history.push(PATH.AUTH.LOGIN);
      } catch (e) {
        console.error(e);
      }
    }
    logOut();
  }, []);

  return <div />;
}

export default Logout;
