import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { LoadingCircle } from '../../utils/Icons';
import { useDispatch } from 'react-redux';
import { gigsoption } from './GigsOption';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { updateCoach } from '../../../redux/authSlice';

const Gigs = ({ gigs, setActiveOption, me }) => {
  const matches = useMediaQuery('(min-width:1024px)');
  const toastProps = {
    position: matches ? 'bottom-right' : '',
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: true,
  };
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    setLoading(true);
    const gigs = values.checked;
    if (me.id) {
      const coachOnboarding = true;
      const obj = {
        gigs,
        profile: {
          coachOnboarding,
        },
      };
      try {
        await dispatch(updateCoach(obj));
        toast.info('Profile Updated!', toastProps);
        setLoading(false);
        setModal(true);
      } catch (err) {
        toast.error('Failed To Update Profile! \n Please Try Again Later!!', toastProps);
      }
    }
    setActiveOption('end');
  };

  return (
    <div className='w-full md:mt-6 mt-3 flex flex-col mx-4 md:mx-0 '>
      {/* header & image input */}
      <div className=' items-center md:gap-4  md:mb-12 '>
        <p className=' text-black text-base md:mb-8 mb-4'>I want to be</p>
        <Formik
          enableReinitialize
          initialValues={{
            checked: gigs,
          }}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <div className=' grid grid-cols-12  justify-start md:mb-10'>
                <div className=' lg:col-span-9 col-span-12 xl:grid-cols-2 grid-col-1 grid  gap-3 md:gap-y-6  '>
                  {gigsoption.map((gig, index) => {
                    return (
                      <div
                        key={index}
                        className={`flex  flex-col  p-4 md:w-80  w-full  text-green-c1 border-solid rounded ${
                          Object.values(values.checked)?.filter((i) => i === gig.value).length > 0
                            ? 'border-2 border-blue-c1 '
                            : 'border-gray-c7 border'
                        }`}
                      >
                        <div className='flex space-x-4'>
                          <Field
                            type='checkbox'
                            className='h-5 w-5 flex-shrink-0'
                            name='checked'
                            value={gig.value}
                          />
                          <div className=' text-green-c1 font-semibold text-base -mt-1'>
                            {gig.title}
                            <p className=' text-sm text-gray-c6 font-normal '>{gig.desc}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <button
                type='submit'
                disabled={values.checked.length === 0}
                className={`disabled:opacity-40 disabled:cursor-not-allowed  md:mb-8 mb-4 md:w-72 w-11/12 bg-green-c1 text-white focus:outline-none font-medium  text-center rounded bottom-0 fixed py-4 -ml-1 md:-ml-0`}
              >
                {loading && <LoadingCircle iconStyle='w-6 mr-2' />}
                <span>SUBMIT FOR VERIFICATION</span>
              </button>
            </Form>
          )}
        </Formik>
      </div>

      {modal && (
        <div className=' inset-0 w-screen h-screen fixed flex'>
          <div className='w-full h-full bg-black opacity-50 fixed z-0' />
          <div className='m-auto bg-ab-tertiary dark:bg-ab-primary-shade-1 rounded-lg md:px-8 px-3 py-6 z-50 lg:w-1/2 w-11/12 '>
            <div
              className='
               flex flex-col items-center pb-4'
            >
              <p className=' text-black mb-5'>Thank you {me.firstName} !</p>
              <p className=' text-sm text-gray-c6 text-center'>
                We appreciate you expressing your interest in joining the coach community at
                AlmaBetter. Your profile details have been shared with the verification team.
                Verification will typically take 3-5 working days.
                <p className='mt-1'>
                  Please check with our helpline <b>+91-9513166012</b> for any updates.
                </p>
              </p>
            </div>
          </div>
        </div>
      )}
      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

export default Gigs;
