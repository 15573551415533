import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectIsConnectedToRoom,
  selectLocalPeer,
  selectRemotePeers,
  selectPeerScreenSharing,
  useHMSStore,
  useHMSActions,
  VideoTile,
  // selectRecordingState,
  // selectRTMPState,
} from '@100mslive/hms-video-react';
import { CircularProgress } from '@material-ui/core';

import Controls from './Controls';
import Peer from './Peer';
import { getFullName } from '../../../../utils';
import { SESSION_TYPE } from '../../../../constants';
import { loggedInUserSelector } from '../../../../redux/authSlice';
import { get100MSAppToken } from '../../../../services/bookingApiService';
import CustomPreview from './Preview';
import ProblemReportingPopup from '../../components/ProblemReportingPopup';
import InstructionsPopup from './InstructionsPopup';
import { BaseURl100msRecording } from '../../../../aws-exports';

const PROBLEM_MESSAGES = [
  'Student not responding',
  'Unable to submit feedback',
  'Question not relevant',
  'Screen not loading',
  'Unable to see video',
  'Chat not working',
  'Facing other technical issue in joining session',
];

const Conference = ({ booking, sessionType }) => {
  const [joinConfig, setJoinConfig] = useState(null);
  const [isPreview, setIsPreview] = useState(true);
  const [reportedBookingId, setReportedBookingId] = useState(null);
  const [showInstructions, setShowInstructions] = useState(false);

  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const localPeer = useHMSStore(selectLocalPeer);
  const remoteData = useHMSStore(selectRemotePeers);
  const [remotePeer] = remoteData.filter((item) => item.roleName !== 'bot');
  const screenSharingPeer = useHMSStore(selectPeerScreenSharing);
  // const recordingState = useHMSStore(selectRecordingState);
  // const rtmpState = useHMSStore(selectRTMPState);

  useEffect(async () => {
    if (isConnected) {
      const params = {
        meetingURL: `${BaseURl100msRecording}/preview/${booking.roomId100ms}/bot?skip_preview=true`,
        record: true,
        rtmpURLs: [],
      };

      try {
        await hmsActions.startRTMPOrRecording(params);
      } catch (err) {
        console.error('failed to start RTMP/recording', err);
      }
    }
  }, [isConnected]);

  const loggedInUser = useSelector(loggedInUserSelector);

  const joinOnClickHandler = () => {
    setIsPreview(false);
    hmsActions.join({
      userName: getFullName(loggedInUser),
      authToken: joinConfig.authToken,
      rememberDeviceSelection: true,
    });
  };

  useEffect(() => {
    const bookingId = booking?.id;
    if (bookingId) {
      get100MSAppToken({
        bookingId,
        userId: loggedInUser.id,
        role: 'mentor',
      })
        .then((resBody) => {
          const appToken = resBody.appToken;
          setJoinConfig({
            userName: getFullName(loggedInUser),
            authToken: appToken,
            metaData: 'mentor',
            rememberDeviceSelection: true,
          });
        })
        .catch((err) => {
          console.error(err);
          alert('Session can not be started. Team is working on this');
        });
    }

    return () => {
      if (isConnected || isPreview) hmsActions.leave();
    };
  }, []);

  if (sessionType === SESSION_TYPE.PREVIEW)
    return (
      <>
        <div className='m-auto text-xl font-medium'>
          <p>Video call will be available when you conduct the session</p>
          <p className='text-center mt-8'>
            <button
              onClick={() => setShowInstructions(true)}
              className='bg-blue-c1 text-ab-tertiary px-8 py-1 font-medium rounded-md'
            >
              Instructions
            </button>
          </p>
        </div>

        {showInstructions && (
          <InstructionsPopup
            subTopic={booking.SubTopic}
            closePopup={() => setShowInstructions(false)}
          />
        )}
      </>
    );

  if (!joinConfig)
    return (
      <span className='m-auto text-blue-c1 dark:text-ab-tertiary'>
        <CircularProgress color='inherit' />
      </span>
    );

  return (
    <>
      {isPreview && <CustomPreview config={joinConfig} joinOnClick={joinOnClickHandler} />}

      {isConnected && (
        <>
          <div
            id='video-call-container'
            className={
              'grid gap-2 p-2 items-center justify-center bg-ab-tertiary border border-solid border-ab-primary-shade-2 border-opacity-20 rounded-xl shadow-lg' +
              (screenSharingPeer && !screenSharingPeer.isLocal
                ? ' grid-cols-4 grid-rows-2'
                : ' grid-cols-2')
            }
          >
            <Peer peer={localPeer} />

            {screenSharingPeer && !screenSharingPeer.isLocal && (
              <div className='col-span-3 row-span-2 w-full h-full'>
                <VideoTile peer={screenSharingPeer} showScreen={true} objectFit='contain' />
              </div>
            )}

            {remotePeer && <Peer peer={remotePeer} />}

            <div className=' col-span-full'>
              <Controls isPreviewMode={false} />
            </div>
          </div>
        </>
      )}

      <div className='bg-ab-tertiary dark:bg-opacity-10 rounded-xl border border-solid border-ab-primary-shade-2 border-opacity-20 text-center p-2 shadow-lg'>
        <span className='ml-2'>Are you facing issues any issues?</span>
        <button
          onClick={() => setReportedBookingId(booking.id)}
          className='bg-blue-c1 text-ab-tertiary px-8 py-1 font-medium rounded-md ml-4'
        >
          Report a Problem
        </button>

        {booking.SubTopic?.instructionForCoach && (
          <>
            <span className='mx-8 border-l border-solid border-ab-primary-shade-2 border-opacity-30' />

            <button
              onClick={() => setShowInstructions(true)}
              className='bg-blue-c1 text-ab-tertiary px-8 py-1 font-medium rounded-md'
            >
              Instructions
            </button>
          </>
        )}
      </div>

      {!isPreview && !isConnected && (
        <div className='m-auto bg-white bg-opacity-10 border border-solid border-ab-primary-shade-2 border-opacity-20 text-center rounded-xl p-8 shadow-lg'>
          <p className='text-lg'>Video call is ended, would you like to restart it?</p>
          <button
            onClick={joinOnClickHandler}
            className='bg-blue-c1 dark:bg-ab-secondary text-ab-tertiary px-8 py-3 font-medium rounded-lg mt-6'
          >
            Restart Video Call
          </button>
        </div>
      )}

      {reportedBookingId && (
        <ProblemReportingPopup
          bookingId={reportedBookingId}
          closePopup={() => setReportedBookingId(false)}
          problemMessages={PROBLEM_MESSAGES}
        />
      )}

      {showInstructions && (
        <InstructionsPopup
          subTopic={booking.SubTopic}
          closePopup={() => setShowInstructions(false)}
        />
      )}
    </>
  );
};

export default Conference;
