import { createSlice } from '@reduxjs/toolkit';
import { getCoachingRoles, addNewCoachingRole } from '../services/userApiService';

const initialState = {
  coachingRoles: [],
};

const coachExperienceSlice = createSlice({
  name: 'coachExperienceReducer',
  initialState,
  reducers: {
    setCoahingRoles: (state, action) => {
      state.coachingRoles = action.payload;
    },
  },
});

export const { setCoahingRoles } = coachExperienceSlice.actions;

export function fetchCoachingRoles() {
  return async (dispatch) => {
    try {
      const coachingRoles = await getCoachingRoles();
      dispatch(setCoahingRoles(coachingRoles));
    } catch (error) {
      console.error(error);
    }
  };
}

export function addCoachingRolesAction(newRole) {
  return async (dispatch) => {
    try {
      const { id, name } = await addNewCoachingRole(newRole);
      dispatch(fetchCoachingRoles());
      return Promise.resolve({ id, name });
    } catch (error) {
      console.error(error);
    }
  };
}

export default coachExperienceSlice.reducer;
