import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { DATE_FORMAT_1 } from '../../constants';

import { Modal, Radio } from '@material-ui/core';

import Rating from '@material-ui/lab/Rating';
import RenderThumbnail from '../utils/RenderThumbnail';

export default function PreviousFeedbackModal({
  open,
  handleOpen,
  handleClose,
  item,
  previousCoachFeedbackObj,
}) {
  const [rating, setRating] = useState([]);
  const [plaigarism, setPlaigarism] = useState('');
  const [feedbackText, setFeedbackText] = useState('');

  const [isIterateThroughRatingObj, setIsIterateThroughRatingObj] = useState(false);

  const isEdit = false;

  function fetchRubricsData() {
    try {
      if (!item?.project?.attributes?.project_category?.data?.attributes?.rubrics) return;
      const allRubricsArr = JSON.parse(
        JSON.stringify(item?.project?.attributes?.project_category?.data?.attributes?.rubrics)
      );

      if (!isEdit) {
        setFeedbackText(previousCoachFeedbackObj?.coachFeedback?.feedback);

        const rubricsValue = previousCoachFeedbackObj?.coachFeedback?.ratings;

        for (let i = 0; i < allRubricsArr.length; i++) {
          const rubricItem = allRubricsArr[i];
          const rubricItemKey = rubricItem.rubricKey;
          if (rubricsValue && rubricsValue[rubricItemKey]) {
            rubricItem.value = rubricsValue[rubricItemKey];
          } else {
            setIsIterateThroughRatingObj(true);
            break;
          }
        }

        if (previousCoachFeedbackObj?.coachFeedback?.plagiarisedSolution) {
          setPlaigarism('plagiarised');
        } else {
          setPlaigarism('self_written');
        }
      }
      setRating(allRubricsArr);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchRubricsData();
  }, [isEdit]);

  return (
    <Modal
      open={open}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className='relative overflow-y-auto h-5/6 top-2 md:top-10 m-auto bg-ab-tertiary dark:bg-ab-primary-shade-1 rounded-lg md:px-8 px-3 py-4 z-50 lg:w-7/12 w-11/12 border-solid border border-gray-600'>
        <div className='absolute right-2 top-2 cursor-pointer' onClick={handleClose}>
          X
        </div>
        <div className='flex flex-col'>
          <div className='flex mb-4'>
            <RenderThumbnail
              name={item?.project?.attributes?.name}
              thumbnail={item?.project?.attributes?.icon?.data}
              size='3'
            />
            <div className='flex-auto'>
              <div className='mr-2 px-4 flex items-center gap-3'>
                <div className='flex-auto'>
                  <h3 className='font-medium'>{item?.project?.attributes?.name}</h3>

                  <span className='text-gray-600 text-opacity-80 text-sm'>
                    {item?.project?.attributes?.project_category?.data?.attributes?.name}
                  </span>
                </div>

                <div>
                  <div className='text-gray-500 text-sm'>
                    Submitted on{' '}
                    {dayjs(previousCoachFeedbackObj?.submittedAt).format(DATE_FORMAT_1)}
                  </div>
                  <div className='text-gray-500 text-sm'>
                    Evaluated on{' '}
                    {dayjs(previousCoachFeedbackObj?.evaluatedAt).format(DATE_FORMAT_1)}
                  </div>
                </div>
              </div>

              <div className='flex items-center px-4 mt-3 rounded w-max text-sm'>
                <div className='font-medium mr-5'>By</div>
                <RenderThumbnail
                  name={item?.student?.firstName}
                  thumbnail={item?.student?.thumbnail}
                  size='2'
                />
                <div className='ml-2'>{`${item?.student?.firstName} ${item?.student?.lastName}`}</div>
              </div>
            </div>
          </div>
          <hr className='mb-4' />
          <div className='font-bold'>Ratings & Feedback</div>

          {!isEdit && isIterateThroughRatingObj
            ? previousCoachFeedbackObj?.coachFeedback?.ratings &&
              Object.keys(previousCoachFeedbackObj?.coachFeedback?.ratings).map(
                (objectKey, index) => (
                  <div className='flex mb-2' key={index}>
                    <div className='w-64 text-gray-400 text-sm'>{objectKey}</div>
                    <Rating
                      max={10}
                      readOnly={true}
                      name={objectKey}
                      value={previousCoachFeedbackObj?.coachFeedback?.ratings[objectKey]}
                    />
                  </div>
                )
              )
            : rating.map((rubricItem, index) => (
                <div className='flex mb-2' key={index}>
                  <div className='w-64 text-gray-400 text-sm'>{rubricItem.rubricName}</div>
                  <Rating
                    max={10}
                    readOnly={!isEdit}
                    name={rubricItem.rubricName}
                    value={rubricItem.value}
                    onChange={(event, newValue) => {
                      setRating((ratingitem) => {
                        ratingitem[index].value = newValue;
                        return ratingitem;
                      });
                    }}
                  />
                </div>
              ))}

          <hr className='mt-4 mb-4' />

          <div className='text-gray-400 text-sm'>Plaigarism</div>
          <div className='flex items-center -mb-2 -ml-2'>
            <Radio
              disabled={!isEdit}
              color='primary'
              name='plaigarism'
              value='self_written'
              checked={plaigarism === 'self_written'}
            />
            <span className='text-sm font-normal opacity-60'>Self-written submission</span>
          </div>
          <div className='flex items-center -mb-2 -ml-2'>
            <Radio
              disabled={!isEdit}
              color='primary'
              name='plaigarism'
              value='plagiarised'
              checked={plaigarism === 'plagiarised'}
            />
            <span className='text-sm font-normal opacity-60'>Plagiarised submission</span>
          </div>

          <div className='text-sm text-gray-400 mt-4'>Feedback</div>
          <textarea
            disabled={!isEdit}
            rows='4'
            className='w-full border border-gray-300 focus:outline-none focus:border-blue-c1 focus:ring-1 focus:ring-blue-c1 rounded-lg p-2'
            value={feedbackText}
            onChange={(event) => {
              setFeedbackText(event.target.value);
            }}
          />
        </div>
      </div>
    </Modal>
  );
}
