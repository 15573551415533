import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Storage } from 'aws-amplify';
import { InputAdornment } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { v4 as uuidv4 } from 'uuid';
import { Formik, Form } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { IMAGE_FILE_SIZE_MAX } from '../../../constants';
import { EditIcon, LoadingCircle } from '../../utils/Icons';
import { updateUser } from '../../../redux/authSlice';
import awsConfig from '../../../aws-exports';
import RenderThumbnail from '../../utils/RenderThumbnail';
import {
  DatePickerLight,
  getFullName,
  getFirstAndLastNamesFromName,
  TextFieldLight,
} from '../../../utils';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import dayjs from 'dayjs';

const BasicInfo = ({ setActiveOption, me }) => {
  const matches = useMediaQuery('(min-width:1024px)');
  const toastProps = {
    position: matches ? 'bottom-right' : '',
    autoClose: 2000,
    closeOnClick: true,
    pauseOnHover: true,
  };

  const dispatch = useDispatch();
  const fileInput = useRef('fileInput');
  const [basicInfo, setBasicInfo] = useState({
    thumbnail: me.thumbnail,
  });
  const [formError, setFormError] = useState();
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showUrlError, setShowUrlError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableButton, setDisableButton] = useState();

  const validate = (values) => {
    setDisableButton(false);
    const { fullName, phone, dob, linkedInUrl, about } = values;
    const error = {};
    const showError = {};
    if (!fullName) {
      error.fullName = 'Name is Required';
    } else {
      const { firstName, lastName } = getFirstAndLastNamesFromName(fullName);
      if (!firstName || !lastName) {
        error.fullName = 'First Name and Last Name is Required';
      }
    }
    if (!phone) {
      error.phone = 'Mobile number is required with format +91XXXXXXXXX';
    } else if (
      !phone ||
      !isPossiblePhoneNumber(phone) ||
      (phone.match(/^\+91/) && phone.length !== 13)
    ) {
      showError.phone = 'Mobile number is invalid, required format +91XXXXXXXXX ';
    }
    if (!dob) {
      error.dob = 'DOB is Required';
    }
    if (!linkedInUrl) {
      error.linkedInUrl = 'Linkedin Url is required';
    } else if (!linkedInUrl.match(/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\//gm)) {
      showError.linkedInUrl = 'Linkedin Url is invalid';
    }
    if (!about) {
      error.about = 'About yourself is required';
    }
    setFormError(showError);
    return error;
  };

  const initialValues = {
    fullName: getFullName(me) || '',
    dob: me.dob || null,
    about: me.about || '',
    linkedInUrl: me.linkedInUrl || '',
    thumbnail: me.thumbnail,
    phone: me.phone || '',
  };

  const [otp] = useState({
    isEmailNotVerified: me?.isEmailNotVerified,
    isPhoneNotVerified: true,
    emailVerificationSent: true,
  });

  const handleImageUpload = () => {
    fileInput.current.click();
    fileInput.current.onchange = async ({ target }) => {
      try {
        const file = target.files[0];
        // if file selection is cancelled
        if (!file) return;

        if (file.size > IMAGE_FILE_SIZE_MAX) {
          setErrorMessage('Image file size should be less than 1 MB');
          return;
        }
        // upload file
        const res = await Storage.put(
          `user/${me.id}/image/${uuidv4()}.${file.type.split('/')[1]}`,
          file,
          {
            contentType: file.type,
          }
        );

        const { key } = res;
        if (key) {
          toast.info('Profile Picture Uploaded!', toastProps);

          // save uploaded file's key in db
          setBasicInfo({
            ...basicInfo,
            thumbnail: `https://${awsConfig.Storage.AWSS3.bucket}.s3.${awsConfig.Storage.AWSS3.region}.amazonaws.com/public/${key}`,
          });
          setErrorMessage(null);
        } else throw res;
      } catch (e) {
        setErrorMessage(null);
        toast.error('Image uploading failed,\n please try again later!', toastProps);
      }
    };
  };

  const handleSubmit = async (values) => {
    if (Object.keys(formError).length > 0) {
      formError?.linkedInUrl && setShowUrlError(true);
      formError?.phone && setShowPhoneError(true);
      setDisableButton(true);
    } else {
      setLoading(true);
      const { fullName, dob, about, linkedInUrl, phone } = values;
      const { firstName, lastName } = getFirstAndLastNamesFromName(fullName);
      const userObj = {
        profile: {
          firstName,
          lastName,
          dob,
          about,
          linkedInUrl,
          phone,
          ...basicInfo,
        },
      };
      try {
        await dispatch(updateUser(userObj));
        toast.info('Profile Updated!', toastProps);
      } catch (err) {
        toast.error('Failed To Update Profile! \n Please Try Again Later!!', toastProps);
      }
      setLoading(false);
      setActiveOption('B');
    }
  };

  if (!basicInfo)
    return (
      <div className='h-full flex'>
        <LoadingCircle iconStyle='w-16 mt-64 text-blue-500 m-auto' />
      </div>
    );

  return (
    <div className='w-full md:mt-6 flex flex-col md:mb-12 mx-4 md:mx-0'>
      {/* header & image input */}
      <div className='flex items-center md:gap-4 mb-8 md:mb-12'>
        <div className='relative'>
          <Thumbnail
            firstName={me.firstName}
            thumbnail={basicInfo.thumbnail}
            onEditButtonClicked={handleImageUpload}
          />
        </div>
        <div>
          <div className='cursor-pointer flex' onClick={handleImageUpload}>
            <span className='text-xl text-blue-c1 md:ml-0 ml-4'>
              {!basicInfo.thumbnail && '+ Upload a profile picture'}
            </span>
          </div>
        </div>
      </div>
      <input type='file' ref={fileInput} hidden accept='.jpg,.jpeg,.png' />
      {errorMessage && <div className='text-red-500 mb-8 -mt-8'>{errorMessage}</div>}

      {/* Form */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        validateOnMount
      >
        {(props) => (
          <Form
            className='flex flex-col gap-4 md:gap-8 md:mb-20 md:mr-20'
            onSubmit={props.handleSubmit}
          >
            <div className='flex flex-col md:grid grid-cols-2 gap-4 md:gap-8'>
              <TextFieldLight
                label='Full Name'
                name='fullName'
                variant='outlined'
                type='text'
                onChange={props.handleChange}
                value={props.values?.fullName}
                fullWidth
                required
                placeholder='Enter name'
              />

              <TextFieldLight
                label='Email ID'
                variant='outlined'
                type='email'
                name='email'
                value={me.email}
                InputProps={otp.isEmailNotVerified ? {} : TickIcon}
                disabled
                fullWidth
                placeholder='Enter email ID'
                required
                className=' bg-gray-c7'
              />
              <div>
                <TextFieldLight
                  label='Mobile Number'
                  variant='outlined'
                  type='text'
                  name='phone'
                  value={props.values.phone}
                  fullWidth
                  required
                  placeholder='Enter mobile number (+91XXXXXXXXXX)'
                  onChange={props.handleChange}
                  onBlur={(e) => {
                    props.handleBlur(e);
                    setShowPhoneError(true);
                  }}
                />
                {showPhoneError && (
                  <div className=' text-red-600 text-xs h-4 w-full mt-1'>{formError?.phone}</div>
                )}
              </div>

              <DatePickerLight
                name='dob'
                label='DOB'
                placeholder='Select date of birth'
                format='dd MMM yyyy'
                onChange={(value) => {
                  const date = dayjs(value, 'YYYY-MM-DD');
                  const dobFormat =
                    date.format('YYYY') + '-' + date.format('MM') + '-' + date.format('DD');
                  props.setFieldValue('dob', dobFormat);
                }}
                value={props.values.dob ? dayjs(props.values.dob).toDate() : null}
                InputProps={{ readOnly: true }}
                required
              />
            </div>

            <div>
              <TextFieldLight
                label='LinkedIN URL'
                name='linkedInUrl'
                variant='outlined'
                type='url'
                onChange={props.handleChange}
                value={props.values.linkedInUrl}
                fullWidth
                placeholder='Add linkedIN URL'
                required
                onBlur={(e) => {
                  props.handleBlur(e);
                  setShowUrlError(true);
                }}
              />
              {showUrlError && (
                <div className=' text-red-600 text-xs h-4 w-full mt-1'>
                  {formError?.linkedInUrl}
                </div>
              )}
            </div>

            <div className='mb-10'>
              <TextFieldLight
                multiline
                rows={4}
                rowsMax={6}
                label='About Yourself'
                name='about'
                variant='outlined'
                type='text'
                onChange={props.handleChange}
                value={props.values.about}
                fullWidth
                placeholder='Enter about yourself'
                required
                inputProps={{ maxLength: 300 }}
              />
            </div>

            <button
              disabled={!props.isValid || disableButton}
              type='submit'
              className={` disabled:opacity-40 disabled:cursor-not-allowed md:mb-8 mb-4  md:w-72 w-11/12 bg-green-c1 text-white focus:outline-none font-medium  text-center rounded bottom-0 fixed py-4 -ml-1 md:-ml-0
            `}
            >
              {loading && <LoadingCircle iconStyle='h-6 mr-2' />}
              NEXT
            </button>
          </Form>
        )}
      </Formik>

      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

const TickIcon = {
  endAdornment: (
    <InputAdornment>
      <CheckCircleIcon style={{ color: '#76CE65' }} />
    </InputAdornment>
  ),
};

function Thumbnail({ thumbnail, firstName, onEditButtonClicked }) {
  return (
    <>
      <RenderThumbnail thumbnail={thumbnail} name={firstName} size='5' />
      {thumbnail && (
        <span
          onClick={onEditButtonClicked}
          className='absolute bottom-1 right-1 bg-blue-500 px-2 py-0.5 rounded-full cursor-pointer'
        >
          <EditIcon iconStyle='w-3 inline text-white' />
        </span>
      )}
    </>
  );
}

export default BasicInfo;
