import React, { useState } from 'react';
import bgImage from './assets/forgot_pass_bg.png';
import rocketImage from './assets/rocket.png';
import { useHistory } from 'react-router-dom';
import { PATH } from '../../constants';
import { sendOTP, validateOTP } from '../../services/userApiService';
import { useDispatch, useSelector } from 'react-redux';
import { loggedInUserSelector, setLoggedInUser } from '../../redux/authSlice';
import { CircularProgress } from '@material-ui/core';
import logo from '../../static/img/almabetterLogo.png';

function EmailVerification() {
  const dispatch = useDispatch();
  const history = useHistory();
  const userDetails = useSelector(loggedInUserSelector);
  const [verificationCode, setVerificationCode] = useState('');
  const [resendDisable, setResendDisable] = useState(false);
  const [timer, setTimer] = useState(30);
  const [errorMsg, setErrorMsg] = useState('');
  const [validateOTPButtonLoading, setValidateOTPButtonLoading] = useState(false);
  const tickerInstance = React.useRef();

  if (timer === 0) {
    clearInterval(tickerInstance.current);
    setTimer(30);
    setResendDisable(false);
  }
  const ticker = () => {
    tickerInstance.current = setInterval(() => setTimer((prevState) => prevState - 1), 1000);
  };

  async function confirmSignUp() {
    setErrorMsg('');
    if (!verificationCode) {
      setErrorMsg('Verification Code is required');
      return;
    }
    try {
      setValidateOTPButtonLoading(true);
      const response = await validateOTP('email', verificationCode);
      if (response.success) {
        const userClone = { ...userDetails };
        delete userClone.isEmailNotVerified;
        dispatch(setLoggedInUser(userClone));
      } else {
        setErrorMsg(response.message);
      }
    } catch (error) {
      console.error(error);
      setErrorMsg('Error validating email otp');
    } finally {
      setValidateOTPButtonLoading(false);
    }
  }

  async function resendConfirmationCode() {
    try {
      setResendDisable(true);
      const response = await sendOTP('email', userDetails.email);
      if (response.success) {
        ticker();
      } else {
        setErrorMsg(response.message);
      }
    } catch (err) {
      console.error(err);
      setErrorMsg('Error sending verification code. Team is working on it.');
    }
  }

  return (
    <div className='h-screen max-h-screen overflow-hidden grid place-items-center'>
      <img className='h-full w-full absolute' src={bgImage} alt='Background' />
      <div className='bg-ab-tertiary w-full absolute top-0 left-0 z-20 flex items-center'>
        <img className='w-36 md:w-44 m-4' src={logo} alt='Logo' />
      </div>
      <div className='bg-ab-tertiary relative z-10 mx-4 md:mx-0 md:w-4/12 text-ab-primary text-center p-8 flex flex-col gap-4 rounded-lg pb-16'>
        <h1 className='font-semibold text-2xl'>Verification Code</h1>
        <h3 className='text-gray-500 font-medium'>A code has been sent to your email</h3>
        <div className='flex flex-col mb-6'>
          <input
            className='border border-solid border-gray-300 rounded h-12 my-2 p-2'
            type='text'
            placeholder='Enter verification code'
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <span
            className={`ml-auto text-sm text-blue-500 underline ${
              resendDisable ? 'hidden' : 'inline-block cursor-pointer'
            }`}
            onClick={resendDisable ? null : resendConfirmationCode}
          >
            Resend Verification Code
          </span>
          {errorMsg && (
            <span className='text-sm text-red-500 place-self-start text-left'>{errorMsg}</span>
          )}
          <h4 className={`text-xs mt-2 ${resendDisable ? 'block' : 'hidden'}`}>
            Wait another {timer} to send verification code again
          </h4>
        </div>
        <button
          disabled={validateOTPButtonLoading}
          className='px-4 py-3 bg-ab-secondary text-ab-tertiary font-medium rounded'
          onClick={confirmSignUp}
        >
          {validateOTPButtonLoading ? (
            <CircularProgress style={{ width: '1rem', height: '1rem', color: '#fff' }} />
          ) : (
            <>Submit</>
          )}
        </button>
        <h3>
          Not {userDetails.email}?{' '}
          <span
            className='text-sm text-blue-500 underline cursor-pointer'
            onClick={() => history.push(PATH.AUTH.LOGOUT)}
          >
            Sign out
          </span>
        </h3>
        <img
          className='hidden md:block md:w-40 xl:w-64 absolute bottom-0 left-0 transform -translate-x-1/2 translate-y-1/2'
          src={rocketImage}
          alt='Rocket'
        />
      </div>
    </div>
  );
}

export default EmailVerification;
