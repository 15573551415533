import React, { useState, useEffect } from 'react';
import { DeleteOutline, EditOutlined, Add } from '@material-ui/icons';
import { toast, ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { TextFieldLight, DatePickerLight, AutocompleteLight } from '../../../utils';
import { LoadingCircle } from '../../utils/Icons';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { addCompanyAction, addJobTitleAction } from '../../../redux/workExperienceSlice';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { updateCoach } from '../../../redux/authSlice';

const filter = createFilterOptions();

const WorkExperience = ({ setActiveOption, workExperience }) => {
  const matches = useMediaQuery('(min-width:1024px)');
  const [data, setData] = useState(workExperience);
  const [workExp, setWorkExp] = useState({});
  const [loading, setLoading] = useState(false);
  const [companyValue, setCompanyValue] = useState({});
  const [jobTitleValue, setJobTitleValue] = useState({});
  const [addWorkExp, setAddWorkExp] = useState(workExperience?.length === 0);
  const [editData, setEditData] = useState({});
  const [companyNameInput, setCompanyNameInput] = useState('');
  const [jobTitleInput, setJobTitleInput] = useState('');

  const getDuration = (startDate, endDate) => {
    const year = dayjs(endDate).diff(startDate, 'year');
    const months = dayjs(endDate).subtract(year, 'year').diff(startDate, 'month');

    return year + ' years ' + months + ' months ';
  };

  useEffect(() => {
    setData(workExperience);
  }, [workExperience]);

  const dispatch = useDispatch();
  const companies = useSelector(({ workExperienceReducer }) => workExperienceReducer.companies);
  const jobTitles = useSelector(({ workExperienceReducer }) => workExperienceReducer.jobTitles);

  const handleInput = (inputData) => setWorkExp({ ...workExp, ...inputData });

  const updateDetails = async (details) => {
    const workExperienceData = {
      workExperience: details,
    };
    try {
      await dispatch(updateCoach(workExperienceData));
      toast.info('Profile Updated!', {
        position: matches ? 'bottom-right' : '',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } catch (err) {
      toast.error('Failed To Update Profile! \n Please Try Again Later!!', {
        position: matches ? 'bottom-right' : '',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    setLoading(false);
  };

  const addDetails = async (e) => {
    e.preventDefault();
    let companyData = companyValue;
    let jobTitleData = jobTitleValue;
    setLoading(true);
    if (!companyValue.companyName || companyValue?.companyName !== companyNameInput) {
      const { id, name } = await dispatch(
        addCompanyAction(companyNameInput.charAt(0).toUpperCase() + companyNameInput.substring(1))
      );
      companyData = { companyId: id, companyName: name };
    }

    if (!jobTitleValue.jobTitleName || jobTitleValue?.jobTitleName !== jobTitleInput) {
      const { id, name } = await dispatch(
        addJobTitleAction(jobTitleInput.charAt(0).toUpperCase() + jobTitleInput.substring(1))
      );
      jobTitleData = { jobTitleId: id, jobTitleName: name };
    }

    const arr = {
      ...workExp,
      companyName: companyData.companyName,
      companyId: companyData.companyId,
      jobTitleName: jobTitleData.jobTitleName,
      jobTitleId: jobTitleData.jobTitleId,
    };
    setCompanyValue({});
    setJobTitleValue({});
    setCompanyNameInput('');
    setJobTitleInput('');
    setWorkExp({});
    await updateDetails([...data, arr]);
    setAddWorkExp(false);
    setEditData({});
  };

  const removeDetails = async (i) => {
    const details = [...data];
    details.splice(i, 1);
    await updateDetails(details);
    if (details.length === 0) {
      setAddWorkExp(true);
    }
    setWorkExp({});
  };

  const editDetails = (i) => {
    setAddWorkExp(true);
    const details = [...data];
    if (editData?.companyName) {
      const obj = {
        ...editData,
      };
      details.push(obj);
    }
    const [toEdit] = details.splice(i, 1);
    setData(details);
    setWorkExp(toEdit);
    setCompanyValue({
      companyName: toEdit.companyName,
      companyId: toEdit.companyId,
    });
    setJobTitleValue({
      jobTitleName: toEdit.jobTitleName,
      jobTitleId: toEdit.jobTitleId,
    });
    setEditData(toEdit);
  };

  return (
    <div className='w-full md:mt-6 flex flex-col mx-4 md:mx-0'>
      <div className='flex md:flex-row flex-col text-ab-primary dark:text-ab-tertiary lg:pr-40 md:pr-10 space-y-4 md:space-y-0'>
        <p
          className={` text-black whitespace-nowrap md:text-xl text-sm md:mb-4 ${
            data?.length > 0 ? 'hidden' : 'visible'
          }`}
        >
          Add your work experience
        </p>
        <div className={`md:w-1/4 w-full text-gray-c5 ${data?.length > 0 ? 'visible' : 'hidden'}`}>
          Experience
        </div>
        <div className='md:w-3/4 w-full '>
          {data?.length > 0 &&
            data?.map((item, index) => {
              return (
                <div className='mb-8 flex justify-between' key={index}>
                  <div>
                    <div className='font-semibold opacity-80'>{item.companyName}</div>
                    <div className='text-sm opacity-60 my-1'>{item.jobTitleName}</div>
                    <div className='text-sm'>
                      <span className='opacity-30'>
                        {dayjs(item.startDate).format('MMM YYYY')} -{' '}
                        {(item.endDate && dayjs(item.endDate).format('MMM YYYY')) || 'Present'} (
                        {getDuration(item.startDate, item.endDate)})
                      </span>
                    </div>
                  </div>

                  <div className='flex gap-2'>
                    <div onClick={() => editDetails(index)} className='cursor-pointer opacity-30'>
                      <EditOutlined color='inherit' />
                    </div>
                    <div onClick={() => removeDetails(index)} className='cursor-pointer opacity-30'>
                      <DeleteOutline color='inherit' />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* form */}
      <form onSubmit={addDetails} className='mt-8 md:pr-20'>
        {addWorkExp && (
          <div className='grid md:grid-cols-2 md:grid-rows-2 grid-col-1 gap-4 '>
            <AutocompleteLight
              freeSolo
              fullWidth
              autoComplete
              id='company-combo-box'
              inputValue={companyNameInput}
              onInputChange={(_, newValue) => setCompanyNameInput(newValue)}
              value={companyValue.companyName || ''}
              onChange={async (event, newValue) => {
                if (newValue) {
                  if (!newValue.inputValue) {
                    setCompanyValue({
                      companyId: newValue.id,
                      companyName: newValue.name,
                    });
                  } else setCompanyValue({});
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return filtered;
              }}
              options={companies}
              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.company;
                }
                // Regular option
                return option.name;
              }}
              loading={companies.length === 0}
              renderInput={(params) => (
                <TextFieldLight
                  {...params}
                  label='Name of Company'
                  name='company'
                  variant='outlined'
                  type='text'
                  value={workExp.company || ''}
                  required
                  autoFocus
                />
              )}
            />
            <AutocompleteLight
              freeSolo
              fullWidth
              autoComplete
              id='position-combo-box'
              inputValue={jobTitleInput}
              onInputChange={(_, newValue) => setJobTitleInput(newValue)}
              value={jobTitleValue.jobTitleName || ''}
              onChange={async (event, newValue) => {
                if (newValue) {
                  if (!(newValue && newValue.inputValue)) {
                    // Create a new value from the user input
                    setJobTitleValue({
                      jobTitleId: newValue.id,
                      jobTitleName: newValue.name,
                    });
                  } else setJobTitleValue({});
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                return filtered;
              }}
              options={jobTitles}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.jobTitle;
                }
                // Regular option
                return option.name;
              }}
              loading={jobTitles.length === 0}
              renderInput={(params) => (
                <TextFieldLight
                  {...params}
                  label='Job Title'
                  name='position'
                  variant='outlined'
                  type='text'
                  value={workExp.position || ''}
                  required
                />
              )}
            />
            <DatePickerLight
              required
              id='startYear'
              label='Start Date'
              name='startDate'
              placeholder=''
              emptyLabel=''
              format='MMM yyyy'
              views={['year', 'month']}
              value={workExp.startDate || null}
              InputProps={{ readOnly: true }}
              onChange={(newValue) => handleInput({ startDate: newValue })}
            />
            <DatePickerLight
              id='endYear'
              label='End Date'
              name='endDate'
              placeholder=''
              emptyLabel=''
              format='MMM yyyy'
              views={['year', 'month']}
              value={workExp.endDate || null}
              InputProps={{ readOnly: true }}
              onChange={(newValue) => handleInput({ endDate: newValue })}
              minDate={workExp.startDate}
              helperText='Leave empty if currently working'
            />
          </div>
        )}
        <div className='md:mb-8 mb-4'>
          <div className=' md:mt-5 flex space-x-8'>
            {!addWorkExp && (
              <button
                type='button'
                onClick={() => {
                  setAddWorkExp(true);
                }}
                className=' text-blue-c1 rounded justify-self-start font-medium text-left '
              >
                <Add /> Add Experience
              </button>
            )}

            {addWorkExp && data?.length > 0 && (
              <button
                type='button'
                className='text-gray-c5 rounded py-3 justify-self-start font-medium cursor-pointer'
                onClick={() => {
                  setAddWorkExp(false);
                  if (Object.keys(editData).length > 0) {
                    const details = [...data, editData];
                    setData(details);
                    setEditData({});
                  }
                  setCompanyValue({});
                  setJobTitleValue({});
                  setCompanyNameInput('');
                  setJobTitleInput('');
                  setWorkExp({});
                }}
              >
                Cancel
              </button>
            )}

            {addWorkExp && (
              <button
                type='submit'
                className='text-blue-c1 rounded py-3 justify-self-start font-medium cursor-pointer '
                disabled={loading}
              >
                {loading && <LoadingCircle iconStyle='w-6 mr-2' />}
                Save
              </button>
            )}
          </div>

          <div className=' bottom-0 fixed'>
            <button
              type='submit'
              onClick={() => {
                setActiveOption('E');
              }}
              disabled={data?.length === 0 || addWorkExp}
              className={` disabled:opacity-40 disabled:cursor-not-allowed mb-4 md:mb-8  md:w-72 w-11/12 bg-green-c1 text-white focus:outline-none font-medium  text-center rounded bottom-0 fixed py-4 -ml-1 md:-ml-0
            `}
            >
              NEXT
            </button>
          </div>
        </div>
        {/* submit button */}
      </form>

      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

export default WorkExperience;
