import RichTextEditor from '../../../../components/utils/RichTextEditor';

export default function InstructionsPopup({ subTopic, closePopup }) {
  return (
    <div className='fixed inset-0 bg-black bg-opacity-80 flex z-50'>
      <div className='m-auto bg-white p-6 rounded-lg max-w-screen-sm'>
        <h1 className='text-2xl font-medium text-center'>Instructions</h1>
        <p className='opacity-80 mt-4'>
          {subTopic?.instructionForCoach ? (
            <RichTextEditor value={subTopic.instructionForCoach} />
          ) : (
            'No instructions found for the session'
          )}
        </p>

        <div className='text-right mt-4'>
          <button onClick={closePopup} className='bg-green-c1 py-1 px-8 text-white rounded-md'>
            I Acknowledge
          </button>
        </div>
      </div>
    </div>
  );
}
