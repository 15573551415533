import React, { Fragment, useState } from 'react';
import { CalendarIcon } from '../utils/Icons';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import liveIcon from '../../static/img/live-icon.png';
import { Dialog, IconButton } from '@material-ui/core';
import RenderThumbnail from '../utils/RenderThumbnail';
import CloseIcon from '@material-ui/icons/Close';
import { DATE_FORMAT_1, DATE_FORMAT_2 } from '../../constants';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  summaryRoot: {
    padding: '0',
    justifyContent: 'flex-start',
  },
  summaryContent: {
    flexGrow: '0',
  },
  detailsRoot: {
    display: 'block',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0.025em',
  },
  tooltip: {
    color: '#000',
    backgroundColor: '#fff',
    fontSize: '16px',
    padding: '10px',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.2)',
    borderRadius: '8px',
  },
  tooltipArrow: {
    color: 'rgba(100,100,100, 0.6)',
  },
  dialog: {
    width: '70%',
    minWidth: '800px',
    maxHeight: '30rem',
    margin: 'auto',
    '& .MuiPaper-root': {
      width: '100%',
      minWidth: '800px',
    },
  },
}));

function ActivityCard({ cardData }) {
  const classes = useStyles();
  const userType = cardData.userType;
  const [ratingDialog, setRatingDialog] = useState(false);

  const badgeClass =
    cardData.sessionStatus === 'DONE'
      ? 'border border-solid border-green-c2 rounded px-2 py-1 text-sm rounded text-green-c2'
      : 'border border-solid border-yellow-c1 rounded px-2 py-1 text-sm rounded text-yellow-c1';

  const cardStatus = {
    DONE: 'Completed',
    UPCOMING: 'Scheduled',
  };

  const handleRatingDialog = () => {
    setRatingDialog((prev) => !prev);
  };

  return (
    <div className='w-full bg-white p-6 text-gray-600 rounded-lg shadow-md border border-solid border-gray-100'>
      {/* Top Section */}
      <section className='flex flex-col gap-2'>
        <div className='flex justify-between items-center'>
          <span className='flex items-center gap-6'>
            <h1 className='text-2xl font-semibold text-ab-primary'>{cardData.topic}</h1>
            {/* <span className='text-sm'>({cardData.cohortName})</span> */}
          </span>
          <div className='flex items-center gap-4'>
            {cardData.sessionStatus === 'UPCOMING' && (
              <img className='h-8' src={liveIcon} alt='Live' />
            )}
            <span className={badgeClass}>{cardStatus[cardData.sessionStatus]}</span>
          </div>
        </div>
        <div className='flex justify-between'>
          <span className='text-ab-primary text-opacity-40'>{cardData.type}</span>
          <span className='text-sm text-ab-primary text-opacity-40'>
            Session ID #{cardData.activityId}
          </span>
        </div>
        <h3 className='text-lg font-medium text-ab-primary text-opacity-60'>
          Module: {cardData.module}
        </h3>
        <div className='flex items-center gap-2'>
          <span className='text-gray-400'>Schedule</span>
          <span className='inline-flex items-center font-medium bg-blue-c5 p-2 rounded-md'>
            <CalendarIcon iconStyle='w-4 inline mr-2' />
            {dayjs(cardData.startTime).format(DATE_FORMAT_1)} -{' '}
            {dayjs(cardData.endTime).format('hh:mm A')}
          </span>
        </div>
      </section>
      <hr className='my-5' />
      <section className='flex flex-col gap-4 my-5'>
        <div className='flex flex-col md:flex-row md:items-center gap-2 md:gap-10'>
          <div className='mr-2 space-y-1 border border-solid border-ab-primary border-opacity-20 rounded-md px-4 py-2 flex items-center gap-3'>
            <RenderThumbnail
              name={cardData.instructorName}
              thumbnail={cardData.instructor?.thumbnail}
              size='3'
            />
            <div className='flex flex-col gap-2'>
              <h3 className='font-medium'>{cardData.instructorName}</h3>
              <span className='flex items-center gap-2'>
                <span className='bg-green-c5 text-green-c6 px-2 rounded text-sm'>Instructor</span>
                {/* <Tooltip
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  title={cardData.instructor?.phone || ''}
                >
                  <img src={phoneIcon} alt='Phone' />
                </Tooltip> */}
              </span>
            </div>
          </div>
          <div className='mr-2 space-y-1 border border-solid border-ab-primary border-opacity-20 rounded-md px-4 py-2 flex items-center gap-3'>
            <RenderThumbnail
              name={cardData.moderatorName}
              thumbnail={cardData.moderator?.thumbnail}
              size='3'
            />
            <div className='flex flex-col gap-2'>
              <h3 className='font-medium'>{cardData.moderatorName}</h3>
              <span className='flex items-center gap-2'>
                <span className='bg-ab-secondary-shade-1 bg-opacity-5 text-ab-secondary-shade-1 text-opacity-70 px-2 rounded text-sm'>
                  Moderator
                </span>
                {/* <Tooltip
                  arrow
                  classes={{
                    tooltip: classes.tooltip,
                    arrow: classes.tooltipArrow,
                  }}
                  title={cardData.moderator?.phone || ''}
                >
                  <img src={phoneIcon} alt='Phone' />
                </Tooltip> */}
              </span>
            </div>
          </div>
          {cardData?.discussUrl && (
            <div className='flex justify-end w-full'>
              <div className='flex '>
                <button
                  className='ml-auto px-6 py-2 bg-green-c1 text-ab-tertiary rounded font-medium pointer-cursor'
                  onClick={() => {
                    window.open(`${cardData?.discussUrl}`, '_blank');
                  }}
                >
                  Student Doubts
                </button>
              </div>
            </div>
          )}
        </div>
        {/* <div className='flex items-center gap-8 text-blue-c2 font-medium'>
          <a
            href={cardData.kahootUrl}
            target='_blank'
            rel='noreferrer'
            className={`flex items-center gap-2 ${
              cardData.sessionStatus === 'DONE' && 'opacity-50 pointer-events-none'
            }`}
          >
            <span className='bg-blue-c5 p-2 rounded'>
              <img src={kahootLogo} alt='Kahot' className='w-6' />
            </span>
            Start Kahoot
          </a>
          <a
            href={cardData.preparationpMaterialUrl}
            target='_blank'
            rel='noreferrer'
            className='flex items-center gap-2'
          >
            <span className='bg-blue-c5 p-2 rounded'>
              <img src={prepMaterial} alt='Pages' className='w-6' />
            </span>
            Preparation Material
          </a>
        </div> */}
      </section>
      <hr className='my-5' />
      <section className='flex items-center'>
        {/* {cardData[`${userType}FeedbackStatus`] === 'DONE' && (
          <span className='text-blue-c2 font-medium cursor-pointer' onClick={handleRatingDialog}>
            View Ratings and Feedback
          </span>
        )} */}
        <a
          href={cardData?.joiningLink}
          target='_blank'
          rel='noreferrer'
          className='ml-auto px-10 py-2 bg-green-c6 text-ab-tertiary rounded font-medium pointer-cursor'
        >
          Start Session
        </a>
      </section>
      <RatingDialog
        classes={classes}
        cardData={cardData}
        userType={userType}
        open={ratingDialog}
        onClose={handleRatingDialog}
      />
    </div>
  );
}

export default ActivityCard;

const RatingDialog = ({ classes, cardData, userType, open, onClose }) => {
  const [section, setSection] = useState('ratings');
  const complimentUserType = userType === 'moderator' ? 'instructor' : 'moderator';
  const [activeOption, setActiveOption] = useState('Students');
  const activeClass =
    'p-3 text-ab-secondary text font-medium cursor-pointer border-b-4 border-solid border-ab-secondary rounded';
  const inactiveClass =
    'p-3 text font-medium cursor-pointer border-b-4 border-solid border-transparent rounded';

  const activeSectionClass = 'bg-ab-secondary text-ab-tertiary';

  const handleSectionChange = (value) => {
    setSection(value);
  };

  return (
    <Dialog className={classes.dialog} open={open} onClose={onClose} maxWidth='80vw'>
      {/* Main Switcher */}
      <div className='flex item-center gap-2 w-full border border-solid border-gray-300 shadow-lg px-4 relative'>
        <span
          className={activeOption === 'Students' ? activeClass : inactiveClass}
          onClick={() => setActiveOption('Students')}
        >
          Students
        </span>
        <span
          className={activeOption === 'userType' ? activeClass : inactiveClass}
          onClick={() => setActiveOption('userType')}
        >
          {complimentUserType.charAt(0).toUpperCase() + complimentUserType.slice(1)}
        </span>
        <span>
          <IconButton
            size='small'
            onClick={onClose}
            style={{ position: 'absolute', right: '10px', top: '10px' }}
          >
            <CloseIcon />
          </IconButton>
        </span>
      </div>
      {/* Section Switcher */}
      <div className='flex justify-evenly shadow-md m-auto mt-6 mb-4'>
        <span
          className={`px-10 py-2 cursor-pointer rounded-l-lg ${
            section === 'ratings' && activeSectionClass
          }`}
          onClick={() => handleSectionChange('ratings')}
        >
          Ratings
        </span>
        <span
          className={`px-10 py-2 cursor-pointer rounded-r-lg ${
            section === 'feedback' && activeSectionClass
          }`}
          onClick={() => handleSectionChange('feedback')}
        >
          Feedback
        </span>
      </div>
      {activeOption === 'Students' ? (
        <div className='py-4 px-8'>
          {section === 'ratings' && (
            <div className='grid grid-cols-4 gap-x-20 gap-y-2 mb-5'>
              {cardData?.feedbacks?.studentFeedback
                ? Object.values(cardData?.feedbacks?.studentFeedback).map((item) => {
                    if (typeof item === 'object' && item !== null) {
                      return Object.keys(item).map((key, i) => {
                        if (key !== 'remarks') {
                          return (
                            <Fragment key={i}>
                              <h3 className='text-gray-600 col-span-3'>{key}</h3>
                              <Rating
                                className='col-span-1'
                                name='read-only'
                                value={item[key]}
                                readOnly
                              />
                            </Fragment>
                          );
                        }
                        return null;
                      });
                    }
                    return null;
                  })
                : null}
            </div>
          )}
          {section === 'feedback' && (
            <div className='mb-5'>
              {cardData?.feedbacks?.studentFeedback
                ? Object.values(cardData.feedbacks.studentFeedback).map((item) => {
                    if (typeof item === 'object' && item !== null) {
                      return Object.keys(item).map((key, i) => {
                        if (key === 'remarks') {
                          if (item[key] === '') return null;
                          return (
                            <Fragment key={i}>
                              <h3 className='font-medium inline mr-10'>
                                {cardData[`${complimentUserType}Name`]}
                              </h3>
                              <span className='text-sm text-gray-600'>
                                {dayjs(
                                  cardData?.feedbacks[`${complimentUserType}Feedback`].createdAt
                                ).format(DATE_FORMAT_2)}
                              </span>
                              <h3 className='text-gray-600 col-span-3'>{item[key]}</h3>
                            </Fragment>
                          );
                        }
                        return null;
                      });
                    }
                    return (
                      <div key={item}>
                        <h3 className='font-medium'>No feedback available</h3>
                      </div>
                    );
                  })
                : null}
            </div>
          )}
        </div>
      ) : (
        <div className='py-4 px-8'>
          {section === 'ratings' && (
            <div className='grid grid-cols-4 gap-x-20 gap-y-2 mb-5'>
              {cardData?.feedbacks[`${complimentUserType}Feedback`]
                ? Object.values(cardData.feedbacks[`${complimentUserType}Feedback`]).map((item) => {
                    if (typeof item === 'object' && item !== null) {
                      return Object.keys(item).map((key, i) => {
                        if (key !== 'remarks') {
                          return (
                            <Fragment key={i}>
                              <h3 className='text-gray-600 col-span-3'>{key}</h3>
                              <Rating
                                className='col-span-1'
                                name='read-only'
                                value={item[key]}
                                readOnly
                              />
                            </Fragment>
                          );
                        }
                        return null;
                      });
                    }
                    return null;
                  })
                : null}
            </div>
          )}
          {section === 'feedback' && (
            <div className='mb-5'>
              {cardData?.feedbacks[`${complimentUserType}Feedback`]
                ? Object.values(cardData.feedbacks[`${complimentUserType}Feedback`]).map((item) => {
                    if (typeof item === 'object' && item !== null) {
                      return Object.keys(item).map((key, i) => {
                        if (key === 'remarks') {
                          if (item[key] === '') return null;
                          return (
                            <Fragment key={i}>
                              <h3 className='font-medium inline mr-10'>
                                {cardData[`${complimentUserType}Name`]}
                              </h3>
                              <span className='text-sm text-gray-600'>
                                {dayjs(
                                  cardData.feedbacks[`${complimentUserType}Feedback`].createdAt
                                ).format(DATE_FORMAT_2)}
                              </span>
                              <h3 className='text-gray-600 col-span-3'>{item[key]}</h3>
                            </Fragment>
                          );
                        }
                        return null;
                      });
                    }
                    return (
                      <div key={item}>
                        <h3 className='font-medium'>No feedback available</h3>
                      </div>
                    );
                  })
                : null}
            </div>
          )}
        </div>
      )}
    </Dialog>
  );
};
