import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import googleLogo from '../../static/img/google_logo.png';
import loginAstronaut from './assets/login_astro.png';
import PasswordStrengthBar from 'react-password-strength-bar';
import { Auth } from 'aws-amplify';
import { validateEmail } from '../../utils';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CircularProgress } from '@material-ui/core';
import { ALMABETTER_HOME_LINKS, PATH } from '../../constants';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import logo from '../../static/img/almabetterLogo.png';

export default function SignUp() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState({});
  const [disable, setDisable] = useState(false);

  async function signUp() {
    setDisable(true);
    try {
      await Auth.signUp({
        username: email,
        password,
      });
      await Auth.signIn(email, password);
      history.push(PATH.AUTH.LOGIN_SUCCESSFUL);
    } catch (error) {
      setError(error);
    }
    setDisable(false);
  }

  const validate = () => {
    const temp = {};
    temp.email = email
      ? validateEmail(email)
        ? ''
        : 'Please enter a valid email'
      : 'This field is required';
    temp.password = password ? (error.password ? error.password : '') : 'This field is required';
    temp.terms = terms ? '' : 'Please accept the terms before proceeding';

    if (!Object.values(temp).every((value) => value === '')) setError(temp);
    else signUp();
  };

  const validatePassword = (score, feedback) => {
    if (password && score >= 0 && score <= 2) {
      setError({
        ...error,
        password: feedback.warning ? feedback.warning : 'Please select a better password',
      });
    } else setError({ ...error, password: '' });
  };

  return (
    <div className=' login w-full min-h-screen bg-pink-c1 md:bg-ab-tertiary-shade-2 text-black'>
      <div className='container mx-auto p-6 md:p-10 md:py-2 grid md:grid-cols-12'>
        <div className='hidden md:block md:col-span-6 md:p-10'>
          <img src={loginAstronaut} alt='Login Astronaut' />
        </div>
        <div className='md:m-10 p-6 md:p-10 md:col-span-6 bg-ab-tertiary flex flex-col w-full lg:w-auto'>
          <h1 className='hidden md:block font-semibold text-3xl text-center mb-6'>
            Welcome to AlmaBetter
          </h1>
          <img className='w-44 m-auto mb-8 block md:hidden' src={logo} alt='Logo' />
          <button
            className='w-5/6 mx-auto border border-solid border-gray-100 rounded py-3 shadow-md'
            onClick={() =>
              Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
            }
          >
            <span className='flex items-center justify-center text-center w-full gap-4'>
              <img className='w-6' src={googleLogo} alt='Google Logo' />
              Sign up with Google
            </span>
          </button>
          <div className='flex w-full items-center my-4'>
            <hr className='border border-solid border-gray-400 w-full' />
            <span className='mx-10'>or</span>
            <hr className='border border-solid border-gray-400 w-full' />
          </div>
          <div className='flex flex-col gap-y-3'>
            {error.message && (
              <span className='text-sm text-red-500 flex items-center gap-1'>
                <ErrorOutlineIcon /> {error.message}
              </span>
            )}
            <div className='flex flex-col'>
              <label htmlFor='email'>Your Email</label>
              <input
                className='border border-solid border-gray-300 rounded h-10 p-2'
                type='text'
                id='email'
                placeholder='Email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className='text-sm text-red-500'>{error.email}</span>
            </div>
            <div className='flex flex-col'>
              <label htmlFor='password'>Choose Password</label>
              <input
                className='border border-solid border-gray-300 rounded h-10 p-2'
                type='password'
                id='password'
                placeholder='Password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <PasswordStrengthBar password={password} onChangeScore={validatePassword} />
              <span className='text-sm text-red-500'>{error.password}</span>
            </div>
          </div>
          <div className='flex items-center gap-2 mt-16 text-sm flex-wrap'>
            <input
              type='checkbox'
              id='termsAndConditions'
              checked={terms}
              onChange={() => setTerms((prevState) => !prevState)}
            />
            <label htmlFor='termsAndConditions'>
              I agree to{' '}
              <a
                href={ALMABETTER_HOME_LINKS.TERM_AND_CONDITIONS}
                className='text-blue-c2 underline'
              >
                terms and conditions
              </a>
            </label>
            {!terms && <span className='2xl:w-full text-sm text-red-500'>{error.terms}</span>}
          </div>
          <button
            className='bg-ab-primary text-ab-tertiary w-full py-3 rounded my-4 font-medium'
            onClick={validate}
          >
            {disable ? (
              <CircularProgress style={{ width: '1rem', height: '1rem', color: '#fff' }} />
            ) : (
              'Sign Up for free'
            )}
          </button>
          <h3 className='text-sm w-full text-center'>
            Do you already have an account?{' '}
            <Link to={PATH.AUTH.LOGIN} className='text-blue-c2 underline text-base font-medium'>
              Log in
            </Link>
          </h3>
        </div>
      </div>
    </div>
  );
}
