import classNames from 'classnames';

export default function CommonButton({ className, disabled, ...props }) {
  const defaultClasses = `${disabled ? '' : 'transform hover:scale-105 transition duration-300'}`;
  const combinedClasses = classNames(defaultClasses, className);
  return (
    <button className={combinedClasses} {...props}>
      {props.children}
    </button>
  );
}
