import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { Checkbox, Radio, ThemeProvider } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import dayjs from 'dayjs';
import { LoadingCircle } from '../../components/utils/Icons';
import { BOOKING_STAGE, BOOKING_STATUS } from '../../constants';

const STAGE_OPTIONS = {
  Scheduled: BOOKING_STAGE.COACH_ASSIGNED,
  Conducted: BOOKING_STAGE.SESSION_CONDUCTED,
  Completed: BOOKING_STAGE.ADMIN_APPROVED,
  Cancelled: BOOKING_STAGE.CANCELLED,
};

const STATUS_OPTIONS = {
  Defaulted: BOOKING_STATUS.DEFAULTED,
};

const SideBar = ({
  themes,
  filterOptions,
  setFilterOptions,
  openMode,
  sortOptions,
  setSortOptions,
  bookingCount,
}) => {
  const handleCheckbox = ({ target }) => {
    const newFilter = [...filterOptions[target.name]];
    if (target.checked) newFilter.push(target.value);
    else newFilter.splice(newFilter.indexOf(target.value), 1);
    setFilterOptions({ ...filterOptions, [target.name]: newFilter });
  };

  const handleChange = (e) => {
    setSortOptions({ ...sortOptions, [e.target.name]: e.target.value });
  };

  return (
    <div
      className='w-1/6 sticky top-4 self-start overflow-y-auto rounded-lg'
      style={{ maxHeight: '88vh' }}
    >
      <div className='bg-ab-tertiary dark:bg-ab-primary-shade-1 border border-solid border-gray-300 dark:border-ab-primary-shade-2 rounded-lg overflow-hidden'>
        <div className='bg-blue-c4 dark:bg-ab-tertiary dark:bg-opacity-10 text-lg text-ab-primary dark:text-ab-tertiary text-opacity-60 dark:text-opacity-60 px-4 py-2'>
          FILTER
        </div>
        <div className='flex flex-col gap-4 p-4 text-ab-primary dark:text-ab-tertiary'>
          {!openMode && (
            <>
              <div>
                <div className='font-medium opacity-80'>Status</div>

                {Object.entries(STAGE_OPTIONS).map(([key, value]) => (
                  <div key={key} className='flex items-center gap-1 -mb-2 -ml-2'>
                    <Checkbox
                      onChange={handleCheckbox}
                      checked={filterOptions?.stage?.includes(value)}
                      name='stage'
                      value={value}
                      id={key}
                      color='primary'
                    />
                    <label
                      htmlFor={key}
                      className='capitalize cursor-pointer font-normal opacity-60'
                    >
                      {key} ({bookingCount[value] || 0})
                    </label>
                  </div>
                ))}

                {Object.entries(STATUS_OPTIONS).map(([key, value]) => (
                  <div key={key} className='flex items-center gap-1 -mb-2 -ml-2'>
                    <Checkbox
                      onChange={handleCheckbox}
                      checked={filterOptions?.status?.includes(value)}
                      name='status'
                      value={value}
                      id={key}
                      color='primary'
                    />
                    <label
                      htmlFor={key}
                      className='capitalize cursor-pointer font-normal opacity-60'
                    >
                      {key} ({bookingCount[value] || 0})
                    </label>
                  </div>
                ))}
              </div>

              <hr className='border-t border-solid border-gray-300 dark:border-ab-primary-shade-2 -mx-4' />
            </>
          )}

          <div>
            <div className='font-medium opacity-80'>Session Type</div>
            {themes.length ? (
              themes.map(({ id, name }) => (
                <div key={id} className='flex items-center -ml-2'>
                  <Checkbox
                    onChange={handleCheckbox}
                    checked={filterOptions?.theme.includes(id)}
                    name='theme'
                    value={id}
                    id={id}
                    color='primary'
                  />
                  <label
                    htmlFor={id}
                    className='capitalize cursor-pointer leading-tight font-normal opacity-60'
                  >
                    {name}
                  </label>
                </div>
              ))
            ) : (
              <LoadingCircle iconStyle='h-8 w-8 m-4' />
            )}
          </div>

          <hr className='border-t border-solid border-gray-300 dark:border-ab-primary-shade-2 -mx-4' />

          <div>
            <div className='font-medium opacity-80'>Specific Date Range</div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider>
                <div className='flex flex-col gap-4 mt-4'>
                  <KeyboardDatePicker
                    onChange={(fromDate) =>
                      setFilterOptions({
                        ...filterOptions,
                        fromDate: fromDate && fromDate.toISOString().substring(0, 10),
                      })
                    }
                    value={filterOptions.fromDate || null}
                    format='dd/MM/yyyy'
                    label='From'
                    inputVariant='outlined'
                    size='small'
                    clearable
                    maxDate={
                      filterOptions.toDate
                        ? dayjs(filterOptions.toDate).subtract(1, 'day')
                        : undefined
                    }
                  />
                  <KeyboardDatePicker
                    onChange={(toDate) =>
                      setFilterOptions({
                        ...filterOptions,
                        toDate: toDate && toDate.toISOString().substring(0, 10),
                      })
                    }
                    value={filterOptions.toDate || null}
                    format='dd/MM/yyyy'
                    label='To'
                    inputVariant='outlined'
                    size='small'
                    clearable
                    minDate={
                      filterOptions.fromDate
                        ? dayjs(filterOptions.fromDate).add(1, 'day')
                        : undefined
                    }
                  />
                </div>
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>

      <div className='bg-ab-tertiary dark:bg-ab-primary-shade-1 border border-solid border-gray-300 dark:border-ab-primary-shade-2 rounded-lg overflow-hidden mt-6'>
        <div className='bg-blue-c4 dark:bg-ab-tertiary dark:bg-opacity-10 text-lg text-ab-primary dark:text-ab-tertiary text-opacity-60 dark:text-opacity-60 px-4 py-2'>
          SORT
        </div>
        <div className='flex flex-col gap-4 p-4 text-ab-primary dark:text-ab-tertiary'>
          <div>
            <div className='font-medium opacity-80'>Sort Earnings</div>

            <div className='flex items-center -mb-2 -ml-2'>
              <Radio
                color='primary'
                name='sortBy'
                value='high-to-low'
                onChange={handleChange}
                checked={sortOptions.sortBy === 'high-to-low'}
              />
              <span className='text-sm font-normal opacity-60'>High to Low</span>
            </div>

            <div className='flex items-center -mb-2 -ml-2'>
              <Radio
                color='primary'
                name='sortBy'
                value='low-to-high'
                onChange={handleChange}
                checked={sortOptions.sortBy === 'low-to-high'}
              />
              <span className='text-sm font-normal opacity-60'>Low to High</span>
            </div>
          </div>

          <hr className='border-t border-solid border-gray-300 dark:border-ab-primary-shade-2 -mx-4' />

          <div>
            <div className='font-medium opacity-80'>Sort By Dates</div>

            <div className='flex items-center -mb-2 -ml-2'>
              <Radio
                color='primary'
                name='sortBy'
                value='near-dates'
                onChange={handleChange}
                checked={sortOptions.sortBy === 'near-dates'}
              />
              <span className='text-sm font-normal opacity-60'>Latest First</span>
            </div>

            <div className='flex items-center -mb-2 -ml-2'>
              <Radio
                color='primary'
                name='sortBy'
                value='far-dates'
                onChange={handleChange}
                checked={sortOptions.sortBy === 'far-dates'}
              />
              <span className='text-sm font-normal opacity-60'>Earliest First</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
