import { API } from 'aws-amplify';
import { PROJECT_EVALUATION_ENDPOINT_NAME } from '../aws-exports';

export const getAlmaXAssignmentList = async ({
  goalType,
  evaluationStatus,
  beforeSubmittedAt,
  limit,
}) => {
  return await API.post(PROJECT_EVALUATION_ENDPOINT_NAME, '/api/assignment/evaluations', {
    body: { goalType, evaluationStatus, beforeSubmittedAt, limit },
  });
};

export const submitAlmaXAssignmentEvaluation = async (payload) => {
  return await API.post(PROJECT_EVALUATION_ENDPOINT_NAME, '/api/assignment/evaluate-assignment', {
    body: payload,
  });
};
