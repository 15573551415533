import React, { useEffect, useState } from 'react';
import { ArrowBack, Cancel, GetApp, LinkedIn, PlayArrowRounded } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { getDetailedBooking, getBookingRecordings } from '../../services/bookingApiService';
import RenderThumbnail from '../../components/utils/RenderThumbnail';
import { formatBytes } from '../../utils';
import { DATE_FORMAT_1 } from '../../constants';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

const WatchRecording = (props) => {
  const [booking, setBooking] = useState(props.location.state?.booking || null);
  const [recordings, setRecordings] = useState(null);
  const [playRecording, setPlayRecording] = useState(null);

  const { bookingId } = props.match.params;

  useEffect(() => {
    const loadBookingDetailsAndRecordings = async () => {
      try {
        const bookingDetails = booking || (await getDetailedBooking(bookingId));
        setBooking(bookingDetails);
        setRecordings(await getBookingRecordings(bookingDetails.roomId100ms));
      } catch (err) {
        console.error(err);
        toast.error('Failed to fetch details, please try again later!');
      }
    };

    loadBookingDetailsAndRecordings();
  }, []);

  return (
    <div className='relative'>
      <h1 className='text-3xl text-center font-semibold tracking-wide'>Session Recordings</h1>

      <button onClick={() => props.history.goBack()} className='absolute left-20 top-2'>
        <ArrowBack />
      </button>

      {booking ? (
        <div className='container mx-auto my-8 px-4'>
          {/* Booking Info */}
          <div className='flex items-center gap-16'>
            <h2 className='text-2xl font-medium capitalize'>{booking.themeName}</h2>
            <span className='opacity-60 text-sm'>
              Conducted At: {dayjs(booking.conductedAt).format(DATE_FORMAT_1)}
            </span>
            <span className='ml-auto opacity-30 text-sm'>Booking ID: {booking.id}</span>
          </div>
          <h3 className='capitalize mt-2'>{booking.subTopicName}</h3>

          <div className='flex mt-6'>
            {/* Candidate Details */}
            <div>
              <div className='text-sm opacity-60 mb-1'>Candidate</div>
              <UserDetails user={booking.Student} />
            </div>

            <span className='mx-10' />

            {/* Coach Details */}
            <div>
              <div className='text-sm opacity-60 mb-1'>Coach</div>
              <UserDetails user={booking.Coach} />
            </div>
          </div>
        </div>
      ) : (
        <div className='flex items-center justify-center text-blue-c1 dark:text-ab-tertiary py-16'>
          <CircularProgress color='inherit' />
        </div>
      )}

      <div className='min-h-screen bg-gray-c1 dark:bg-white dark:bg-opacity-10 py-4'>
        {recordings ? (
          <>
            <h2 className='text-center font-semibold text-3xl mt-4 mb-8'>
              {recordings.length} Recordings Available
            </h2>
            {recordings.map((recording, index) => (
              <div
                className='bg-ab-tertiary dark:bg-opacity-10 rounded-lg max-w-lg flex gap-4 mx-auto my-4 p-4'
                key={index}
              >
                <span
                  onClick={() => setPlayRecording(recording)}
                  className='bg-green-c1 dark:bg-ab-tertiary text-ab-tertiary dark:text-ab-primary-shade-2 rounded-full flex items-center justify-center h-12 w-12 cursor-pointer'
                >
                  <PlayArrowRounded color='inherit' fontSize='large' />
                </span>

                <div>
                  <div className='font-medium'>Recording {index + 1}</div>
                  <div className='text-sm opacity-60 mt-1'>
                    {dayjs(recording.LastModified).format(DATE_FORMAT_1)}
                  </div>
                </div>

                <button
                  onClick={() => window.open(recording.url, '_blank')}
                  className='ml-auto my-auto text-blue-c1'
                >
                  <GetApp color='inherit' fontSize='large' />
                  <span className='ml-1 text-sm'>{formatBytes(recording.Size)}</span>
                </button>
              </div>
            ))}
          </>
        ) : (
          <div className='flex items-center justify-center text-blue-c1 dark:text-ab-tertiary py-16'>
            <CircularProgress color='inherit' />
          </div>
        )}
      </div>

      {playRecording && (
        <div className='fixed inset-0 flex flex-col items-center justify-center gap-4 bg-ab-primary bg-opacity-80'>
          <video
            src={playRecording.url}
            type='video/mp4'
            height='720'
            width='1280'
            className='focus:outline-none ring-1 ring-ab-tertiary ring-opacity-40 rounded-lg'
            autoPlay
            controls
          />
          <button
            onClick={() => setPlayRecording(null)}
            className='flex items-center gap-1 ring-1 ring-ab-tertiary ring-opacity-40 text-ab-tertiary rounded-full px-2 py-1.5'
          >
            <Cancel />
            <span>Close</span>
          </button>
        </div>
      )}
    </div>
  );
};

const UserDetails = ({ user }) => (
  <span className='flex items-center gap-2'>
    <RenderThumbnail thumbnail={user.thumbnail} name={user.firstName} size={3} />
    <span>
      {user.firstName} {user.lastName}
    </span>
    {user.linkedInUrl && (
      <a href={user.linkedInUrl} target='_blank' rel='noopener noreferrer'>
        <LinkedIn htmlColor='#0275B4' />
      </a>
    )}
  </span>
);

export default WatchRecording;
