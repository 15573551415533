import {
  COACH_ROLES,
  COMMUNITY,
  DOUBT_RESOLUTION,
  PATH,
  THINKIFIC_ALMABETTER_LINKS,
  GROW_PLATFORM_LINK,
} from '../../constants';
import mentorshipImg from '../../static/svg/round-table.svg';
import almaxChampImg from '../../static/svg/almax-champion.svg';
import instructorshipImg from '../../static/svg/teachings.svg';
import resourcesImg from '../../static/svg/resources.svg';
import communityImg from '../../static/svg/unity.svg';
import doubtResolutionImg from '../../static/svg/raise-hands.svg';
import evaluationsImg from '../../static/svg/clipboard.svg';
import careerCoachImg from '../../static/svg/partner.svg';
import moderatorImg from '../../static/svg/moderator.svg';

export const DASHBOARD_CARDS = [
  {
    roles: [COACH_ROLES.MENTOR, COACH_ROLES.CAREER_COACH],
    to: PATH.MENTORSHIP.HOME,
    appName: 'Mentorship',
    appDescription: 'Coach your Mentees to crack interviews.',
    appLinkText: 'Accept sessions',
    cardImg: mentorshipImg,
    bgColor: 'rgba(255, 247, 247, 1)',
  },
  // {
  //   roles: [COACH_ROLES.MENTOR, COACH_ROLES.CAREER_COACH],
  //   to: PATH.INTERVIEW_ROSTER,
  //   appName: 'Legacy Mentorship',
  //   appDescription: 'Coach your Mentees to crack interviews.',
  //   appLinkText: 'Accept sessions',
  //   cardImg: mentorshipImg,
  //   bgColor: 'rgba(255, 247, 247, 1)',
  // },
  {
    roles: [COACH_ROLES.INSTRUCTOR],
    to: PATH.CALENDAR_ACTIVITY('instructor'),
    cardImg: instructorshipImg,
    appName: 'Instructorship',
    appDescription: 'Teach students through Live Classes.',
    appLinkText: 'View Classes',
    bgColor: 'rgba(245, 253, 255, 1)',
  },
  {
    external: true,
    to: GROW_PLATFORM_LINK,
    cardImg: resourcesImg,
    appName: 'My Resources',
    appDescription: 'Access your learning resources here.',
    appLinkText: 'Open',
    bgColor: 'rgba(118, 206, 101, 0.08)',
  },
  {
    external: true,
    to: COMMUNITY,
    cardImg: communityImg,
    appName: 'Community',
    appDescription: 'Connect with your peers and all learners.',
    appLinkText: 'Make connections',
    bgColor: 'rgba(245, 253, 255, 1)',
  },
  {
    roles: [COACH_ROLES.PROJECT_EVALUATOR],
    to: PATH.PROJECT_EVALUATIONS_DASHBOARD,
    cardImg: evaluationsImg,
    appName: 'Project Evaluations',
    appDescription: 'Score Project to asses students.',
    appLinkText: 'Evaluate',
    bgColor: 'rgba(254, 215, 102, 0.08)',
  },
  {
    roles: [COACH_ROLES.EVALUATOR],
    to: PATH.ALMAX_ASSIGNMENT_EVALUATIONS_DASHBOARD,
    cardImg: evaluationsImg,
    appName: 'Assignment Evaluations',
    appDescription: 'Score Project to asses students.',
    appLinkText: 'Evaluate',
    bgColor: 'rgba(254, 215, 102, 0.08)',
  },
  {
    roles: [COACH_ROLES.SUBJECT_MATTER_EXPERT],
    external: true,
    to: DOUBT_RESOLUTION,
    cardImg: doubtResolutionImg,
    appName: 'Doubt Resolution',
    appDescription: 'Clear doubts and spread your knowledge.',
    appLinkText: 'Ask a doubt',
    bgColor: 'rgba(154, 59, 130, 0.08)',
  },
  {
    roles: [COACH_ROLES.CAREER_COACH],
    external: true,
    to: 'https://almabetter.notion.site/Published-for-Career-Coaches-e6bf74365719467fa0534a067681f6be',
    cardImg: careerCoachImg,
    appName: 'Career Coach',
    appDescription: 'Help your students get a job.',
    appLinkText: 'View details',
    bgColor: 'rgba(244, 251, 243, 1)',
  },
  {
    roles: [COACH_ROLES.MODERATOR],
    to: PATH.CALENDAR_ACTIVITY('moderator'),
    cardImg: moderatorImg,
    appName: 'Moderator',
    appDescription: 'View your Scheduled Sessions.',
    appLinkText: 'Join live sessions',
    bgColor: 'rgba(236, 161, 105, 0.08)',
  },

  {
    external: true,
    to: 'https://almabetter.notion.site/AlmaX-Champion-Data-Science-2a0dedeb068d4d59bfecc976a9e92cf3',
    cardImg: almaxChampImg,
    appName: 'AlmaX Champion',
    appDescription: `Mentor AlmaBetter's top 1% students.`,
    appLinkText: 'View details',
    bgColor: 'rgba(240, 255, 252, 1)',
  },
];
