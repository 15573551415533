module.exports = {
  AB_GREEN_C1: '#134F5C',
  AB_GREEN_C2: '#47C71A',
  AB_GREEN_C3: '#76CE65',
  AB_GREEN_C4: '#1B252F',
  AB_GREEN_C5: '#EBFAF4',
  AB_GREEN_C6: '#359D91',
  AB_GRAY_C1: '#E5E5E5',
  AB_GRAY_C2: '#7B91B1',
  AB_GRAY_C3: '#D2DDE8',
  AB_GRAY_C4: '#F8F8FA',
  AB_BLUE_C1: '#148EFF',
  AB_BLUE_C2: '#114ADC',
  AB_BLUE_C3: '#4388FF',
  AB_BLUE_C4: '#E1ECF0',
  AB_BLUE_C5: '#EBF2FC',
  AB_YELLOW_C1: '#FDA623',
  AB_PINK_C1: '#FDB5B5',
  AB_PURPLE_C1: '#9A3B82',
  AB_PRIMARY: '#000',
  AB_PRIMARY_SHADE_1: '#1C1C25',
  AB_PRIMARY_SHADE_2: '#333333',
  AB_SECONDARY: '#F00037',
  AB_SECONDARY_SHADE_1: '#F00037',
  AB_SECONDARY_SHADE_2: '#F00037',
  AB_SECONDARY_SHADE_3: '#ff4b55',
  AB_TERTIARY: '#fff',
  AB_TERTIARY_SHADE_1: '#ffffffb3',
  AB_TERTIARY_SHADE_2: '#E5E5E5',
  AB_GRAY_C5: '#0000004d',
  AB_GRAY_C6: '#00000099',
  AB_GRAY_C7: '#0000001a',
};
