import React, { useState } from 'react';

import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { toast, ToastContainer } from 'react-toastify';

import { LoadingCircle } from '../../utils/Icons';
import { resetPassword } from '../../../services/authService';
import CommonButton from '../../common/CommonButton';
import COLORS from '../../../globals/COLORS';

const Password = ({ editMode }) => {
  const [passwordData, setPasswordData] = useState({});
  const [showPassword, setShowPassword] = useState({});
  const [loading, setLoading] = useState({});

  const handleResetPasswordInput = ({ target }) =>
    setPasswordData({ ...passwordData, [target.name]: target.value });

  const handleResetPasswordSubmit = async (e) => {
    e.preventDefault();

    setLoading({ pwd: true });

    try {
      await resetPassword(passwordData);
      toast.info('Password Updated!', toastProps);
    } catch ({ code, message }) {
      if (code === 'NotAuthorizedException') {
        toast.error('Incorrect Old Password!', toastProps);
      } else {
        toast.error(message, toastProps);
      }
    }

    setLoading({ pwd: false });
  };

  return (
    <div className='w-full max-w-4xl mt-16'>
      {editMode && (
        <>
          <h1 className='text-2xl font-semibold'>Reset Password</h1>
          <form
            onSubmit={handleResetPasswordSubmit}
            className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-8'
          >
            <TextField
              label='Old Password'
              variant='outlined'
              type={showPassword.oldPassword ? 'text' : 'password'}
              name='oldPassword'
              onChange={handleResetPasswordInput}
              fullWidth
              required
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      style={{ color: COLORS.AB_TERTIARY }}
                      aria-label='toggle password visibility'
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          oldPassword: !showPassword.oldPassword,
                        })
                      }
                      edge='end'
                    >
                      {showPassword.oldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label='New Password'
              variant='outlined'
              type={showPassword.newPassword ? 'text' : 'password'}
              name='newPassword'
              onChange={handleResetPasswordInput}
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      style={{ color: COLORS.AB_TERTIARY }}
                      aria-label='toggle password visibility'
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          newPassword: !showPassword.newPassword,
                        })
                      }
                      edge='end'
                    >
                      {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CommonButton
              type='submit'
              disabled={loading.pwd}
              className='bg-green-c1 dark:bg-ab-secondary text-white font-medium rounded focus:outline-none py-4 mt-4'
            >
              {loading.pwd && <LoadingCircle iconStyle='w-6 mr-2' />}
              UPDATE PASSWORD
            </CommonButton>
          </form>
        </>
      )}

      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

const toastProps = {
  position: 'bottom-right',
  autoClose: 3000,
  closeOnClick: true,
  pauseOnHover: true,
};

export default Password;
