import { Auth } from 'aws-amplify';
import { getDomain, PATH } from './constants';

export const USER_API_GATEWAY_ENDPOINT_NAME = 'User-API-Gateway-Endpoint';
export const USER_API_GATEWAY_AUTH_FREE_ENDPOINT_NAME = 'User-API-Gateway-Auth-Free-Endpoint';
export const PROJECT_EVALUATION_ENDPOINT_NAME = 'PROJECT_EVALUATION_ENDPOINT';

export const PROJECT_EVALUATION_ENDPOINT =
  process.env.REACT_APP_COURSE_LMS_API_GATEWAY_ENDPOINT ||
  'https://6c7jn568a0.execute-api.ap-south-1.amazonaws.com';

const USER_API_GATEWAY_ENDPOINT =
  process.env.REACT_APP_USER_API_GATEWAY_ENDPOINT ||
  'https://c3yd5bh60c.execute-api.ap-south-1.amazonaws.com';

const DOMAIN = getDomain() || 'http://localhost:3000';
const AWS_REGION = process.env.REACT_APP_AWS_REGION || 'ap-south-1';
const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID || 'ap-south-1_2xDr5HmfJ';
const USER_POOL_WEB_CLIENT_ID =
  process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || '715oetv9o2f4jugf6to7u87r0r';
const USER_POOL_OAUTH_DOMAIN =
  process.env.REACT_APP_USER_POOL_OAUTH_DOMAIN ||
  'id-dev-almabetter.auth.ap-south-1.amazoncognito.com';
const ENV = process.env.REACT_APP_ENV || 'dev';
const IDENTITY_POOL_ID =
  process.env.REACT_APP_IDENTITY_POOL_ID || 'ap-south-1:5ef56f79-cca8-4903-a118-94aa6caab07b';
const config = {
  Auth: {
    identityPoolId: IDENTITY_POOL_ID,
    region: AWS_REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: USER_POOL_OAUTH_DOMAIN,
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${DOMAIN}${PATH.AUTH.LOGIN_SUCCESSFUL}`,
      redirectSignOut: `${DOMAIN}${PATH.AUTH.LOGOUT_SUCCESSFUL}`,
      responseType: 'code',
    },
  },
  API: {
    endpoints: [
      {
        name: USER_API_GATEWAY_ENDPOINT_NAME,
        endpoint: USER_API_GATEWAY_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          };
        },
      },

      {
        name: PROJECT_EVALUATION_ENDPOINT_NAME,
        endpoint: PROJECT_EVALUATION_ENDPOINT,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
          };
        },
      },

      {
        name: USER_API_GATEWAY_AUTH_FREE_ENDPOINT_NAME,
        endpoint: USER_API_GATEWAY_ENDPOINT,
      },
    ],
  },
  Storage: {
    AWSS3: {
      bucket: `ab-user-files-${ENV}`,
      region: AWS_REGION,
    },
  },
};
export const BaseURl100msRecording =
  process.env.REACT_APP_HMS_BASE_URL || 'https://ab-mentorship-dev.app.100ms.live';

export default config;
