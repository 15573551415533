import { Auth } from 'aws-amplify';

import { setLoggedInUser, updateAuthStatus } from '../redux/authSlice';

export const logInUser = async (dispatch, { email, password }) => {
  try {
    await Auth.signIn(email, password);
    dispatch(updateAuthStatus(true));
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const signUpUser = async (dispatch, { name, email, password, phone }) => {
  try {
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        name,
        email,
        phone_number: `+91${phone}`,
      },
    });

    logInUser(dispatch, { email, password });
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const logOutUser = async (dispatch) => {
  try {
    await Auth.signOut();
    dispatch(updateAuthStatus(false));
    dispatch(setLoggedInUser(null));
  } catch (e) {
    console.error(e);
  }
};

export const checkIfLoggedIn = async (dispatch) => {
  try {
    await Auth.currentAuthenticatedUser();
    dispatch(updateAuthStatus(true));
  } catch (e) {
    console.error(e);
  }
};

export const resetPassword = async ({ oldPassword, newPassword }) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(user, oldPassword, newPassword);
  } catch (e) {
    console.error(e);
    throw e;
  }
};
