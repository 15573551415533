import React, { useState } from 'react';
import { Close, SettingsOutlined } from '@material-ui/icons';
import {
  selectDevices,
  selectLocalMediaSettings,
  useHMSStore,
  useHMSActions,
} from '@100mslive/hms-video-react';
import { MenuItem, TextField } from '@material-ui/core';

const Settings = () => {
  const hmsActions = useHMSActions();
  const devices = useHMSStore(selectDevices);
  const selectedDevices = useHMSStore(selectLocalMediaSettings);

  const [popup, setPopup] = useState(false);

  return (
    <div>
      <button
        onClick={() => setPopup(true)}
        className='bg-white bg-opacity-10 border border-solid border-ab-primary-shade-2 border-opacity-20 rounded-full p-2 focus:outline-none'
      >
        <SettingsOutlined className='text-ab-primary dark:text-ab-tertiary text-opacity-60' />
      </button>

      {/* settings popup */}
      {popup && (
        <div className='fixed w-screen h-screen inset-0 bg-ab-primary bg-opacity-50 z-50 flex'>
          <div className='m-auto bg-ab-tertiary dark:bg-ab-primary-shade-1 rounded-lg p-8'>
            <header className='pb-4 border-b border-solid border-ab-primary-shade-2 border-opacity-20 dark:border-opacity-100'>
              <h1 className='text-2xl font-semibold tracking-wide inline-block'>Settings</h1>

              {/* popup close button */}
              <Close
                onClick={() => setPopup(false)}
                color='inherit'
                className='float-right cursor-pointer'
              />
            </header>

            <section className='grid grid-cols-4 gap-4 mt-8'>
              <label htmlFor='audio-output' className='font-medium mr-4'>
                Speaker
              </label>
              <TextField
                select
                onChange={(e) => hmsActions.setAudioOutputDevice(e.target.value)}
                value={selectedDevices.audioOutputDeviceId}
                className='col-span-3'
                id='audio-output'
                variant='outlined'
                size='small'
              >
                {devices.audioOutput.map((deviceInfo) => (
                  <MenuItem
                    value={deviceInfo.deviceId}
                    key={deviceInfo.deviceId}
                    className='bg-ab-primary-shade-1'
                  >
                    {deviceInfo.label}
                  </MenuItem>
                ))}
              </TextField>

              <label htmlFor='audio-input' className='font-medium mr-4'>
                Microphone
              </label>
              <TextField
                select
                onChange={(e) => hmsActions.setAudioSettings({ deviceId: e.target.value })}
                value={selectedDevices.audioInputDeviceId}
                className='col-span-3'
                id='audio-input'
                variant='outlined'
                size='small'
              >
                {devices.audioInput.map((deviceInfo) => (
                  <MenuItem
                    value={deviceInfo.deviceId}
                    key={deviceInfo.deviceId}
                    className='bg-ab-primary-shade-1'
                  >
                    {deviceInfo.label}
                  </MenuItem>
                ))}
              </TextField>

              <label htmlFor='video-input' className='font-medium mr-4'>
                Camera
              </label>
              <TextField
                select
                onChange={(e) => hmsActions.setVideoSettings({ deviceId: e.target.value })}
                value={selectedDevices.videoInputDeviceId}
                className='col-span-3'
                id='video-input'
                variant='outlined'
                size='small'
              >
                {devices.videoInput.map((deviceInfo) => (
                  <MenuItem
                    value={deviceInfo.deviceId}
                    key={deviceInfo.deviceId}
                    className='bg-ab-primary-shade-1'
                  >
                    {deviceInfo.label}
                  </MenuItem>
                ))}
              </TextField>
            </section>
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
