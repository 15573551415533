export const HourGlassIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 10 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 0V5.4H0.00833352L0 5.409L3.33333 9L0 12.6L0.00833352 12.609H0V18H10V12.609H9.99167L10 12.6L6.66667 9L10 5.409L9.99167 5.4H10V0H0ZM8.33333 13.05V16.2H1.66667V13.05L5 9.45L8.33333 13.05ZM5 8.55L1.66667 4.95V1.8H8.33333V4.95L5 8.55Z'
      fill='currentColor'
    />
  </svg>
);

export const CalendarIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.4 1.63636H13.6V0H12V1.63636H4V0H2.4V1.63636H1.6C0.72 1.63636 0 2.37273 0 3.27273V16.3636C0 17.2636 0.72 18 1.6 18H14.4C15.28 18 16 17.2636 16 16.3636V3.27273C16 2.37273 15.28 1.63636 14.4 1.63636ZM14.4 16.3636H1.6V7.36364H14.4V16.3636ZM14.4 5.72727H1.6V3.27273H14.4V5.72727Z'
      fill='currentColor'
    />
  </svg>
);

export const CurrencyIcon = ({ iconStyle }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={iconStyle}
    fill='none'
    viewBox='0 0 24 24'
    stroke='currentColor'
    strokeWidth={2}
  >
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z'
    />
  </svg>
);

export const BackArrowIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M18 5H3.83L7.41 1.41L6 0L0 6L6 12L7.41 10.59L3.83 7H18V5Z' fill='currentColor' />
  </svg>
);

export const FilterIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M7 12H11V10H7V12ZM0 0V2H18V0H0ZM3 7H15V5H3V7Z' fill='currentColor' />
  </svg>
);

export const RemoveIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M4 7.2V8.8H12V7.2H4ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4Z'
      fill='currentColor'
    />
  </svg>
);

export const ReverseTriangle = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 0L5 5L10 0L0 0Z' fill='currentColor' />
  </svg>
);

export const Triangle = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10 5L5 0L0 5L10 5Z' fill='currentColor' />
  </svg>
);

export const Cross = ({ iconStyle }) => (
  <svg
    className={iconStyle}
    fill='currentColor'
    viewBox='0 0 20 20'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
      clipRule='evenodd'
    />
  </svg>
);

export const HangUp = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx={24} cy={24} r={24} fill='currentColor' />
    <path
      d='M24 21c-1.6 0-3.15.25-4.6.72v3.1c0 .39-.23.74-.56.9-.98.49-1.87 1.12-2.66 1.85-.18.18-.43.28-.7.28-.28 0-.53-.11-.71-.29l-2.48-2.48a.956.956 0 01-.29-.7c0-.28.11-.53.29-.71C15.34 20.78 19.46 19 24 19c4.54 0 8.66 1.78 11.71 4.67.18.18.29.43.29.71 0 .28-.11.53-.29.71l-2.48 2.48c-.18.18-.43.29-.71.29-.27 0-.52-.11-.7-.28a11.27 11.27 0 00-2.67-1.85.996.996 0 01-.56-.9v-3.1C27.15 21.25 25.6 21 24 21z'
      fill='#fff'
    />
  </svg>
);
export const Mic = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='50%' cy='50%' r='50%' fill='#fff' opacity='0.5' />
    <svg
      className={iconStyle}
      viewBox='0 0 1024 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <svg
        className={iconStyle}
        viewBox='0 0 320 512'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill='currentColor'
          d='M160 352c53.02 0 96-42.98 96-96V96c0-53.02-42.98-96-96-96S64 42.98 64 96v160c0 53.02 42.98 96 96 96zM96 96c0-35.29 28.71-64 64-64s64 28.71 64 64v160c0 35.29-28.71 64-64 64s-64-28.71-64-64V96zm216 96h-16c-4.42 0-8 3.58-8 8v56c0 73.46-62.2 132.68-136.73 127.71C83.3 379.18 32 319.61 32 251.49V200c0-4.42-3.58-8-8-8H8c-4.42 0-8 3.58-8 8v50.34c0 83.39 61.65 156.12 144 164.43V480H72c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h176c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8h-72v-65.01C256.71 406.9 320 338.8 320 256v-56c0-4.42-3.58-8-8-8z'
        ></path>
      </svg>
    </svg>
  </svg>
);

export const MicSlashed = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='50%' cy='50%' r='50%' fill='currentColor' opacity='0.5' />
    <svg
      className={iconStyle}
      viewBox='0 0 1024 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <svg
        className={iconStyle}
        viewBox='0 0 640 512'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill='currentColor'
          d='M256 96c0-35.3 28.7-64 64-64s64 28.7 64 64v149.3l30.7 24.1c.6-4.4 1.3-8.8 1.3-13.4V96c0-53-43-96-96-96s-96 43-96 96v23.3l32 25.2zm224 160v-56c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v56c0 12.3-2.3 24-5.6 35.3l27 21.3C476.2 295 480 276 480 256zm-72 224h-72v-65c27.2-2.7 52.1-12.7 73.6-27.3l-26.4-20.8c-21 12-45.6 18.6-71.9 16.8-68-4.5-119.3-64.1-119.3-132.2v-35.2l-28.5-22.5c-2 1.5-3.5 3.5-3.5 6.1v50.3c0 83.4 61.7 156.1 144 164.4V480h-72c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-88-128c12.6 0 24.5-2.6 35.5-7l-32.2-25.4c-1.1.1-2.1.3-3.3.3-31.3 0-57.3-22.6-62.8-52.4l-33.2-26V256c0 53 43 96 96 96zm317 133.2L23 1.8C19.6-1 14.5-.5 11.8 3l-10 12.5C-1 19-.4 24 3 26.7l614 483.5c3.4 2.8 8.5 2.2 11.2-1.2l10-12.5c2.8-3.5 2.3-8.5-1.2-11.3z'
        ></path>
      </svg>
    </svg>
  </svg>
);

export const Video = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='50%' cy='50%' r='50%' fill='#fff' opacity='0.5' />
    <svg
      className={iconStyle}
      viewBox='0 0 1024 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <svg
        className={iconStyle}
        viewBox='0 0 576 512'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill='currentColor'
          d='M543.9 96c-6.2 0-12.5 1.8-18.2 5.7L416 171.6v-59.8c0-26.4-23.2-47.8-51.8-47.8H51.8C23.2 64 0 85.4 0 111.8v288.4C0 426.6 23.2 448 51.8 448h312.4c28.6 0 51.8-21.4 51.8-47.8v-59.8l109.6 69.9c5.7 4 12.1 5.7 18.2 5.7 16.6 0 32.1-13 32.1-31.5v-257c.1-18.5-15.4-31.5-32-31.5zM384 400.2c0 8.6-9.1 15.8-19.8 15.8H51.8c-10.7 0-19.8-7.2-19.8-15.8V111.8c0-8.6 9.1-15.8 19.8-15.8h312.4c10.7 0 19.8 7.2 19.8 15.8v288.4zm160-15.7l-1.2-1.3L416 302.4v-92.9L544 128v256.5z'
        ></path>
      </svg>
    </svg>
  </svg>
);

export const VideoSlashed = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 512 512' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='50%' cy='50%' r='50%' fill='currentColor' opacity='0.5' />
    <svg
      className={iconStyle}
      viewBox='0 0 1024 512'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <svg
        className={iconStyle}
        viewBox='0 0 640 512'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          fill='currentColor'
          d='M637 485.2L142.7 96l-16.2-12.7L23 1.8C19.6-1 14.5-.4 11.8 3l-10 12.5C-1 19-.4 24 3 26.8L438.9 370l9.1 7.2 169 133.1c3.4 2.8 8.5 2.2 11.2-1.2l10-12.5c2.8-3.6 2.2-8.6-1.2-11.4zM396.2 416H83.8c-10.7 0-19.8-7.2-19.8-15.8V135.9l-31.9-25.1c0 .4-.1.7-.1 1.1v288.4c0 26.4 23.2 47.8 51.8 47.8h312.4c16.6 0 31.2-7.3 40.7-18.5l-25.3-19.9c-3.7 3.7-9.1 6.3-15.4 6.3zm0-320c10.7 0 19.8 7.2 19.8 15.8v138.3l32 25.2v-65.8L576 128v248.1l28.4 22.4c2.1-4.2 3.6-8.8 3.6-14v-257C608 109 592.5 96 575.9 96c-6.2 0-12.5 1.8-18.2 5.7L448 171.6v-59.8c0-26.4-23.2-47.8-51.8-47.8H179.6l40.6 32h176z'
        ></path>
      </svg>
    </svg>
  </svg>
);

export const RatedStar = ({ iconStyle, size }) => (
  <svg
    className={iconStyle}
    style={{ width: size + 'rem', height: size + 'rem' }}
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.43 6L8.96 1.16c-.29-.95-1.63-.95-1.91 0L5.57 6H1.12C.15 6-.25 7.25.54 7.81l3.64 2.6-1.43 4.61c-.29.93.79 1.68 1.56 1.09L8 13.31l3.69 2.81c.77.59 1.85-.16 1.56-1.09l-1.43-4.61 3.64-2.6c.79-.57.39-1.81-.58-1.81h-4.45V6z'
      fill='#FDA623'
    />
  </svg>
);

export const UnratedStar = ({ iconStyle, size }) => (
  <svg
    className={iconStyle}
    style={{ width: size + 'rem', height: size + 'rem' }}
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 4.89L8.94 8h2.82L9.49 9.62l.93 3.01L8 10.79l-2.42 1.84.93-3.01L4.24 8h2.82L8 4.89zM5.58 6H1.12C.15 6-.25 7.25.54 7.81l3.64 2.6-1.43 4.61c-.29.93.79 1.68 1.56 1.09L8 13.31l3.69 2.81c.77.59 1.85-.16 1.56-1.09l-1.43-4.61 3.64-2.6c.79-.57.39-1.81-.58-1.81h-4.46L8.95 1.17c-.29-.95-1.63-.95-1.91 0L5.58 6z'
      fill='#FDA623'
    />
  </svg>
);

export const Star = ({ iconStyle, size }) => (
  <svg
    className={iconStyle}
    style={{ width: size + 'rem', height: size + 'rem' }}
    viewBox='0 0 16 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M8 4.89L8.94 8h2.82L9.49 9.62l.93 3.01L8 10.79l-2.42 1.84.93-3.01L4.24 8h2.82L8 4.89zM5.58 6H1.12C.15 6-.25 7.25.54 7.81l3.64 2.6-1.43 4.61c-.29.93.79 1.68 1.56 1.09L8 13.31l3.69 2.81c.77.59 1.85-.16 1.56-1.09l-1.43-4.61 3.64-2.6c.79-.57.39-1.81-.58-1.81h-4.46L8.95 1.17c-.29-.95-1.63-.95-1.91 0L5.58 6z'
      fill='#E5E5E5'
    />
  </svg>
);

export const Online = ({ iconStyle }) => (
  <svg
    className={iconStyle}
    fill='none'
    stroke='currentColor'
    viewBox='0 0 50 50'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='50%' cy='50%' r='49%' fill='currentColor' opacity='0.5' />
    <svg
      className={iconStyle}
      fill='none'
      stroke='currentColor'
      viewBox='0 0 50 35'
      xmlns='http://www.w3.org/2000/svg'
    >
      <svg
        className={iconStyle}
        fill='none'
        stroke='currentColor'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M5.636 18.364a9 9 0 010-12.728m12.728 0a9 9 0 010 12.728m-9.9-2.829a5 5 0 010-7.07m7.072 0a5 5 0 010 7.07M13 12a1 1 0 11-2 0 1 1 0 012 0z'
        />
      </svg>
    </svg>
  </svg>
);

export const Offline = ({ iconStyle }) => (
  <svg
    className={iconStyle}
    fill='none'
    stroke='currentColor'
    viewBox='0 0 50 50'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='50%' cy='50%' r='49%' fill='currentColor' opacity='0.5' />
    <svg
      className={iconStyle}
      fill='none'
      stroke='currentColor'
      viewBox='0 0 50 35'
      xmlns='http://www.w3.org/2000/svg'
    >
      <svg
        className={iconStyle}
        fill='none'
        stroke='currentColor'
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          d='M18.364 5.636a9 9 0 010 12.728m0 0l-2.829-2.829m2.829 2.829L21 21M15.536 8.464a5 5 0 010 7.072m0 0l-2.829-2.829m-4.243 2.829a4.978 4.978 0 01-1.414-2.83m-1.414 5.658a9 9 0 01-2.167-9.238m7.824 2.167a1 1 0 111.414 1.414m-1.414-1.414L3 3m8.293 8.293l1.414 1.414'
        />
      </svg>
    </svg>
  </svg>
);

export const Verified = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22 11L19.56 8.21L19.9 4.52L16.29 3.7L14.4 0.5L11 1.96L7.6 0.5L5.71 3.69L2.1 4.5L2.44 8.2L0 11L2.44 13.79L2.1 17.49L5.71 18.31L7.6 21.5L11 20.03L14.4 21.49L16.29 18.3L19.9 17.48L19.56 13.79L22 11ZM9.09 15.72L5.29 11.91L6.77 10.43L9.09 12.76L14.94 6.89L16.42 8.37L9.09 15.72Z'
      fill='currentColor'
    />
  </svg>
);

export const PhoneIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 12 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.2857 0.00857142L1.71429 0C0.771429 0 0 0.771429 0 1.71429V17.1429C0 18.0857 0.771429 18.8571 1.71429 18.8571H10.2857C11.2286 18.8571 12 18.0857 12 17.1429V1.71429C12 0.771429 11.2286 0.00857142 10.2857 0.00857142ZM10.2857 15.4286H1.71429V3.42857H10.2857V15.4286Z'
      fill='black'
      fillOpacity='0.3'
    />
  </svg>
);

export const EmailIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 18 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M18 1.8C18 0.81 17.19 0 16.2 0H1.8C0.81 0 0 0.81 0 1.8V12.6C0 13.59 0.81 14.4 1.8 14.4H16.2C17.19 14.4 18 13.59 18 12.6V1.8ZM16.2 1.8L9 6.3L1.8 1.8H16.2ZM16.2 12.6H1.8V3.6L9 8.1L16.2 3.6V12.6Z'
      fill='black'
      fillOpacity='0.3'
    />
  </svg>
);

export const EditIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 14.25V18H3.75L14.81 6.94L11.06 3.19L0 14.25ZM2.92 16H2V15.08L11.06 6.02L11.98 6.94L2.92 16ZM17.71 2.63L15.37 0.29C15.17 0.09 14.92 0 14.66 0C14.4 0 14.15 0.1 13.96 0.29L12.13 2.12L15.88 5.87L17.71 4.04C18.1 3.65 18.1 3.02 17.71 2.63Z'
      fill='currentColor'
    />
  </svg>
);

export const GraduationHatIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 22 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11 0L0 6L4 8.18V14.18L11 18L18 14.18V8.18L20 7.09V14H22V6L11 0ZM17.82 6L11 9.72L4.18 6L11 2.28L17.82 6ZM16 12.99L11 15.72L6 12.99V9.27L11 12L16 9.27V12.99Z'
      fill='currentColor'
    />
  </svg>
);

export const BriefcaseIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 4V2H8V4H12ZM2 6V17H18V6H2ZM18 4C19.11 4 20 4.89 20 6V17C20 18.11 19.11 19 18 19H2C0.89 19 0 18.11 0 17L0.00999999 6C0.00999999 4.89 0.89 4 2 4H6V2C6 0.89 6.89 0 8 0H12C13.11 0 14 0.89 14 2V4H18Z'
      fill='currentColor'
    />
  </svg>
);

export const NotepadCheckedIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5 5H7V8H17V5H19V10H21V5C21 3.9 20.1 3 19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H11V19H5V5ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3Z'
      fill='currentColor'
    />
    <path d='M21 11.5L15.51 17L12.5 14L11 15.5L15.51 20L22.5 13L21 11.5Z' fill='currentColor' />
  </svg>
);

export const ShieldIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 18 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9 0L0 4V10C0 15.55 3.84 20.74 9 22C14.16 20.74 18 15.55 18 10V4L9 0ZM9 10.99H16C15.47 15.11 12.72 18.78 9 19.93V11H2V5.3L9 2.19V10.99Z'
      fill='currentColor'
    />
  </svg>
);

export const ChatsIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 34 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17.8921 9.5455H32.0671C33.1346 9.5455 34 10.5267 34 11.7371V23.4254C34 24.6358 33.1346 25.617 32.0671 25.617H29.7797L30.1302 29.1893C30.1693 29.5903 29.9142 29.9513 29.5605 29.9956C29.3779 30.0184 29.1953 29.9518 29.0587 29.8124L24.9467 25.617H17.8921C16.8246 25.617 15.9592 24.6358 15.9592 23.4254V11.7371C15.9592 10.5267 16.8246 9.5455 17.8921 9.5455Z'
      fill='#76CE65'
    />
    <g clipPath='url(#clip0)'>
      <path
        d='M7.48638 21.5414L7.61233 20.4289H6.49273H2.97376C1.86709 20.4289 1 19.5519 1 18.5068V2.92228C1 1.87718 1.86709 1.00018 2.97376 1.00018H24.7813C25.888 1.00018 26.7551 1.87718 26.7551 2.92228V18.5068C26.7551 19.5519 25.888 20.4289 24.7813 20.4289H13.9281H13.5494L13.2657 20.6798L6.95187 26.2628L7.48638 21.5414Z'
        fill='white'
        stroke='#76CE65'
        strokeWidth='2'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='27.7551' height='27.2729' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export const LoadingCircle = ({ iconStyle }) => (
  <svg
    className={'inline animate-spin ' + iconStyle}
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 24 24'
  >
    <circle
      className='opacity-25'
      cx='12'
      cy='12'
      r='10'
      stroke='currentColor'
      strokeWidth='4'
    ></circle>
    <path
      className='opacity-75'
      fill='currentColor'
      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
    ></path>
  </svg>
);

export const AlertIcon = ({ iconStyle }) => (
  <svg className={iconStyle} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11 15H13V17H11V15ZM11 7H13V13H11V7ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z'
      fill='currentColor'
    />
  </svg>
);

export const ScreenShareIcon = () => (
  <svg
    className='inline w-6 h-6 text-gray-400 ml-2 -mt-2'
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    viewBox='0 0 16 16'
  >
    <path
      fillRule='evenodd'
      d='M3.5 6a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-2a.5.5 0 0 1 0-1h2A1.5 1.5 0 0 1 14 6.5v8a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-8A1.5 1.5 0 0 1 3.5 5h2a.5.5 0 0 1 0 1h-2z'
    />
    <path
      fillRule='evenodd'
      d='M7.646.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 1.707V10.5a.5.5 0 0 1-1 0V1.707L5.354 3.854a.5.5 0 1 1-.708-.708l3-3z'
    />
  </svg>
);

export const Person = ({ iconStyle }) => (
  <svg
    className={iconStyle}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.87'>
      <path
        d='M12 6C13.1 6 14 6.9 14 8C14 9.1 13.1 10 12 10C10.9 10 10 9.1 10 8C10 6.9 10.9 6 12 6ZM12 16C14.7 16 17.8 17.29 18 18H6C6.23 17.28 9.31 16 12 16ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 14C9.33 14 4 15.34 4 18V20H20V18C20 15.34 14.67 14 12 14Z'
        fill='white'
      />
    </g>
  </svg>
);

export const Card = ({ iconStyle }) => (
  <svg
    className={iconStyle}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.87'>
      <path
        d='M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z'
        fill='white'
      />
    </g>
  </svg>
);

export const LogoutDoor = ({ iconStyle }) => (
  <svg
    className={iconStyle}
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.87'>
      <path
        d='M17 8L15.59 9.41L17.17 11H9V13H17.17L15.59 14.58L17 16L21 12L17 8ZM5 5H12V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H12V19H5V5Z'
        fill='white'
      />
    </g>
  </svg>
);

export const UserIcon = ({ iconstyle }) => (
  <svg className={iconstyle} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M12 5.9C13.16 5.9 14.1 6.84 14.1 8C14.1 9.16 13.16 10.1 12 10.1C10.84 10.1 9.9 9.16 9.9 8C9.9 6.84 10.84 5.9 12 5.9ZM12 14.9C14.97 14.9 18.1 16.36 18.1 17V18.1H5.9V17C5.9 16.36 9.03 14.9 12 14.9ZM12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4ZM12 13C9.33 13 4 14.34 4 17V20H20V17C20 14.34 14.67 13 12 13Z'
      fill='black'
      fillOpacity='0.3'
    />
  </svg>
);

export const LockIcon = ({ iconStyle }) => (
  <svg
    className={iconStyle}
    width='40'
    height='53'
    viewBox='0 0 40 53'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M35 18H32.5V13C32.5 6.1 26.9 0.5 20 0.5C13.1 0.5 7.5 6.1 7.5 13V18H5C2.25 18 0 20.25 0 23V48C0 50.75 2.25 53 5 53H35C37.75 53 40 50.75 40 48V23C40 20.25 37.75 18 35 18ZM12.5 13C12.5 8.85 15.85 5.5 20 5.5C24.15 5.5 27.5 8.85 27.5 13V18H12.5V13ZM35 48H5V23H35V48ZM20 40.5C22.75 40.5 25 38.25 25 35.5C25 32.75 22.75 30.5 20 30.5C17.25 30.5 15 32.75 15 35.5C15 38.25 17.25 40.5 20 40.5Z'
      fill='white'
    />
  </svg>
);

export const CloseIcon = ({ iconStyle }) => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z'
      fill='black'
      fillOpacity='0.6'
    />
  </svg>
);

export const AccordianIcon = ({ iconStyle }) => (
  <svg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 5L5 0L10 5L0 5Z' fill='#134F5C' />
  </svg>
);

export const AIcon = ({ iconStyle }) => (
  <svg width='10' height='5' viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M0 0L5 5L10 0L0 0Z' fill='black' fillOpacity='0.6' />
  </svg>
);

export const InfoIcon = (props) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill={props.fill}
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M11.2 8H12.8V9.6H11.2V8ZM11.2 11.2H12.8V16H11.2V11.2ZM12 4C7.584 4 4 7.584 4 12C4 16.416 7.584 20 12 20C16.416 20 20 16.416 20 12C20 7.584 16.416 4 12 4ZM12 18.4C8.472 18.4 5.6 15.528 5.6 12C5.6 8.472 8.472 5.6 12 5.6C15.528 5.6 18.4 8.472 18.4 12C18.4 15.528 15.528 18.4 12 18.4Z'
      fill={props.fill}
      fillOpacity='0.4'
    />
  </svg>
);
