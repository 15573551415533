import React, { useEffect, useState } from 'react';
import { CheckCircle, Done } from '@material-ui/icons';
import { CircularProgress, TextField } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHMSActions } from '@100mslive/hms-video-react';
import { useHistory } from 'react-router-dom';

import { Triangle, ReverseTriangle } from '../../../../components/utils/Icons';
import RenderImageQA from '../../../../components/utils/RenderImageQA';
import COLORS from '../../../../globals/COLORS';
import { PATH, SESSION_TYPE } from '../../../../constants';
import RatingTemplate, { getRatingChangeObj } from '../../components/RatingTemplate';
import RatingTemplateView from '../../components/RatingTemplateView';

const AskedQues = ({
  bookingQuestions,
  minQuesToAsk,
  submitQuesFeedback,
  endSession,
  sessionType,
}) => {
  const [solutionToggle, setSolutionToggle] = useState({});
  const [feedbackFormToggle, setFeedbackFormToggle] = useState({});

  const noOfSubmittedQues = bookingQuestions?.filter((bq) => !!bq.ratingFromCoach)?.length;
  const disableEndSessionButton =
    sessionType === SESSION_TYPE.PREVIEW || noOfSubmittedQues < minQuesToAsk;

  return (
    <div className='bg-ab-tertiary bg-opacity-10 border border-solid border-ab-primary-shade-2 border-opacity-20 rounded-xl p-4 shadow-lg'>
      {/* heading */}
      <div className='px-2 flex justify-between items-center'>
        <span>
          <span className='text-xl font-medium mb-2'>Questions</span>
          <span className='ml-2 opacity-60 text-sm'>(min {minQuesToAsk || 0} questions)</span>
        </span>
        <span className='text-4xl opacity-80'>{bookingQuestions.length}</span>
      </div>

      <button
        onClick={endSession}
        disabled={disableEndSessionButton}
        className={
          'w-full bg-blue-c1 dark:bg-ab-secondary text-ab-tertiary font-medium rounded-lg py-2.5 mt-4 focus:outline-none ' +
          (disableEndSessionButton && ' opacity-30 cursor-not-allowed')
        }
      >
        End Session & Submit Feedback
      </button>

      {/* render asked ques */}
      <div className='flex flex-col'>
        {bookingQuestions.map((item, index) => (
          <div
            key={item.Question.id}
            className={
              item.ratingFromCoach
                ? 'bg-green-c3 bg-opacity-10 font-medium rounded-lg p-4 border border-solid border-green-c3 mt-4'
                : 'bg-ab-tertiary dark:bg-ab-tertiary dark:bg-opacity-5 font-medium rounded-lg p-4 border border-solid border-ab-primary-shade-2 border-opacity-20 mt-4'
            }
          >
            {/* <div className='flex mb-1'>
              {item.ratingFromCoach && (
                <CheckCircle htmlColor={COLORS.AB_GREEN_C3} />
              )}
            </div> */}

            {/* Question */}
            <div
              onClick={() =>
                setFeedbackFormToggle({
                  ...feedbackFormToggle,
                  [item.Question.id]: !feedbackFormToggle[item.Question.id],
                })
              }
              className='cursor-pointer flex gap-4'
            >
              <span>{`Q${index + 1}.`}</span>
              <pre>
                <span className='whitespace-pre-wrap'>{item.Question.question}</span>
              </pre>

              {feedbackFormToggle[item.Question.id] ? (
                <span>
                  <Triangle iconStyle='w-4 inline' />
                </span>
              ) : (
                <span>
                  <ReverseTriangle iconStyle='w-4 inline opacity-60 -mt-0.5' />
                </span>
              )}

              {item.ratingFromCoach && <CheckCircle htmlColor={COLORS.AB_GREEN_C3} />}
            </div>

            {/* Show Submitted Ratings or Feedback Form */}
            {feedbackFormToggle[item.Question.id] && (
              <div className='mt-4'>
                {item.ratingFromCoach ? (
                  // Show submitted feedback
                  <SubmittedFeedback ratingFromCoach={item.ratingFromCoach} />
                ) : (
                  // Show feedback form
                  <FeedbackForm
                    questionId={item.Question.id}
                    conceptId={item.Question.conceptId}
                    questionString={item.Question.question}
                    submitQuesFeedback={submitQuesFeedback}
                  />
                )}
              </div>
            )}

            {/* tags */}
            <div className='mt-4 flex justify-between'>
              {/* <span className='text-sm font-medium'>
                <span className='opacity-60'>Previously Attended : </span>
                <span>{item.alreadyAsked ? 'Yes' : 'No'}</span>
              </span> */}
              <span className='text-sm font-medium capitalize'>
                <span className='opacity-60'>Difficulty Level : </span>
                <span>{item.Question.difficultyLevel || '---'}</span>
              </span>

              {/* solution toggle */}
              <span
                className={'cursor-pointer '}
                onClick={() =>
                  setSolutionToggle({
                    ...solutionToggle,
                    [item.Question.id]: !solutionToggle[item.Question.id],
                  })
                }
              >
                <span
                  className={'text-sm pr-2 ' + (!solutionToggle[item.Question.id] && 'opacity-60')}
                >
                  Solution
                </span>

                {solutionToggle[item.Question.id] ? (
                  <span>
                    <Triangle iconStyle='w-4 inline pointer-events-none' />
                  </span>
                ) : (
                  <span>
                    <ReverseTriangle iconStyle='w-4 inline opacity-60 pointer-events-none' />
                  </span>
                )}
              </span>
            </div>

            {/* solution */}
            {solutionToggle[item.Question.id] && (
              <div>
                <pre>
                  <code>
                    <p className='mt-2 font-normal overflow-x-auto space-x-8 '>
                      {item.Question.solution}
                    </p>
                  </code>
                </pre>
                {item.Question.solutionImageUrl && (
                  <RenderImageQA imageKey={item.solutionImageUrl} />
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const FeedbackForm = ({ questionId, conceptId, questionString, submitQuesFeedback }) => {
  const [ratingFromCoach, setRatingFromCoach] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isQuesAsked, setIsQuesAsked] = useState(false);
  const ratingTemplateByConceptId = useSelector(
    (state) => state.questionLevelsReducer.ratingTemplateByConceptId
  );
  const hmsActions = useHMSActions();
  const history = useHistory();

  const ratingTemplate = ratingTemplateByConceptId[conceptId];

  const handleFeedbackText = (feedback) =>
    setRatingFromCoach({
      ...ratingFromCoach,
      feedback,
    });

  const handleRatingInput = (param, e) => {
    const { name, finalObj } = getRatingChangeObj(param, e);
    setRatingFromCoach({
      ...ratingFromCoach,
      [name]: finalObj,
    });
  };

  const askQuestion = () => {
    hmsActions.sendBroadcastMessage(questionString);
    setIsQuesAsked(true);
  };

  useEffect(() => {
    if (history.location.pathname === PATH.MENTORSHIP.PREVIEW_SESSION) return;

    if (
      Object.values(ratingFromCoach).filter((v) => !!v).length >= ratingTemplate?.parameters.length
    )
      setIsDisabled(false);
    else setIsDisabled(true);
  }, [ratingFromCoach]);

  return (
    <div>
      {/* Ratings input */}
      {ratingTemplate?.parameters ? (
        <div>
          {ratingTemplate.parameters?.map((param) => (
            <RatingTemplate
              key={param.name}
              param={{ ...param, questionId }}
              changeHandler={handleRatingInput}
            />
          ))}

          {/* feedback text input */}
          {ratingTemplate.feedback && (
            <TextField
              onChange={(e) => handleFeedbackText(e.target.value)}
              label='Feedback'
              variant='outlined'
              rows={3}
              style={{ margin: '0.5rem 0rem' }}
              multiline
              fullWidth
            />
          )}

          <div className='flex items-center gap-4 my-2'>
            {/* ask ques & send to chat */}
            <button
              onClick={askQuestion}
              className='w-full text-sm text-blue-c1 border border-solid border-blue-c1 rounded py-1.5 focus:outline-none flex items-center justify-center gap-2'
            >
              <span>Ask Question</span>
              {isQuesAsked && <Done />}
            </button>
            {/* submission button */}
            <button
              onClick={() => submitQuesFeedback(questionId, ratingFromCoach)}
              disabled={isDisabled}
              className={
                'w-full bg-blue-c1 dark:bg-ab-secondary py-2 font-medium text-white text-sm rounded focus:outline-none ' +
                (isDisabled && 'opacity-50 cursor-not-allowed')
              }
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      ) : (
        <div className='text-center text-blue-c1'>
          <CircularProgress color='inherit' />
        </div>
      )}
    </div>
  );
};

const SubmittedFeedback = ({ ratingFromCoach }) => {
  const { feedback, ...ratings } = ratingFromCoach;
  return (
    <div>
      {/* Ratings */}
      <div className='px-4 mx-2 my-2 flex flex-col sm:text-sm md:text-base'>
        {Object.entries(ratings || {}).map(([key, values]) => (
          <>
            <span className='opacity-60 capitalize my-2 mx-4'>{key}</span>
            <RatingTemplateView param={values} />
          </>
        ))}
      </div>

      {/* Feedback Text */}
      {feedback && (
        <div className='bg-green-c2 dark:bg-ab-tertiary bg-opacity-20 dark:bg-opacity-5 rounded-lg font-normal mt-4 py-2 px-2.5'>
          <div className='text-xs opacity-60'>Feedback</div>
          <p className='mt-1'>{feedback}</p>
        </div>
      )}
    </div>
  );
};

export default AskedQues;
