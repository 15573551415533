import React, { useEffect, useState } from 'react';
import { CircularProgress, TextField } from '@material-ui/core';
import { addBookingFeedback, getRatingTemplate } from '../../../../services/bookingApiService';
import RatingTemplate, { getRatingChangeObj } from '../../components/RatingTemplate';

const CandidateRatingForm = ({ booking, setSubmitted }) => {
  const [ratingTemplate, setRatingTemplate] = useState(null);
  const [formData, setFormData] = useState({});
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await addBookingFeedback(booking.id, formData);
      setSubmitted(true);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchRatingTemplate = async () =>
    setRatingTemplate(await getRatingTemplate(booking.SubTopic.ratingTemplateIdForCoach));

  useEffect(fetchRatingTemplate, []);

  useEffect(() => {
    if (Object.keys(formData).length > ratingTemplate?.parameters?.length) setDisabledSubmit(false);
    else setDisabledSubmit(true);
  }, [formData]);

  const handleUpdateChange = (param, e) => {
    const { name, finalObj } = getRatingChangeObj(param, e);
    setFormData({ ...formData, [name]: finalObj });
  };

  return ratingTemplate ? (
    <div className='bg-ab-tertiary dark:bg-opacity-10 border border-solid border-ab-primary-shade-2 border-opacity-20 rounded-xl mt-4 p-4'>
      <h1 className='text-2xl font-medium mb-6 mx-1'>Overall Candidate Rating</h1>

      {ratingTemplate?.parameters?.map((param) => (
        <RatingTemplate key={param.name} param={param} changeHandler={handleUpdateChange} />
      ))}

      {/* feedback text input */}
      <div className='mt-6 mx-4'>
        <TextField
          variant='outlined'
          onChange={({ target }) => setFormData({ ...formData, feedback: target.value })}
          value={formData.feedback || ''}
          label='Feedback'
          rows={4}
          multiline
          fullWidth
          autoFocus
        />
      </div>

      {/* submission button */}
      <button
        onClick={handleSubmit}
        disabled={disabledSubmit || isSubmitting}
        className={
          'bg-green-c1 dark:bg-ab-secondary py-3 w-1/4 m-4 font-medium text-white text-sm rounded focus:outline-none' +
          ((disabledSubmit || isSubmitting) && ' opacity-50 cursor-not-allowed')
        }
      >
        {isSubmitting ? <CircularProgress color='inherit' size='1.5rem' /> : 'Submit'}
      </button>
    </div>
  ) : (
    <div className='text-center py-32'>
      <CircularProgress color='inherit' />
    </div>
  );
};

export default CandidateRatingForm;
