import Rating from '@material-ui/lab/Rating';
import { StarOutline } from '@material-ui/icons';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';

const RatingTemplateView = ({ param }) => {
  if (!isNumber(param) && !isObject(param) && !param)
    return <span className='ml-4 mb-4 opacity-40'>-</span>;

  if (isNumber(param)) {
    return (
      <Rating
        emptyIcon={
          <StarOutline
            color='inherit'
            className='text-opacity-30 dark:text-opacity-60 text-ab-primary dark:text-ab-tertiary'
          />
        }
        value={param}
        readOnly
      />
    );
  }
  const { value, maxLength, type } = param;

  return (
    <div key={param} className='opacity-80 font-normal capitalize flex flex-col gap-y-3 mb-4 mx-4'>
      {
        {
          string: <span>{value || '-'}</span>,
          number: (
            <>
              <Rating
                max={maxLength}
                emptyIcon={
                  <StarOutline
                    color='inherit'
                    className='text-opacity-30 dark:text-opacity-60 text-ab-primary dark:text-ab-tertiary'
                  />
                }
                value={value}
                maxLength={maxLength}
                readOnly
              />
            </>
          ),
          boolean: <span>{value ? 'Yes' : 'No'}</span>,
        }[type]
      }
    </div>
  );
};

export default RatingTemplateView;
