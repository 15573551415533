import React from 'react';

const RenderThumbnail = ({ thumbnail, name, size = 4 }) => {
  return (
    <>
      {thumbnail ? (
        <img
          src={thumbnail}
          alt='User Profile'
          className='rounded-full object-cover text-xs'
          style={{ width: size + 'rem', height: size + 'rem' }}
        />
      ) : (
        <span
          className='bg-blue-200 flex items-center justify-center rounded-full'
          style={{ width: size + 'rem', height: size + 'rem' }}
        >
          <h1 className='text-2xl uppercase font-bold text-blue-500'>{name?.substr(0, 1)}</h1>
        </span>
      )}
    </>
  );
};

export default RenderThumbnail;
