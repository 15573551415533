import React, { useState, useEffect } from 'react';
import { DeleteOutline, EditOutlined, Add } from '@material-ui/icons';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { TextFieldLight, AutocompleteLight } from '../../../utils';
import { LoadingCircle } from '../../utils/Icons';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { addCompanyAction } from '../../../redux/workExperienceSlice';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { updateCoach } from '../../../redux/authSlice';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Chip } from '@material-ui/core';
import { addCoachingRolesAction } from '../../../redux/coachExperienceSlice';

const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const CoachingExperience = ({ coachingExperience, setActiveOption }) => {
  const matches = useMediaQuery('(min-width:1024px)');
  const [data, setData] = useState(coachingExperience);
  const [coachExp, setCoachExp] = useState({});
  const [loading, setLoading] = useState(false);
  const [companyValue, setCompanyValue] = useState({});
  const [addCoachExp, setAddCoachExp] = useState(coachingExperience?.length === 0);
  const [editData, setEditData] = useState({});
  const [companyNameInput, setCompanyNameInput] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [error, setError] = useState([]);

  const dispatch = useDispatch();
  const companies = useSelector(({ workExperienceReducer }) => workExperienceReducer.companies);
  const coachingRoles = useSelector(
    ({ coachExperienceReducer }) => coachExperienceReducer.coachingRoles
  );

  useEffect(() => {
    setData(coachingExperience);
  }, [coachingExperience]);

  const getListOfRoles = (list) => {
    const data = [];
    list.map((role) => {
      data.push(role.name);
    });
    return data;
  };

  const removeOption = (id) => {
    const data = selectedOptions.filter((option) => option.id !== id);
    setSelectedOptions(data);
  };

  const handleInput = async ({ target }) => {
    setCoachExp({
      ...coachExp,
      [target.name]: target.value,
    });
  };

  const updateDetails = async (details) => {
    const coachExperienceData = {
      coachingExperience: details,
    };
    try {
      await dispatch(updateCoach(coachExperienceData));
      toast.info('Profile Updated!', {
        position: matches ? 'bottom-right' : '',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } catch (err) {
      toast.error('Failed To Update Profile! \n Please Try Again Later!!', {
        position: matches ? 'bottom-right' : '',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    setLoading(false);
  };

  const addDetails = async (e) => {
    e.preventDefault();

    let companyData = companyValue;
    let coachRoleData = [];
    setLoading(true);
    if (!companyValue.companyName || companyValue?.companyName !== companyNameInput) {
      const { id, name } = await dispatch(
        addCompanyAction(companyNameInput.charAt(0).toUpperCase() + companyNameInput.substring(1))
      );
      companyData = { companyId: id, companyName: name };
    }
    if (selectedOptions) {
      selectedOptions.map((role) => {
        const data = { id: role.id, name: role.name };
        coachRoleData.push(data);
      });
    }

    const arr = {
      ...coachExp,
      companyName: companyData.companyName,
      companyId: companyData.companyId,
      coachRoleName: coachRoleData,
    };
    setCompanyValue({});
    setCompanyNameInput('');
    setCoachExp({});
    await updateDetails([...data, arr]);
    setAddCoachExp(false);
    setEditData({});
    setSelectedOptions([]);
  };

  const removeDetails = async (i) => {
    const details = [...data];
    details.splice(i, 1);
    await updateDetails(details);
    if (details.length === 0) {
      setAddCoachExp(true);
    }
    setCoachExp({});
  };

  const editDetails = (i) => {
    setAddCoachExp(true);
    const details = [...data];
    if (editData?.companyName) {
      const obj = {
        ...editData,
      };
      details.push(obj);
    }
    const [toEdit] = details.splice(i, 1);
    setData(details);
    setSelectedOptions(toEdit.coachRoleName);
    setCoachExp(toEdit);
    setCompanyValue({
      companyName: toEdit.companyName,
      companyId: toEdit.companyId,
    });
    setEditData(toEdit);
  };

  const disableSubmitButton = () => {
    const error = [];
    if (!coachExp.domain) error.push('Domain');
    if (!coachExp.years) error.push('Years');
    if (selectedOptions.length === 0) error.push('roles');
    if (Object.keys(companyValue).length === 0) error.push('company');

    setError(error);
  };

  useEffect(() => {
    disableSubmitButton();
  }, [coachExp, selectedOptions, companyValue]);

  return (
    <div className='w-full md:mt-6 flex flex-col mx-4 md:mx-0'>
      <div className='flex md:flex-row flex-col text-ab-primary dark:text-ab-tertiary lg:pr-40 md:pr-10 space-y-4 md:space-y-0'>
        <div className={`md:w-1/4 w-full text-gray-c5 ${data?.length > 0 ? 'visible' : 'hidden'}`}>
          Experience
        </div>
        <div className='md:w-3/4 w-full '>
          {data?.length > 0 &&
            data?.map((item, index) => {
              return (
                <div className='mb-8 flex justify-between gap-4' key={index}>
                  <div className=' flex flex-col gap-1'>
                    <div className='font-semibold text-base opacity-80'>{item.domain}</div>
                    <div className='ffont-semibold text-sm opacity-70  md:whitespace-nowrap'>
                      {getListOfRoles(item.coachRoleName).join(', ')}
                    </div>
                    <div className='text-sm opacity-60 my-1'>
                      {item.companyName} ({item.years} Year)
                    </div>
                  </div>

                  <div className='flex gap-2'>
                    <div onClick={() => editDetails(index)} className='cursor-pointer opacity-30'>
                      <EditOutlined color='inherit' />
                    </div>
                    <div onClick={() => removeDetails(index)} className='cursor-pointer opacity-30'>
                      <DeleteOutline color='inherit' />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* form */}
      <form onSubmit={addDetails} className='mt-8 md:pr-20 w-full px-0 md:px-4'>
        {addCoachExp && (
          <div className=''>
            <div className=' mb-8 flex md:gap-8 gap-3 items-center'>
              <div className=' text-black'>Teaching experience in years :</div>
              <TextFieldLight
                label='Years'
                placeholder='Ex. 5'
                name='years'
                variant='outlined'
                value={coachExp.years || ''}
                type='number'
                style={{ width: '80px', height: '50px' }}
                onChange={handleInput}
                fullWidth
                required
                onWheel={(e) => e.target.blur()}
              />
            </div>

            <div className='flex flex-col md:flex-row justify-between gap-4 mb-6'>
              <AutocompleteLight
                freeSolo
                fullWidth
                autoComplete
                id='company-combo-box'
                inputValue={companyNameInput}
                onInputChange={(_, newValue) => setCompanyNameInput(newValue)}
                value={companyValue.companyName || ''}
                onChange={async (event, newValue) => {
                  if (newValue) {
                    if (!newValue.inputValue) {
                      setCompanyValue({
                        companyId: newValue.id,
                        companyName: newValue.name,
                      });
                    } else setCompanyValue({});
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  return filtered;
                }}
                options={companies}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.company;
                  }
                  // Regular option
                  return option.name;
                }}
                loading={companies.length === 0}
                renderInput={(params) => (
                  <TextFieldLight
                    {...params}
                    label='Institution'
                    name='company'
                    variant='outlined'
                    type='text'
                    value={coachExp.company || ''}
                    required
                    autoFocus
                  />
                )}
              />

              <TextFieldLight
                label='Domain'
                name='domain'
                variant='outlined'
                placeholder='Select Domain'
                select
                fullWidth
                variant='outlined'
                value={coachExp.domain || ''}
                required
                onChange={handleInput}
                style={{}}
              >
                <MenuItem value='Data Science'>Data Science</MenuItem>
                <MenuItem value='Web Development'>Web Development</MenuItem>
              </TextFieldLight>
            </div>

            <div className=' grid grid-col-2'>
              <AutocompleteLight
                multiple
                fullWidth
                options={coachingRoles}
                defaultValue={coachingRoles}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                value={selectedOptions}
                onKeyPress={(e) => {
                  e.target.keyCode === 'Enter' && e.preventDefault();
                }}
                renderTags={(values) =>
                  values.map((value) => (
                    <Chip
                      key={value.id}
                      label={value.name}
                      onDelete={() => {
                        removeOption(value.id);
                      }}
                    />
                  ))
                }
                getOptionSelected={(option, value) => option.id === value.id}
                renderOption={(filterOptions, { selected }) => (
                  <React.Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 6 }}
                      checked={selected}
                    />
                    {filterOptions.name}
                  </React.Fragment>
                )}
                //style={{ width: 500 }}
                onChange={(event, values) => {
                  setSelectedOptions([...values]);
                }}
                renderInput={(params) => (
                  <TextFieldLight
                    {...params}
                    style={{ display: 'flex' }}
                    variant='outlined'
                    label='Role'
                    placeholder='Role'
                    required
                    onKeyDown={async (e) => {
                      if (e.key === 'Enter' && e.target.value) {
                        e.preventDefault();
                        const { id, name } = await dispatch(addCoachingRolesAction(e.target.value));
                        setSelectedOptions(selectedOptions.concat({ name: name, id: id }));
                      }
                    }}
                  />
                )}
              />
            </div>
          </div>
        )}
        <div className='md:mb-8 mb-4'>
          <div className=' md:mt-5 flex space-x-8'>
            {!addCoachExp && (
              <button
                type='button'
                onClick={() => {
                  setAddCoachExp(true);
                }}
                className=' text-blue-c1 rounded justify-self-start font-medium text-left '
              >
                <Add /> Add Experience
              </button>
            )}

            {addCoachExp && data?.length > 0 && (
              <button
                type='button'
                className='text-gray-c5 rounded py-3 justify-self-start font-medium cursor-pointer'
                onClick={() => {
                  setAddCoachExp(false);
                  if (Object.keys(editData).length > 0) {
                    const details = [...data, editData];
                    setData(details);
                    setEditData({});
                  }
                  setCompanyValue({});
                  setCompanyNameInput('');
                  setCoachExp({});
                  setSelectedOptions([]);
                }}
              >
                Cancel
              </button>
            )}

            {addCoachExp && (
              <button
                type='button'
                className='text-blue-c1 rounded py-3 justify-self-start font-medium cursor-pointer disabled:opacity-40 disabled:cursor-not-allowed  '
                disabled={loading || error.length !== 0}
                onClick={addDetails}
              >
                {loading && <LoadingCircle iconStyle='w-6 mr-2' />}
                Save
              </button>
            )}
          </div>
        </div>
        <div className=' bottom-0 fixed'>
          <button
            type='button'
            onClick={() => {
              setActiveOption('D');
            }}
            disabled={data?.length === 0 || addCoachExp}
            className={` disabled:opacity-40 disabled:cursor-not-allowed mb-4 md:mb-8  md:w-72 w-11/12 bg-green-c1 text-white focus:outline-none font-medium  text-center rounded bottom-0 fixed py-4  md:-ml-0
            `}
          >
            NEXT
          </button>
        </div>
        <button
          onClick={() => {
            setActiveOption('D');
          }}
          className=' m-auto text-blue-c1'
        >
          Skip for now
        </button>
      </form>

      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

export default CoachingExperience;
