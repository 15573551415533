import React, { useState, useEffect } from 'react';
import bgImage from './assets/forgot_pass_bg.png';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import rocketImage from './assets/rocket.png';
import { getFirstAndLastNamesFromName, getFullName } from '../../utils';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useDispatch, useSelector } from 'react-redux';
import { loggedInUserSelector, updateUser } from '../../redux/authSlice';
import { ALMABETTER_HOME_LINKS } from '../../constants';
import { CircularProgress } from '@material-ui/core';
import logo from '../../static/img/almabetterLogo.png';

function Onboarding() {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState({});
  const [whatsappEnabled, setWhatsappEnabled] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);

  const userDetails = useSelector(loggedInUserSelector);

  const updateMissingDetails = async () => {
    try {
      setButtonLoading(true);
      const { firstName, lastName } = getFirstAndLastNamesFromName(name);
      const userObj = {
        profile: {
          firstName,
          lastName,
          phone,
          optedInForWA: whatsappEnabled,
          studentProfileCompleted: true,
        },
      };
      await dispatch(updateUser(userObj));
    } catch (e) {
      setError({ message: 'Error occurred. The team is working on it.' });
      setButtonLoading(false);
      console.error(e);
    }
  };

  const validate = () => {
    const temp = {};
    if (!name || !name.trim()) {
      temp.name = 'Name is Required';
    } else {
      const { firstName, lastName } = getFirstAndLastNamesFromName(name);
      if (!firstName || !lastName) {
        temp.name = 'First Name and Last Name is Required';
      }
    }
    if (!phone || !isPossiblePhoneNumber(phone) || (phone.match(/^\+91/) && phone.length !== 13)) {
      temp.phone = 'Mobile number is not valid';
    }

    if (Object.keys(temp).length > 0) setError(temp);
    else updateMissingDetails();
  };

  useEffect(() => {
    const fullName = getFullName(userDetails);
    const phone = userDetails.phone;
    setName(fullName);
    setPhone(phone);
  }, []);

  return (
    <div className='h-screen max-h-screen overflow-hidden grid place-items-center'>
      <img className='h-full w-full absolute' src={bgImage} alt='Background' />
      <div className='bg-ab-tertiary w-full absolute top-0 left-0 z-20 flex items-center'>
        <img className='w-36 md:w-44 m-4' src={logo} alt='Logo' />
      </div>
      <div className='bg-ab-tertiary relative z-10 mx-4 md:mx-0 md:w-4/12 text-ab-primary text-center p-8 flex flex-col gap-4 rounded-lg pb-16'>
        <h1 className='font-semibold text-2xl'>Let&apos;s start the journey</h1>
        <div className='flex flex-col justify-start text-left'>
          {error.message && (
            <span className='text-sm text-red-500 flex items-center gap-1'>
              <ErrorOutlineIcon /> {error.message}
            </span>
          )}
          <label className='text-sm' htmlFor='name'>
            Name{' '}
            <span className='text-blue-400'>
              (This will be used on all Almabetter certificates)
            </span>
          </label>
          <input
            id='name'
            className='border border-solid border-gray-300 rounded h-12 my-2 p-2'
            type='text'
            placeholder='Enter full name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <span className='text-sm text-red-500'>{error.name}</span>
        </div>
        <div className='onboarding flex flex-col gap-1 justify-start text-left'>
          <label className='text-sm' htmlFor='name'>
            Mobile Number
          </label>
          <PhoneInput
            placeholder='Enter 10-digit phone number'
            value={phone}
            onChange={setPhone}
            defaultCountry='IN'
          />
          <span className='text-sm text-red-500'>{error.phone}</span>
        </div>
        <div className='flex items-center gap-2 mt-4 text-sm'>
          <input
            type='checkbox'
            id='whatsappEnabled'
            checked={whatsappEnabled}
            onChange={() => setWhatsappEnabled((prevState) => !prevState)}
          />
          <label className='text-left' htmlFor='whatsappEnabled'>
            Send me course and admission related updates on Whatsapp
          </label>
        </div>
        <button
          className='px-4 py-3 bg-ab-secondary text-ab-tertiary font-medium rounded'
          onClick={validate}
          disabled={buttonLoading}
        >
          {buttonLoading ? (
            <CircularProgress style={{ width: '1rem', height: '1rem', color: '#fff' }} />
          ) : (
            <>Get Started</>
          )}
        </button>
        {error?.message && (
          <span className='text-sm text-red-500 place-self-start text-left'>{error.message}</span>
        )}
        <h4 className='text-xs text-left'>
          By clicking on get started, you agree to our{' '}
          <a href={ALMABETTER_HOME_LINKS.TERM_AND_CONDITIONS} className='text-blue-400'>
            Terms of Service
          </a>{' '}
          and{' '}
          <a href={ALMABETTER_HOME_LINKS.PRIVACY} className='text-blue-400'>
            Privacy Policy
          </a>
        </h4>
        <img
          className='hidden md:block md:w-40 xl:w-64 absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2'
          src={rocketImage}
          alt='Rocket'
        />
      </div>
    </div>
  );
}

export default Onboarding;
