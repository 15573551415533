import { API } from 'aws-amplify';
import { USER_API_GATEWAY_ENDPOINT_NAME } from '../aws-exports';
import { BOOKING_STAGE, BOOKING_STATUS } from '../constants';

export const get100MSAppToken = async ({ bookingId, userId, role }) => {
  return await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking/app-token', {
    body: {
      bookingId,
      userId,
      role,
    },
  });
};
export const getThemes = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/theme', {});
export const getOpenBookings = async (themeId, fromDate, toDate, subTopicId) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/filter-bookings/open', {
    body: {
      themeId,
      fromDate,
      toDate,
      subTopicId,
      includeSubTopic: true,
      includeStudent: true,
      status: BOOKING_STATUS.ACTIVE,
    },
  });
export const getMyBookings = async (pageSize, pageNo, stage, status, themeId, fromDate, toDate) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/filter-bookings/coach', {
    body: {
      themeId,
      stage,
      status,
      pageNo,
      pageSize,
      fromDate,
      toDate,
      includeSubTopic: true,
      includeBookingProblems: true,
      includeStudent: true,
    },
  });
export const acceptBooking = async (body) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/booking', {
    body: {
      ...body,
      stage: BOOKING_STAGE.COACH_ASSIGNED,
    },
  });
export const cancelBooking = async ({ id, coachId }) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/booking', {
    body: { id, coachId, stage: 'cancelled' },
  });
export const sessionConducted = async (id, coachId) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/booking', {
    body: { id, coachId, stage: 'sessionConducted' },
  });
export const reportProblem = async (body) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/booking/problem', { body });
export const getBookingRecommendedQuestions = async (bookingId) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking/question/recommended', {
    queryStringParameters: { bookingId },
  });

export const getShortlistedQuestions = async (bookingId) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking/shortlisted-question', {
    queryStringParameters: { bookingId },
  });
export const addCoachQuestion = async (body) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach-question', {
    body,
  });
export const updateShortlistedQuestions = async (bookingId, shortlistedQuestionIds) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking/shortlisted-question', {
    body: { bookingId, shortlistedQuestionIds },
  });
export const getRatingTemplate = async (ratingTemplateId) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/rating-template', {
    queryStringParameters: { ratingTemplateId },
  });
export const getBookingRatingTemplate = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/rating-template', {
    queryStringParameters: { userType: 'student' },
  });
export const getBookingQuestions = async (bookingId) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking/question', {
    queryStringParameters: { bookingId },
  });
export const addBookingFeedback = async (bookingId, rating) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/booking/rating', {
    body: { bookingId, rating },
  });
// SEssion settings
export const getSessionSettings = async () => {
  const { generalSettings } = await API.get(
    USER_API_GATEWAY_ENDPOINT_NAME,
    '/api/theme-settings',
    {}
  );
  return generalSettings;
};

export const getDetailedBooking = async (bookingId) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking', {
    queryStringParameters: { bookingId },
  });

export const deleteBookingQuestion = async (bookingId, questionId) =>
  await API.del(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking/question', {
    body: { bookingId, questionId },
  });

export const upsertBookingQuestion = async (body) =>
  await API.put(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking/question', {
    body,
  });

export const getQuestionLevels = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/question-module', {});

export const getCoachSubTopics = async () =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/sub-topic', {});

export const getBookingRecordings = async (roomId100ms) =>
  await API.get(USER_API_GATEWAY_ENDPOINT_NAME, '/api/booking/recordings', {
    queryStringParameters: { roomId100ms },
  });

export const requestNewTimeSlots = async (bookingId) =>
  await API.post(USER_API_GATEWAY_ENDPOINT_NAME, '/api/coach/booking/request-new-slots', {
    body: { bookingId },
  });
