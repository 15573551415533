import React from 'react';
import { VideoTile } from '@100mslive/hms-video-react';

import COLORS from '../../../../globals/COLORS';

const Peer = ({ peer }) => {
  return (
    <VideoTile
      peer={peer}
      hmsVideoTrackId={peer.videoTrack}
      isLocal={peer.isLocal}
      showAudioMuteStatus={!peer.isLocal}
      audioLevelDisplayColor={COLORS.AB_SECONDARY}
    />
  );
};

export default Peer;
