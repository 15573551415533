import { Route, Switch } from 'react-router-dom';
import Login from './Login';
import SignUp from './SignUp';
import ResetPassword from './ResetPassword';
import Logout from './Logout';
import LoginSuccessful from './LoginSuccessful';
import { PATH } from '../../constants';

export default function AuthRouter() {
  return (
    <Switch>
      <Route exact path={PATH.AUTH.LOGIN} component={Login} />
      <Route exact path={PATH.AUTH.LOGIN_SUCCESSFUL} component={LoginSuccessful} />
      <Route exact path={PATH.AUTH.SIGN_UP} component={SignUp} />
      <Route exact path={PATH.AUTH.FORGOT_YOUR_PASSWORD} component={ResetPassword} />
      <Route exact path={PATH.AUTH.LOGOUT} component={Logout} />
      <Route exact path={PATH.AUTH.LOGOUT_SUCCESSFUL} component={Logout} />
    </Switch>
  );
}
