import {
  selectIsLocalAudioEnabled,
  selectIsLocalVideoEnabled,
  selectIsLocalScreenShared,
  selectIsSomeoneScreenSharing,
  useHMSActions,
  useHMSStore,
} from '@100mslive/hms-video-react';
import {
  MicNoneOutlined,
  MicOffOutlined,
  VideocamOffOutlined,
  VideocamOutlined,
  PresentToAllOutlined,
  CancelPresentationOutlined,
  CallEnd,
  Warning,
  Info,
  Fullscreen,
  FullscreenExit,
} from '@material-ui/icons';
import { useState } from 'react';

import Settings from './Settings';

function Controls({ isPreviewMode }) {
  const isVideoEnabled = useHMSStore(selectIsLocalVideoEnabled);
  const isAudioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const isScreenShareEnabled = useHMSStore(selectIsLocalScreenShared);
  const isRemoteScreenShareEnabled = useHMSStore(selectIsSomeoneScreenSharing);
  const hmsActions = useHMSActions();

  const [warning, setWarning] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = (e) => {
    try {
      if (isFullScreen) {
        document.exitFullscreen();
        setIsFullScreen(false);
      } else {
        document.getElementById('video-call-container').requestFullscreen();
        setIsFullScreen(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const showWarning = async () => {
    setWarning(true);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    setWarning(false);
  };

  const toggleAudio = async () => {
    try {
      await hmsActions.setLocalAudioEnabled(!isAudioEnabled);
    } catch (e) {
      console.error(e);
    }
  };

  const toggleVideo = async () => {
    try {
      await hmsActions.setLocalVideoEnabled(!isVideoEnabled);
    } catch (e) {
      console.error(e);
    }
  };

  const toggleScreenShare = async () => {
    try {
      if (isRemoteScreenShareEnabled && !isScreenShareEnabled) {
        showWarning();
        return;
      }
      await hmsActions.setScreenShareEnabled(!isScreenShareEnabled);
    } catch (e) {
      console.error(e);
    }
  };

  const leaveConference = async () => {
    try {
      await hmsActions.stopRTMPAndRecording();
      await hmsActions.leave();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className='flex justify-between relative p-4'>
      <span className='p-4' />
      <span className='flex gap-2 text-ab-primary dark:text-ab-tertiary text-opacity-60'>
        <button
          onClick={toggleAudio}
          className='border border-solid border-ab-primary-shade-2 border-opacity-20 dark:bg-white dark:bg-opacity-10 rounded-full p-2 focus:outline-none'
        >
          {isAudioEnabled ? <MicNoneOutlined /> : <MicOffOutlined />}
        </button>

        <button
          onClick={toggleVideo}
          className='border border-solid border-ab-primary-shade-2 border-opacity-20 dark:bg-white dark:bg-opacity-10 rounded-full p-2 focus:outline-none'
        >
          {isVideoEnabled ? <VideocamOutlined /> : <VideocamOffOutlined />}
        </button>

        {!isPreviewMode && (
          <>
            <button
              onClick={toggleScreenShare}
              className='border border-solid border-ab-primary-shade-2 border-opacity-20 dark:bg-white dark:bg-opacity-10 rounded-full p-2 focus:outline-none'
            >
              {isScreenShareEnabled ? <CancelPresentationOutlined /> : <PresentToAllOutlined />}
            </button>

            <button
              onClick={leaveConference}
              className='bg-ab-secondary text-ab-tertiary rounded-full px-2.5 py-2 focus:outline-none'
            >
              <CallEnd />
            </button>
          </>
        )}
      </span>

      <span className='flex gap-2'>
        {!isPreviewMode && (
          <button
            onClick={toggleFullScreen}
            className='bg-ab-tertiary dark:bg-opacity-10 rounded-full p-2 border border-solid border-ab-primary-shade-2 border-opacity-20 focus:outline-none'
          >
            {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
          </button>
        )}

        <Settings />
      </span>

      {/* screen sharing indicator */}
      {isScreenShareEnabled && (
        <span className='absolute left-4 top-6 text-xs animate-pulse flex items-center gap-1'>
          <Info />
          <p>Screen Sharing Active</p>
        </span>
      )}

      {/* show warning message */}
      {warning && (
        <span className='absolute left-4 top-6 text-xs animate-pulse flex items-center gap-2'>
          <Warning />
          <p>Ask Coach To Turn Off Screen Sharing</p>
        </span>
      )}
    </div>
  );
}

export default Controls;
