import { API } from 'aws-amplify';
import { PROJECT_EVALUATION_ENDPOINT_NAME } from '../aws-exports';

export const getProjectList = async ({ goalType, evaluationStatus, beforeSubmittedAt, limit }) => {
  return await API.post(
    PROJECT_EVALUATION_ENDPOINT_NAME,
    '/api/project/filter-project-evaluations',
    {
      body: { goalType, evaluationStatus, beforeSubmittedAt, limit },
    }
  );
};

export const submitEvaluation = async (projectId, payload) => {
  return await API.put(
    PROJECT_EVALUATION_ENDPOINT_NAME,
    '/api/project/evaluate-project/' + projectId,
    {
      body: payload,
    }
  );
};
