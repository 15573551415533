import React, { useEffect, useState } from 'react';
import { Autorenew as LoadingIcon, Launch } from '@material-ui/icons';
import DetailsCard from './DetailsCard';
import { GOAL_TYPE, PATH } from '../../constants';
import { NavLink } from 'react-router-dom';
import NavigationBar from '../header/NavigationBar';
import { getAlmaXAssignmentList } from '../../services/almaXAssignmentEvaluationApiService';
import Filter from './components/Filter';
import { toast, ToastContainer } from 'react-toastify';

const menuBarComponent = (
  <div className='flex items-center w-11/12'>
    <NavLink
      className='text-black text-opacity-50 py-3 border-b-4 border-solid border-transparent rounded'
      activeClassName='font-medium border-ab-secondary text-ab-secondary text-opacity-100'
      exact
      to={PATH.ALMAX_ASSIGNMENT_EVALUATIONS_DASHBOARD}
    >
      My Evaluations
    </NavLink>
    <a
      href='https://almabetter.notion.site/Published-for-Evaluators-dc9b557383cb430085595af060bc5b70'
      target='_blank'
      rel='noreferrer'
      className='flex items-center gap-1 bg-blue-c3 bg-opacity-20 text-blue-c2 rounded px-2 py-1 ml-auto'
    >
      Resources
      <Launch />
    </a>
  </div>
);

const ITEM_SIZE_LIMIT = 50;

function AlmaxAssignmentEvaluationDashboard() {
  const [submissions, setSubmissions] = useState([]);
  const [totalCount, setTotalCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterState, setFilterState] = useState({
    goalType: GOAL_TYPE.DATA_SCIENCE,
    status: 'pending',
  });
  const getFilterObj = () => {
    return {
      goalType: filterState.goalType,
      evaluationStatus: filterState.status === 'all' ? null : filterState.status,
      beforeSubmittedAt: submissions.length
        ? submissions[submissions.length - 1].submittedAt
        : null,
      limit: ITEM_SIZE_LIMIT,
    };
  };

  const getFilterObjForReloadSubmissions = () => {
    return {
      goalType: filterState.goalType,
      evaluationStatus: filterState.status === 'all' ? null : filterState.status,
      beforeSubmittedAt: null,
      limit: submissions.length,
    };
  };

  const fetchSubmissions = async () => {
    try {
      setLoading(true);
      const filterObj = getFilterObj(filterState);
      const { totalCount, data } = await getAlmaXAssignmentList(filterObj);
      setTotalCount(totalCount);
      setSubmissions((prevSubmissions) => [...prevSubmissions, ...data]);
    } catch (e) {
      console.error(e);
      toast.error('Error fetching Project Evaluations');
    } finally {
      setLoading(false);
    }
  };

  const reloadSubmissions = async () => {
    try {
      setLoading(true);
      const filterObj = getFilterObjForReloadSubmissions(filterState);
      const { totalCount, data } = await getAlmaXAssignmentList(filterObj);
      setTotalCount(totalCount);
      setSubmissions(data);
    } catch (e) {
      console.error(e);
      toast.error('Error fetching Project Evaluations');
    } finally {
      setLoading(false);
    }
  };

  const onFilterChange = (filterObj) => {
    const newFilterObj = { ...filterState, ...filterObj };
    setFilterState(newFilterObj);
    setSubmissions([]);
  };

  useEffect(() => {
    fetchSubmissions();
  }, [filterState]);

  return (
    <>
      <NavigationBar menubar={menuBarComponent} />
      <div className='font-medium bg-gray-c4 min-h-screen pb-8'>
        {/* content */}
        <div className='mx-auto p-4 md:p-10 flex flex-col md:flex-row-reverse gap-10 items-start w-full'>
          {/* info & filter */}
          <Filter
            currentNoOfItems={submissions.length}
            totalItems={totalCount}
            currentFilterState={filterState}
            onFilterChange={onFilterChange}
          />

          {/* submissions list */}
          <div className='flex flex-col gap-4 pb-4 w-full md:w-4/5 2xl:w-3/5'>
            {submissions.length > 0 && (
              <>
                {submissions.map((item, index) => (
                  <DetailsCard key={index} item={item} onEvaluationSubmit={reloadSubmissions} />
                ))}
                {submissions.length < totalCount && (
                  <div className='flex w-full justify-center'>
                    <button onClick={fetchSubmissions}>Load More</button>
                  </div>
                )}
              </>
            )}
            <div className='flex justify-center'>
              {loading && (
                <LoadingIcon
                  fontSize='large'
                  className={'text-gray-500 mx-auto animate-spin my-32'}
                />
              )}
            </div>

            {/* info messages */}
            {!loading && submissions.length === 0 && (
              <div className='mx-auto mt-32 text-gray-600 text-lg text-center'>
                Nothing to show here!
              </div>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default AlmaxAssignmentEvaluationDashboard;
