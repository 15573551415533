import React, { useEffect, useState, useRef } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';
import { LoadingCircle } from '../utils/Icons';
import { ToastContainer, toast } from 'react-toastify';
import BasicInfo from './sections/BasicInfo';
import Gigs from './sections/Gigs';
import WorkExperience from './sections/WorkExperience';
import EducationInfo from './sections/EducationInfo';
import Expertise from './sections/Expertise';
import CommonButton from '../common/CommonButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Skills from './sections/Skills';
import CoachingExperience from './sections/CoachingExperience';

const OPTIONS = {
  A: 'Basic Info',
  B: 'Education Info',
  C: 'Coaching Experience',
  D: 'Work Experience',
  E: 'Skills',
  F: 'Expertise',
  G: 'Choose Your Gig',
};

const getStartEndYears = (data) => {
  if (data) {
    data.map((item) => ({
      ...item,
      selected: false,
    }));
    return data;
  }
};

const CoachOnBoarding = () => {
  const matches = useMediaQuery('(min-width:1024px)');
  const toastProps = {
    position: matches ? 'bottom-right' : '',
    autoClose: 2000,
    closeOnClick: true,
    pauseOnHover: true,
  };
  const [activeOption, setActiveOption] = useState('A');
  const [curriculumForAllCourses, setCurriculumForAllCourses] = useState([]);
  const [loadData, setLoadData] = useState(false);

  const me = useSelector(({ authReducer }) => authReducer.loggedInUser);
  const coachInfo = useSelector(({ authReducer }) => authReducer.coachInfo);
  const expertise = coachInfo?.unverified?.domainKnowledge;
  const gigs = coachInfo?.unverified?.gigs;
  const workExperience = coachInfo?.unverified?.workExperience || [];
  const coachingExperience = coachInfo?.unverified?.coachingExperience || [];
  const education = getStartEndYears(coachInfo?.unverified?.education);
  const skills = coachInfo?.unverified?.skills;

  const getActivePage = () => {
    const data = coachInfo?.unverified;
    if (!data) {
      setActiveOption('A');
      return;
    }
    if (data.gigs) setActiveOption('end');
    else if (data.domainKnowledge) setActiveOption('G');
    else if (data.skills) setActiveOption('F');
    else if (data.workExperience) setActiveOption('E');
    else if (data.coachingExperience) setActiveOption('D');
    else if (data.education) setActiveOption('C');
    else if (data.profile && Object.keys(data.profile).length > 1) setActiveOption('B');
    else setActiveOption('A');
  };
  useEffect(() => {
    if (coachInfo) {
      setLoadData(true);
    }
  }, [coachInfo]);

  useEffect(() => {
    if (coachInfo) {
      getActivePage();
    }
  }, [loadData]);

  useEffect(() => {
    const url =
      'https://strapiv2.almagrow.com/api/courses?fields%5B0%5D=courseName&fields%5B1%5D=courseDesc&populate=learningJourney.modules';
    fetch(url)
      .then((res) => res.json())
      .then(
        (result) => {
          const data = result.data;
          setCurriculumForAllCourses(data);
        },
        (error) => {
          console.error(error);
          toast.error('Curriculum data Loading failed,\n please try again later!', toastProps);
        }
      );
  }, []);

  if (!me)
    return (
      <div className='h-full flex m-auto'>
        <LoadingCircle iconStyle='w-16 mt-64 text-blue-500 m-auto' />
      </div>
    );

  return (
    <>
      <div className='text-left flex flex-col md:flex-row h-screen font-medium text-white bg-white '>
        <div className=' md:inline hidden'>
          <Sidebar activeOption={activeOption} />
        </div>
        <div className='md:hidden sticky'>
          <MobileSidebar activeOption={activeOption} />
        </div>
        <div
          className='col-span-5 md:col-span-9 w-full  flex justify-center lg:pl-14  md:pl-8 mt-4 md:mb-20 mb-24 overflow-y-auto'
          style={{ height: 'calc(100vh - 100px)' }}
        >
          {activeOption === 'A' && <BasicInfo me={me} setActiveOption={setActiveOption} />}
          {activeOption === 'B' && (
            <EducationInfo education={education} setActiveOption={setActiveOption} />
          )}
          {activeOption === 'C' && (
            <CoachingExperience
              setActiveOption={setActiveOption}
              coachingExperience={coachingExperience}
            />
          )}
          {activeOption === 'D' && (
            <WorkExperience setActiveOption={setActiveOption} workExperience={workExperience} />
          )}
          {activeOption === 'E' && (
            <Skills setActiveOption={setActiveOption} skills={skills || []} me={me} />
          )}
          {activeOption === 'F' && (
            <Expertise
              setActiveOption={setActiveOption}
              curriculum={curriculumForAllCourses}
              me={me}
              domainKnowledge={expertise || []}
            />
          )}
          {(activeOption === 'G' || activeOption === 'end') && (
            <Gigs me={me} setActiveOption={setActiveOption} gigs={gigs || []} />
          )}
        </div>
      </div>
    </>
  );
};

const Sidebar = ({ activeOption }) => {
  const nonActiveClass = 'opacity-60';
  const activeClass = '';

  return (
    <div className='bg-blue-c1 md:bg-blue-c1 text-white lg:w-72 md:w-60 h-full pb-6 overflow-x-auto'>
      <div className=' font-semibold mb-9 mt-12 ml-8'>Complete your profile</div>
      <div className='ml-8'>
        {[...Object.keys(OPTIONS)].map((option, index) => (
          <div key={index} className={activeOption >= option ? activeClass : nonActiveClass}>
            {index > 0 && (
              <>
                {[...Array(+4).keys()].map((i) => (
                  <div key={i} className='text-left ml-3.5'>
                    {activeOption >= option ? (
                      <div className='-mt-2.5'>|</div>
                    ) : (
                      <div className='text-xs'>|</div>
                    )}
                  </div>
                ))}
              </>
            )}
            {activeOption > option ? (
              <CheckCircleIcon className='mr-2 -mt-1' fontSize='large' />
            ) : (
              <span className='ring-2 ring-current rounded-full inline-block w-6 h-6 text-center mr-4 ml-1'>
                {index + 1}
              </span>
            )}
            <span className='select-none'>{OPTIONS[option]}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
const MobileSidebar = ({ activeOption, setActiveOption }) => {
  const nonActiveClass = 'opacity-60';
  const activeClass = '';
  const activestep = useRef('activestep');

  useEffect(() => {
    if (activestep && activeOption !== 'E') {
      activestep.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
    if (activestep && activeOption === 'E') {
      activestep.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'end',
      });
    }
  }, [activeOption]);

  return (
    <>
      <div className={`bg-blue-c1 text-white  w-full pt-5 px-3 `}>
        <p className='  mb-3 ml-6'>Complete your profile</p>
        <div className={`text-left h-28 flex flex-row overflow-x-auto `}>
          <div className='flex flex-col mt-4 px-8'>
            <div className=' flex flex-row text-center items-center mr-10'>
              {[...Object.keys(OPTIONS)].map((option, index) => (
                <div
                  key={index + option}
                  className={`${
                    activeOption >= option ? activeClass : nonActiveClass
                  } flex space-x-1`}
                >
                  {index > 0 && (
                    <>
                      {[...Array(+15).keys()].map((i) => (
                        <div key={i} className='text-left flex'>
                          -
                        </div>
                      ))}
                    </>
                  )}
                  <>
                    <div className='md:inline hidden ' />
                    <span
                      className={`ring-2 ring-current rounded-full inline-block w-6 h-6 text-center abc `}
                    >
                      <div className='flex flex-col items-center whitespace-nowrap'>
                        {index + 1}
                        <p className='mt-4 mb-6' ref={activeOption >= option ? activestep : null}>
                          {OPTIONS[option]}
                        </p>
                      </div>
                    </span>
                  </>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </>
  );
};

export default CoachOnBoarding;
