import React, { useEffect, useState } from 'react';
import googleLogo from '../../static/img/google_logo.png';
import loginAstronaut from './assets/login_astro.png';
import { Link, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { ALMABETTER_HOME_LINKS, LOCAL_STORAGE_KEYS, PATH } from '../../constants';
import logo from '../../static/img/almabetterLogo.png';

function getRedirectTo() {
  const urlSearchText = window.location.search;
  if (urlSearchText) {
    const urlSearchParams = new URLSearchParams(urlSearchText);
    const redirectTo = urlSearchParams.get('redirectTo');
    if (redirectTo) return redirectTo;
  }
}

function storeRedirectTo() {
  localStorage.removeItem(LOCAL_STORAGE_KEYS.REDIRECT_TO);
  const redirectTo = getRedirectTo();
  if (redirectTo) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.REDIRECT_TO, redirectTo);
  }
}

export default function Login() {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(true);
  const [error, setError] = useState({});

  const [disable, setDisable] = useState(false);

  const ifUserIsAuthenticatedRedirect = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        history.push(PATH.AUTH.LOGIN_SUCCESSFUL);
      }
    } catch (err) {}
  };

  useEffect(() => {
    storeRedirectTo();
    ifUserIsAuthenticatedRedirect();
  }, []);

  async function signIn() {
    setDisable(true);
    try {
      await Auth.signIn(email, password);
      await ifUserIsAuthenticatedRedirect();
    } catch (error) {
      setError(error);
    } finally {
      setDisable(false);
    }
  }

  const validate = (e) => {
    e.preventDefault();
    const temp = {};
    if (email === '') {
      temp.email = 'This field is required';
    }
    if (password === '') {
      temp.password = 'This field is required';
    }
    if (!terms) temp.terms = 'Please accept the terms before proceeding';

    if (Object.keys(temp).length) setError(temp);
    else signIn();
  };

  return (
    <div className=' login w-full min-h-screen bg-pink-c1 md:bg-ab-tertiary-shade-2 text-black'>
      <div className='container mx-auto p-6 md:p-10 md:py-2 grid md:grid-cols-12'>
        <div className='hidden md:block md:col-span-6 md:p-10'>
          <img src={loginAstronaut} alt='Login Astronaut' />
        </div>
        <div className='md:m-10 p-6 md:p-10 md:col-span-6 bg-ab-tertiary flex flex-col w-full lg:w-auto'>
          <h1 className='hidden md:block font-semibold text-3xl text-center mb-6'>
            Log In to AlmaBetter
          </h1>
          <img className='w-44 m-auto mb-8 block md:hidden' src={logo} alt='Logo' />
          <button
            className='w-5/6 mx-auto border border-solid border-gray-100 rounded py-3 shadow-md'
            onClick={() =>
              Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google })
            }
          >
            <span className='flex items-center justify-center text-center w-full gap-4'>
              <img className='w-6' src={googleLogo} alt='Google Logo' />
              Sign in with Google
            </span>
          </button>
          <div className='flex w-full items-center my-4'>
            <hr className='border border-solid border-gray-400 w-full' />
            <span className='mx-10'>or</span>
            <hr className='border border-solid border-gray-400 w-full' />
          </div>
          <form onSubmit={validate} className='flex flex-col'>
            <div className='flex flex-col gap-y-3'>
              {error.message && (
                <span className='text-sm text-red-500 flex items-center gap-1'>
                  <ErrorOutlineIcon /> {error.message}
                </span>
              )}
              <div className='flex flex-col'>
                <label htmlFor='email'>Your Email</label>
                <input
                  className='border border-solid border-gray-300 rounded h-10 p-2'
                  type='text'
                  id='email'
                  placeholder='Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {email === '' && <span className='text-sm text-red-500'>{error.email}</span>}
              </div>
              <div className='flex flex-col'>
                <label htmlFor='password'>Your Password</label>
                <input
                  className='border border-solid border-gray-300 rounded h-10 p-2'
                  type='password'
                  id='password'
                  placeholder='Password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                {password === '' && <span className='text-sm text-red-500'>{error.password}</span>}
              </div>
            </div>
            <Link
              to={PATH.AUTH.FORGOT_YOUR_PASSWORD}
              className='ml-auto text-sm text-ab-secondary-shade-2'
            >
              Forgot Password?
            </Link>
            <div className='flex items-center gap-2 mt-16 text-sm flex-wrap'>
              <input
                type='checkbox'
                id='termsAndConditions'
                checked={terms}
                onChange={() => setTerms((prevState) => !prevState)}
              />
              <label htmlFor='termsAndConditions'>
                I agree to{' '}
                <a
                  href={ALMABETTER_HOME_LINKS.TERM_AND_CONDITIONS}
                  className='text-blue-c2 underline'
                >
                  Terms of use
                </a>{' '}
                &{' '}
                <a href={ALMABETTER_HOME_LINKS.PRIVACY} className='text-blue-c2 underline'>
                  Privacy policy
                </a>
              </label>
              {!terms && <span className='2xl:w-full text-sm text-red-500'>{error.terms}</span>}
            </div>
            <button
              disabled={disable}
              className='bg-ab-primary text-ab-tertiary w-full py-3 rounded my-4 font-medium'
              type='submit'
            >
              {disable ? (
                <CircularProgress style={{ width: '1rem', height: '1rem', color: '#fff' }} />
              ) : (
                'Sign In'
              )}
            </button>
          </form>
          <h3 className='text-sm w-full text-center'>
            Don't have an account?{' '}
            <Link to={PATH.AUTH.SIGN_UP} className='text-blue-c2 underline text-base font-medium'>
              Sign Up
            </Link>
          </h3>
        </div>
      </div>
    </div>
  );
}
