import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import themeReducer from './themeSlice';
import workExperienceReducer from './workExperienceSlice';
import expertiseReducer from './expertiseSlice';
import colorModeReducer from './colorModeSlice';
import questionLevelsReducer from './questionLevelsSlice';
import coachExperienceReducer from './coachExperienceSlice';

export const store = configureStore({
  reducer: {
    authReducer,
    questionLevelsReducer,
    themeReducer,
    workExperienceReducer,
    expertiseReducer,
    colorModeReducer,
    coachExperienceReducer,
  },
});
