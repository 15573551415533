import { createSlice } from '@reduxjs/toolkit';
import {
  getWorkExperience,
  getCompanies,
  getJobTitles,
  addNewCompany,
  addNewJobTitle,
  updateWorkExperience,
} from '../services/userApiService';

const initialState = {
  workExperience: null,
  companies: [],
  jobTitles: [],
};

const workExperienceSlice = createSlice({
  name: 'workExperienceReducer',
  initialState,
  reducers: {
    setWorkExperience: (state, action) => {
      state.workExperience = action.payload;
    },
    setCompanies: (state, action) => {
      state.companies = action.payload;
    },
    setJobTitles: (state, action) => {
      state.jobTitles = action.payload;
    },
  },
});

export const { setWorkExperience, setCompanies, setJobTitles } = workExperienceSlice.actions;

export function fetchWorkExperience() {
  return async (dispatch) => {
    try {
      const userWorkExperience = await getWorkExperience();
      dispatch(setWorkExperience(userWorkExperience?.items));
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchCompanies() {
  return async (dispatch) => {
    try {
      const companies = await getCompanies();
      dispatch(setCompanies(companies));
    } catch (error) {
      console.error(error);
    }
  };
}

export function fetchJobTitles() {
  return async (dispatch) => {
    try {
      const jobTitles = await getJobTitles();
      dispatch(setJobTitles(jobTitles));
    } catch (error) {
      console.error(error);
    }
  };
}

export function addCompanyAction(newCompany) {
  return async (dispatch) => {
    try {
      const { id, name } = await addNewCompany(newCompany);
      dispatch(fetchCompanies());
      return Promise.resolve({ id, name });
    } catch (error) {
      console.error(error);
    }
  };
}

export function addJobTitleAction(newJobTitle) {
  return async (dispatch) => {
    try {
      const { id, name } = await addNewJobTitle(newJobTitle);
      dispatch(fetchJobTitles());
      return Promise.resolve({ id, name });
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateWorkExperienceAction(data) {
  return async (dispatch) => {
    try {
      await updateWorkExperience(data);
      dispatch(fetchWorkExperience());
    } catch (error) {
      console.error(error);
    }
  };
}

export default workExperienceSlice.reducer;
