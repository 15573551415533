import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useHMSActions } from '@100mslive/hms-video-react';

import AskedQues from './AskedQues';
import {
  deleteBookingQuestion,
  getBookingQuestions,
  getDetailedBooking,
  upsertBookingQuestion,
} from '../../../../services/bookingApiService';
import { useHistory } from 'react-router-dom';
import { PATH } from '../../../../constants';

const Questions = ({ bookingId, sessionType, hidden }) => {
  const [booking, setBooking] = useState(null);
  const [bookingQuestions, setBookingQuestions] = useState([]);
  // const [recommendedQuestions, setRecommendedQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const hmsActions = useHMSActions();

  const history = useHistory();

  const fetchBooking = async () => setBooking(await getDetailedBooking(bookingId));

  const fetchBookingQuestions = async () =>
    setBookingQuestions(await getBookingQuestions(bookingId));

  // const fetchRecommendedQuestions = async () =>
  //   setRecommendedQuestions(await getBookingRecommendedQuestions(bookingId));

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      fetchBooking(),
      fetchBookingQuestions(),
      // fetchRecommendedQuestions(),
    ]).then(() => setIsLoading(false));
  }, []);

  // const askQues = async (questionId, questionString) => {
  //   try {
  //     setIsLoading(true);
  //     await upsertBookingQuestion({ bookingId, questionId });
  //     await fetchBookingQuestions();
  //     await hmsActions.sendBroadcastMessage(questionString);
  //     setIsLoading(false);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const submitQuesFeedback = async (questionId, ratingFromCoach) => {
    try {
      setIsLoading(true);
      await upsertBookingQuestion({ questionId, bookingId, ratingFromCoach });
      Promise.all([
        fetchBookingQuestions(),
        hmsActions.sendGroupMessage('✅', ['mentee'], 'ANSWERED_QUESTION'),
      ]);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  // remove ques from the asked questions list
  const removeQues = async (questionId) => {
    try {
      setIsLoading(true);
      await deleteBookingQuestion(bookingId, questionId);
      await fetchBookingQuestions();
      setIsLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  const endSession = () => history.replace(PATH.MENTORSHIP.POST_INTERVIEW, { booking });

  if (!booking)
    return (
      <div className='w-full h-full flex items-center justify-center text-blue-c1' hidden={hidden}>
        <CircularProgress color='inherit' />
      </div>
    );

  return (
    <div
      className='flex-grow relative hidden-scrollbar'
      style={{ overflowY: isLoading ? 'hidden' : 'scroll' }}
      hidden={hidden}
    >
      <AskedQues
        bookingQuestions={bookingQuestions}
        submitQuesFeedback={submitQuesFeedback}
        minQuesToAsk={booking.SubTopic.minQuestions}
        removeQues={removeQues}
        endSession={endSession}
        sessionType={sessionType}
      />
      {/* <QuesMaster
        sessionType={sessionType}
        recommendedQuestions={recommendedQuestions}
        askQues={askQues}
      /> */}

      {isLoading && (
        <div className='sticky h-full inset-0 bg-ab-tertiary dark:bg-ab-primary bg-opacity-50 text-blue-c1 dark:text-ab-tertiary flex justify-center items-center'>
          <CircularProgress color='inherit' />
        </div>
      )}
    </div>
  );
};

export default Questions;
