import React, { useState } from 'react';

import { Close } from '@material-ui/icons';

import { CircularProgress, IconButton, Radio, TextField } from '@material-ui/core';
import { reportProblem } from '../../../services/bookingApiService';

const ProblemReportingPopup = ({ bookingId, closePopup, problemMessages }) => {
  const [formData, setFormData] = useState({
    bookingId,
    message: problemMessages[0],
    reason: '',
  });
  const [loading, setLoading] = useState(false);

  const reportProblemHandler = async () => {
    setLoading(true);
    try {
      await reportProblem(formData);
    } catch (err) {
      alert('Failed to Report Problem! Please try again later.');
      console.error(err);
    }
    closePopup();
  };

  return (
    <div className='fixed bg-ab-primary bg-opacity-60 inset-0 z-20 flex p-4'>
      <div className='w-full max-w-lg bg-ab-tertiary dark:bg-ab-primary-shade-1 m-auto rounded-lg p-6 relative'>
        <h1 className='text-2xl font-medium text-center opacity-80 mb-6'>Report a problem</h1>

        <span className='absolute top-4 right-4 cursor-pointer opacity-60'>
          <IconButton onClick={closePopup} color='inherit'>
            <Close />
          </IconButton>
        </span>

        {problemMessages.map((message) => (
          <div
            className='flex items-center text-ab-primary dark:text-ab-tertiary text-opacity-60 dark:text-opacity-60'
            key={message}
          >
            <Radio
              onChange={() => setFormData({ ...formData, message })}
              checked={formData.message === message}
              color='primary'
            />
            <span>{message}</span>
          </div>
        ))}

        <div className='mt-4'>
          <TextField
            onChange={(e) => setFormData({ ...formData, reason: e.target.value })}
            value={formData.reason}
            label='Mention your reason (optional)'
            variant='outlined'
            rows={3}
            rowsMax={5}
            multiline
            fullWidth
          />
        </div>

        <div className='text-right mt-6'>
          <button
            onClick={reportProblemHandler}
            className={
              'bg-blue-c1 dark:bg-ab-secondary text-ab-tertiary px-8 py-2 font-medium rounded ' +
              (loading && 'opacity-60')
            }
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color='inherit' style={{ display: 'block' }} />
            ) : (
              <span>Send</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProblemReportingPopup;
