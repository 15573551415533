import {
  Radio,
  TextField,
  RadioGroup,
  FormControlLabel,
  FormControl,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import { StarOutline } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';

const useToolTipStyles = makeStyles(() => ({
  tooltip: {
    color: 'white',
    backgroundColor: 'rgba(black, 0.2)',
    fontSize: '16px',
    padding: '10px',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.2)',
    borderRadius: '8px',
  },
  tooltipArrow: {
    color: 'rgba(black, 0.2)',
  },
}));

export const getRatingChangeObj = (param, e) => {
  const { name, type, maxLength, description } = param;
  const { value } = e.target;
  let finalObj = {};
  switch (type) {
    case 'number':
      finalObj = { value: Number(value), type, maxLength };
      break;
    case 'boolean':
      finalObj = { value: value === 'true', type };
      break;
    default:
      finalObj = { value, type };
  }
  description && (finalObj.description = description);
  return { name, finalObj };
};

export const DescriptionToolTip = ({ description }) => {
  const classes = useToolTipStyles();
  return (
    <Tooltip
      arrow
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      }}
      title={description}
    >
      <InfoIcon className='ml-5 cursor-help text-gray-c2 text-opacity-60' />
    </Tooltip>
  );
};

const RatingTemplate = ({ param, changeHandler }) => {
  return (
    <div key={param} className='opacity-80 font-normal capitalize flex flex-col gap-y-3 my-4 mx-4'>
      <div>
        <span className='w-1/2 whitespace-nowrap'>{param.name}</span>
        {param?.description && <DescriptionToolTip description={param.description} />}
      </div>
      {
        {
          string: (
            <>
              <TextField
                id='outlined-full-width'
                fullWidth
                onChange={(e) => changeHandler(param, e)}
                margin='normal'
                {...(!param.questionId && { label: 'Required' })}
                InputLabelProps={{
                  shrink: true,
                }}
                variant='outlined'
              />
            </>
          ),
          number: (
            <>
              <Rating
                max={param?.maxLength}
                emptyIcon={
                  <StarOutline
                    color='inherit'
                    className='text-opacity-30 dark:text-opacity-60 text-ab-primary dark:text-ab-tertiary'
                  />
                }
                onChange={(e) => changeHandler(param, e)}
              />
            </>
          ),
          boolean: (
            <FormControl component='fieldset'>
              <RadioGroup row onChange={(e) => changeHandler(param, e)}>
                <FormControlLabel value='true' control={<Radio />} label='Yes' />
                <FormControlLabel value='false' control={<Radio />} label='No' />
              </RadioGroup>
            </FormControl>
          ),
        }[param.type]
      }
    </div>
  );
};

export default RatingTemplate;
