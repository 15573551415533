import React, { useEffect } from 'react';
import { LOCAL_STORAGE_KEYS, PATH } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingCircle } from '../../components/utils/Icons';
import { loadUser, loggedInUserSelector } from '../../redux/authSlice';
import { doesUserHaveMissingDetails, isEmailNotVerified } from '../../utils';
import EmailVerification from './EmailVerification';
import Onboarding from './Onboarding';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import CoachOnBoarding from '../../components/coach_onboarding/coachOnboardPage';
import { fetchCompanies, fetchJobTitles } from '../../redux/workExperienceSlice';
import { fetchCoachingRoles } from '../../redux/coachExperienceSlice';

function LoginSuccessful() {
  const dispatch = useDispatch();
  const userDetails = useSelector(loggedInUserSelector);
  const history = useHistory();
  const { coachRoles } = useSelector((state) => state.authReducer);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        dispatch(loadUser());
        dispatch(fetchCompanies());
        dispatch(fetchJobTitles());
        dispatch(fetchCoachingRoles());
      })
      .catch((err) => {
        console.error(err);
        history.replace(PATH.AUTH.LOGIN);
      });
  }, []);

  if (!userDetails) {
    return <LoadingCircle iconStyle='w-10 h-10 absolute top-1/2 left-1/2' />;
  }

  if (isEmailNotVerified(userDetails)) {
    return <EmailVerification />;
  }

  if (doesUserHaveMissingDetails(userDetails)) {
    return <Onboarding />;
  }

  if (userDetails.coachOnboarding !== true && !coachRoles) {
    return <CoachOnBoarding />;
  } else {
    history.push(PATH.HOME);
  }

  return <div />;
}

export default LoginSuccessful;
