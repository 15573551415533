import React, { useState, useRef, useEffect } from 'react';
import { TextField, useMediaQuery } from '@material-ui/core';
import { toast, ToastContainer } from 'react-toastify';
import BackupOutlinedIcon from '@material-ui/icons/BackupOutlined';
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import ErrorIcon from '@material-ui/icons/Error';
import GetAppIcon from '@material-ui/icons/GetApp';
import { PATH } from '../../../constants';
import { LoadingCircle } from '../../utils/Icons';
import { Storage } from 'aws-amplify';
import awsConfig from '../../../aws-exports';
import { makeStyles } from '@material-ui/core/styles';

import {
  // getMyKycDetails,
  updateMyKycDetails,
  uploadMyKycDetails,
} from '../../../services/userApiService';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
});

const getChangedObjectProperties = (compareObj, origionalObj) => {
  const res = Object.entries(compareObj).filter(([key, value]) => {
    if (origionalObj[key] !== value) {
      return { key: value };
    }
    return null;
  });
  return Object.fromEntries(res);
};

const kycDetails = ({ userInfo, coachKycDetails, getKycDetails }) => {
  const [formData, setFormData] = useState(coachKycDetails);
  const [isLoading, setIsLoading] = useState(false);
  const MAX_FILE_SIZE = 5 * 1024 * 1024;
  const [formError, setformError] = useState(null);
  const [editMode, seteditMode] = useState(false);
  const [uploadedDetails, setuploadedDetails] = useState(null);
  // const [uploadErrorMsg, setUploadErrorMsg] = useState({});
  const aadhaarS3Url = useRef();
  const panS3Url = useRef();

  const matches = useMediaQuery('(min-width:1024px)');
  const toastProps = {
    position: matches ? 'bottom-right' : '',
    autoClose: 2000,
    closeOnClick: true,
    pauseOnHover: true,
  };

  const classes = useStyles();

  const getDetails = async () => {
    if (Object.keys(coachKycDetails).length) {
      const { createdAt, createdBy, updatedAt, updatedBy, deletedAt, ...kycDetails } =
        coachKycDetails;
      if (kycDetails) {
        seteditMode(true);
        setuploadedDetails(kycDetails);
        // return;
      }
      setFormData(kycDetails);
    }
  };

  useEffect(() => {
    getDetails();
  }, [coachKycDetails]);

  const handleKycDetailsInput = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const validate = (formData) => {
    const { address, panNo, aadhaarNo, panS3Url, aadhaarS3Url } = formData;
    const error = {};
    if (!aadhaarS3Url) {
      error.aadhaarS3Url = 'Please Upload Your Aadhaar Card *';
    }
    if (!panS3Url) {
      error.panS3Url = 'Please Upload Your Pan Card *';
    }
    if (/^[0-9]{12}$/.test(aadhaarNo) === false) {
      error.aadhaarNo = 'Enter Valid 12 Digit Aadhaar Number';
    }
    if (/[A-Z]{5}[0-9]{4}[A-Z]{1}/.test(panNo) === false || panNo.length !== 10) {
      error.panNo = 'Enter Valid 10 Digit Pan Number';
    }
    if (address.length > 80) {
      error.address = 'Address must be at less than 80 characters long';
    }
    if (Object.keys(error).length) {
      setformError({ ...error });
      return false;
    }
    setformError(null);
    return true;
  };

  const handleEditMode = async (formData, uploadedDetails) => {
    if (isEqual(uploadedDetails, formData)) {
      // eslint-disable-next-line no-throw-literal
      throw { msg: 'Please Try Making Some Changes!!' };
    } else {
      setIsLoading(true);
      const data = getChangedObjectProperties(formData, uploadedDetails);
      data.userId = userInfo.id;
      await updateMyKycDetails(data);
    }
  };

  const handleKycDetailsSubmit = async (e) => {
    e.preventDefault();
    if (validate(formData)) {
      try {
        formData.userId = userInfo.id;
        if (editMode) {
          await handleEditMode(formData, uploadedDetails);
        } else {
          setIsLoading(true);
          await uploadMyKycDetails(formData);
        }
        getKycDetails();
        setTimeout(() => {
          setIsLoading(false);
          toast.info('Profile Updated!', toastProps);
        }, 1000);
      } catch (err) {
        setIsLoading(false);
        console.error(err);
        toast.error(err?.msg || 'Failed To Update Profile! \n Please Try Again !!', toastProps);
      }
    }
  };

  const handleUploadToS3 = async (file, type) => {
    // if file selection is cancelled
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      toast.error('file size exceeds maximum of 5MB,\n please try again!', toastProps);
      return;
    }
    const res = await Storage.put(
      `user/${userInfo.id}/${type}/${uuidv4()}.${file.type.split('/')[1]}`,
      file,
      {
        contentType: file.type,
      }
    );
    return res;
  };

  const handlePanCardUpload = () => {
    panS3Url.current.click();
    panS3Url.current.onchange = async ({ target }) => {
      try {
        const file = target.files[0];
        // if file selection is cancelled
        const { key } = await handleUploadToS3(file, 'pan');
        if (key) {
          setFormData({
            ...formData,
            panS3Url: `https://${awsConfig.Storage.AWSS3.bucket}.s3.${awsConfig.Storage.AWSS3.region}.amazonaws.com/public/${key}`,
          });
          toast.info('Pancard Uploaded Succesfully!', toastProps);
        }
      } catch (e) {
        console.error(e);
        // setUploadErrorMsg(null);
        toast.error('PanCard uploading failed,\n please try again!', toastProps);
      }
    };
  };

  const handleAadhaarUpload = (e) => {
    aadhaarS3Url.current.click();
    aadhaarS3Url.current.onchange = async ({ target }) => {
      try {
        const file = target.files[0];
        // if file selection is cancelled
        const { key } = await handleUploadToS3(file, 'aadhaar');

        if (key) {
          setFormData({
            ...formData,
            aadhaarS3Url: `https://${awsConfig.Storage.AWSS3.bucket}.s3.${awsConfig.Storage.AWSS3.region}.amazonaws.com/public/${key}`,
          });
          toast.info('Aadhaar Uploaded Succesfully!', toastProps);
        }
      } catch (e) {
        console.error(e);
        toast.error('Aadhaar Card uploading failed,\n please try again later!', toastProps);
      }
    };
  };

  if (isLoading)
    return (
      <div className='h-full flex m-auto'>
        <LoadingCircle iconStyle='w-16 mt-64 text-blue-500 m-auto' />
      </div>
    );

  return (
    <div className='w-full max-w-4xl mt-16'>
      <div className='text-xl text-green-c1 font-light'>Aadhaar Details</div>
      <form
        onSubmit={handleKycDetailsSubmit}
        className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-8'
      >
        <div className='col-span-2 md:col-span-1'>
          <TextField
            label='Aadhaar Number'
            variant='outlined'
            type='number'
            name='aadhaarNo'
            className={classes.input}
            required
            InputLabelProps={{ shrink: true }}
            value={formData?.aadhaarNo}
            helperText={formError?.aadhaarNo}
            error={formError?.aadhaarNo}
            onChange={handleKycDetailsInput}
            fullWidth
          />
        </div>

        <section className='relative col-span-2 md:col-span-1  text-sm md:text-normalize'>
          <input
            type='file'
            ref={aadhaarS3Url}
            hidden
            name='aadhaarS3Url'
            accept='.pdf, .png, .jpg, .jpeg'
          />
          <button
            disabled={isLoading}
            type='button'
            // onClick={(e) => aadhaarS3Url.current && aadhaarS3Url.current.click()}
            onClick={handleAadhaarUpload}
            className='border-dashed  text-sm md:text-base  border-2 w-full capitalize  text-green-c1 font-extralight rounded focus:outline-none py-3'
          >
            {formData?.aadhaarS3Url ? (
              <>
                <a
                  onClick={(e) => e.stopPropagation()}
                  href={formData?.aadhaarS3Url}
                  target='_blank'
                  rel='noopener'
                >
                  <GetAppIcon className='mr-2 opacity-95 absolute top-50 right-3' />
                </a>
                aadhaar uploaded
              </>
            ) : (
              <>
                <BackupOutlinedIcon className='mr-2' />
                upload aadhaar card
              </>
            )}
          </button>
          <p className='text-red-500 capitalize text-xs mt-2 font-extralight'>
            {formError?.aadhaarS3Url}
          </p>
        </section>

        <span className='col-span-2'>
          <TextField
            label='Address on Aadhaar'
            variant='outlined'
            name='address'
            multiline
            required
            InputLabelProps={{ shrink: true }}
            helperText={formError?.address || `${formData?.address?.length || 0}/80`}
            rows={5}
            maxRows={13}
            className='col-span-2'
            value={formData?.address}
            error={formError?.address}
            onChange={handleKycDetailsInput}
            fullWidth
          />
        </span>

        <div className='text-xl text-green-c1 font-light col-span-2'>PAN Details</div>

        <div className='col-span-2 md:col-span-1'>
          <TextField
            label='PAN Number'
            variant='outlined'
            type='text'
            name='panNo'
            required
            InputLabelProps={{ shrink: true }}
            error={formError?.panNo}
            helperText={formError?.panNo}
            value={formData?.panNo}
            onChange={handleKycDetailsInput}
            fullWidth
          />
        </div>

        <section className='relative col-span-2 md:col-span-1'>
          <input
            type='file'
            ref={panS3Url}
            hidden
            name='panS3Url'
            accept='.pdf, .png, .jpg, .jpeg'
          />
          <button
            disabled={isLoading}
            type='button'
            onClick={handlePanCardUpload}
            className='border-dashed text-sm md:text-base border-2 w-full capitalize  text-green-c1 font-light rounded focus:outline-none py-3'
          >
            {formData?.panS3Url ? (
              <>
                <a
                  onClick={(e) => e.stopPropagation()}
                  href={formData?.panS3Url}
                  target='_blank'
                  rel='noopener'
                >
                  <GetAppIcon className='mr-2 opacity-95 absolute top-50 right-3' />
                </a>
                pancard uploaded
              </>
            ) : (
              <>
                <BackupOutlinedIcon className='mr-3' />
                upload pan card
              </>
            )}
          </button>

          <p className='text-red-500 capitalize text-xs mt-2 font-extralight'>
            {formError?.panS3Url}
          </p>
        </section>

        <button
          type='submit'
          disabled={isLoading}
          className='bg-green-c1 col-span-2 md:col-span-1 dark:bg-ab-secondary text-white font-medium rounded focus:outline-none mt-8 py-4'
        >
          {isLoading && <LoadingCircle iconStyle='w-6 mr-2' />}
          {editMode ? 'UPDATE DETAILS' : 'SUBMIT FOR VERIFICATION'}
        </button>

        {editMode && (
          <Link
            to={PATH.HOME}
            className='text-gray-400 col-span-2 md:col-span-1 text-center font-medium rounded focus:outline-none mt-8 py-4'
          >
            Skip for now
          </Link>
        )}
      </form>

      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

export default kycDetails;
