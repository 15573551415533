import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { Add, DeleteOutline, EditOutlined } from '@material-ui/icons';
import { TextFieldLight } from '../../../utils';
import { updateCoach } from '../../../redux/authSlice';
import { LoadingCircle } from '../../utils/Icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Education = ({ setActiveOption, education }) => {
  const matches = useMediaQuery('(min-width:1024px)');
  const dispatch = useDispatch();
  const [allEducation, setAllEducation] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});
  const [addEdu, setAddEdu] = useState(education ? education.length === 0 : true);
  const [formError, setFormError] = useState();
  const [startYearError, setStartYearError] = useState();
  const [endYearError, setEndYearError] = useState();

  useEffect(() => {
    setAllEducation(education);
  }, [education]);

  const handleInput = async ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const checkYear = (year) => {
    if (year) {
      const error = {};
      if (!(year >= 1800 && year <= 2050)) {
        error.year = 'Please enter correct year, YYYY';
      }
      setFormError(error);
      return Object.keys(error)?.length > 0;
    }
  };

  const updateDetails = async (details) => {
    try {
      await dispatch(updateCoach({ education: details }));
      toast.info('Education updated!', {
        position: matches ? 'bottom-right' : '',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } catch (err) {
      toast.error('Failed To Update Education! \n Please Try Again Later!!', {
        position: 'bottom-right',
        autoClose: 3000,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
    setLoading(false);
  };

  const addDetails = async (e) => {
    setLoading(true);
    const years = formData.startYear + '-' + formData.endYear;
    e.preventDefault();
    const detail = () => {
      return allEducation ? [...allEducation] : [];
    };
    const obj = {
      ...formData,
      years,
    };
    const details = detail();
    details.push(obj);

    await updateDetails(details);
    setFormData({});
    setAddEdu(false);
    setEditData({});
  };

  const removeDetails = async (i) => {
    const details = [...allEducation];
    details.splice(i, 1);
    await updateDetails(details);
    if (details?.length === 0) {
      setAddEdu(true);
    }
    setFormData({});
  };

  const editDetails = (i) => {
    setAddEdu(true);
    const details = [...allEducation];
    if (editData?.degree) {
      const obj = {
        ...editData,
      };
      details.push(obj);
    }

    const [toEdit] = details.splice(i, 1);
    setAllEducation(details);
    setFormData(toEdit);
    setEditData(toEdit);
  };

  return (
    <div className='w-full md:mt-6 flex flex-col md:mb-12 mx-4 md:mx-0'>
      {/* Current Education Info */}
      <div className='flex md:flex-row flex-col text-ab-primary space-y-4 md:space-y-0 lg:mr-40 md:mr-10'>
        <p
          className={`text-black md:text-lg text-sm whitespace-nowrap ${
            allEducation?.length > 0 ? 'hidden' : 'visible'
          }`}
        >
          Add your education details
        </p>
        <div
          className={`md:w-1/4 w-full opacity-30 ${
            allEducation?.length > 0 ? 'visible' : 'hidden'
          }`}
        >
          Education
        </div>
        <div className='md:w-3/4 w-full '>
          <>
            {allEducation?.length > 0 &&
              allEducation.map((item, index) => {
                return (
                  <div className='mb-5 flex justify-between' key={index}>
                    <div>
                      <div className='font-semibold opacity-80'>{item.degree}</div>
                      <div className='text-sm opacity-60 my-1'>{item.instituteName}</div>
                      <div className='text-sm'>
                        <span className='opacity-30'>
                          {item.startYear}-{item.endYear}
                        </span>
                      </div>
                    </div>

                    <div className='flex gap-2'>
                      <div onClick={() => editDetails(index)} className='cursor-pointer opacity-30'>
                        <EditOutlined color='inherit' />
                      </div>
                      <div
                        onClick={() => removeDetails(index)}
                        className='cursor-pointer opacity-30'
                      >
                        <DeleteOutline color='inherit' />
                      </div>
                    </div>
                  </div>
                );
              })}
          </>
        </div>
      </div>

      {/* form */}

      <form onSubmit={addDetails} className='md:mt-6 mt-4 flex flex-col gap-4 md:gap-8 md:mr-20'>
        {addEdu && (
          <div className={`flex flex-col md:grid grid-cols-2 gap-4 md:gap-8`}>
            <TextFieldLight
              label='Name of Institute'
              placeholder='College / School / University'
              name='instituteName'
              variant='outlined'
              type='text'
              value={formData.instituteName || ''}
              onChange={handleInput}
              fullWidth
              required
              autoFocus
            />
            <TextFieldLight
              label='Degree'
              placeholder='Master in / Bachelor in'
              name='degree'
              variant='outlined'
              type='text'
              value={formData.degree || ''}
              onChange={handleInput}
              fullWidth
              required
            />
            <div>
              <TextFieldLight
                label='Start Year'
                placeholder='Ex. 2018'
                name='startYear'
                variant='outlined'
                type='number'
                value={formData.startYear || ''}
                onChange={handleInput}
                fullWidth
                required
                onWheel={(e) => e.target.blur()}
                onBlur={(e) => {
                  const status = checkYear(formData.startYear);
                  setStartYearError(status);
                }}
              />
              <div className=' text-red-600 text-xs h-4 w-full mt-1'>
                {startYearError && formError?.year}
              </div>
            </div>

            <div>
              <TextFieldLight
                label='End Year'
                placeholder='Ex.2020'
                name='endYear'
                variant='outlined'
                type='number'
                value={formData.endYear || ''}
                onChange={handleInput}
                fullWidth
                required
                onWheel={(e) => e.target.blur()}
                onBlur={(e) => {
                  const status = checkYear(formData.endYear);
                  setEndYearError(status);
                }}
              />

              <div className=' text-red-600 text-xs h-4 w-full mt-1'>
                {endYearError && formError?.year}
              </div>
            </div>
          </div>
        )}
        <div className='md:mb-8 mb-4'>
          <div className=' flex space-x-8'>
            {!addEdu && (
              <button
                type='button'
                onClick={() => {
                  setAddEdu(true);
                }}
                className=' text-blue-c1 rounded justify-self-start font-medium text-left '
              >
                <Add /> Add Education
              </button>
            )}

            {addEdu && allEducation?.length > 0 && (
              <button
                type='button'
                className='text-gray-c5 rounded py-3 justify-self-start font-medium cursor-pointer'
                onClick={() => {
                  setAddEdu(false);
                  setFormError({});
                  if (Object.keys(editData).length > 0) {
                    const details = [...allEducation, editData];
                    setAllEducation(details);
                    setEditData({});
                  }
                  setFormData({});
                }}
              >
                Cancel
              </button>
            )}

            {addEdu && (
              <button
                type='submit'
                className='rounded py-3 justify-self-start font-medium cursor-pointer text-blue-c1 disabled:opacity-40 disbled:cursor-not-allowed'
                disabled={startYearError || endYearError}
              >
                {loading && <LoadingCircle iconStyle='w-6 mr-2' />}
                Save
              </button>
            )}
          </div>
        </div>
        <div className=''>
          <button
            type='button'
            onClick={() => {
              setActiveOption('C');
            }}
            disabled={allEducation?.length === 0 || addEdu}
            className={` mb-4 md:mb-8 md:w-72 w-11/12 bg-green-c1 text-white focus:outline-none font-medium  text-center rounded bottom-0 fixed py-4 -ml-1 md:-ml-0
              disabled:opacity-40 cursor-not-allowed"
              }`}
          >
            NEXT
          </button>
        </div>
      </form>

      <span className='whitespace-pre-line'>
        <ToastContainer />
      </span>
    </div>
  );
};

export default Education;
